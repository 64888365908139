import _ from 'lodash'

export default {
	data() {
		return {
			
		}
	},
	props: [],
	created(){
		
	},
	methods: {
		calculSommeQtyDechetsAvecNull(dechets_qty){
			let somme = null
			_.forEach(dechets_qty, function(qty){
				if(typeof qty == 'number') somme = somme == null ? qty : somme + qty
			})
			return somme
		},
		numberFormatQty(qty, unite, espace_unite, force_number_digit){
			let number = null

			if(typeof qty != 'number'){
				return '-'
			}

			//AS--> Unité et espace
			if(typeof espace_unite == 'undefined') espace_unite = true
			let _unite = ''
			if(unite){
				if(espace_unite){
					_unite = ' '+unite
				}else{
					_unite = unite
				}
			}

			//AS--> Formattage
			if(typeof force_number_digit == 'number'){
				number = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: force_number_digit }).format(qty);
			}else{
				if (qty >= 0 && qty <= 1) {
					number = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(qty);
				} else {
					number = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(qty);
				}
			}

			//AS--> Ajout de l'unite
			number += _unite

			return number
		}
	},
	computed:{
		
	},
	components:{
		
	}
}