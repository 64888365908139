import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import CustomSlider from '../blocs/custom_slider.vue'

export default {
	data() {
		return {
			settings: {
				// maxScrollbarLength: 60
			},
			sliders: []
		}
	},
	props: ['params','menu_index'],
	watch: {
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
		}
	},
	methods: {
		reset_filters() {
			// this.$store.dispatch('init_custom_filters');
			this.$store.dispatch('resetFilterVariables');
		},
		set_variables_filters(variable_code, value) {
			let filter = { variable_code, value }
			this.$store.dispatch('setVariables', filter)
		},
		is_check_bool(variable_code, value) {
			let variables = this.$store.state.filters.variables
			
			if(value == 'oui'){
				return variables[variable_code][1] == 1;
			}else{
				return variables[variable_code][0] == 0;
			}
		},
		switch_check_bool(variable_code, value){
			let variable = _.clone(this.$store.state.filters.variables[variable_code]);

			//AS--> Si on traite la case non
			if(value == 'non'){

				//AS--> Si 'non' était coché, on le décoche
				if(variable[0] == 0){
					//AS--> Si oui est coché
					if(variable[1] == 1){
						variable[0] = 0.3;
					}else{
						variable[1] = 2;
						variable[0] = -1;
					}

				//AS--> Si 'non' était décoché, on le coche
				}else{
					variable[0] = 0;
					//AS--> Si oui n'est pas coché
					if(variable[1] != 1) variable[1] = 0.7;
				}

			//AS--> Si on traite la case oui
			}else if(value == 'oui'){

				//AS--> Si 'oui' était coché, on le décoche
				if(variable[1] == 1){
					//AS--> Si non est coché
					if(variable[0] == 0){
						variable[1] = 0.7;
					}else{
						variable[0] = -1;
						variable[1] = 2;
					}

				//AS--> Si 'oui' était décoché, on le coche
				}else{
					variable[1] = 1;
					//AS--> Si non n'est pas coché
					if(variable[0] != 0) variable[0] = 0.3;
				}

			}

			let filter = {variable_code, value: variable};
          	this.$store.dispatch('setVariables',filter)
		},
		switch_variable(item) {
			this.$store.dispatch('switchFilterVariable', item);
		},
		getVariableCheck(variable_code) {
			return this.$store.state.filters.variables_codes.includes(variable_code)
		},
		minMaxEqual() {

		},
		formatData(value) {
			if (this.is_float) {
				return value / 100;
			} else {
				return value;
			}
		},
		setVal(valeur, key){
			let v = _.cloneDeep(valeur)
			if(this.is_float){
				v[0] = v[0]/100;
				v[1] = v[1]/100;
			}
			let filter = _.cloneDeep({variable_code:this.item.id, value: v})
			this.$store.dispatch('setVariables',filter)
		}
	},
	computed: {
		getVariablesFilters() {
			let self = this
			let variables_filters = this.$store.state.ui.variables_filters;

			// let dechets = this.$store.state.ui.dechets
			// let main_dechet_id = this.$store.state.filters.filters.main_dechet_id
			// let filtres_ids = false

			// _.forEach(dechets, function(dechet) {
			// 	if (dechet.data.id == main_dechet_id) {
			// 		if (Object.values(dechet.filtres).length != 0) {
			// 			filtres_ids = dechet.filtres_ids;
			// 		}
			// 	}
			// })

			// let _filtres = {}
			_.each(variables_filters, function(filtre_id, k){
				if(!self.$store.state.ui.sourcing.variables[k]){
					delete variables_filters[k]
				}else{
					variables_filters[k]['vals'] = [variables_filters[k]['min'], variables_filters[k]['max']]
					variables_filters[k]['id'] = k
					variables_filters[k]['nom'] = self.$store.state.ui.sourcing.variables[k]['label']
				}
			})

			return variables_filters
		},
		hasVariablesFilters() {
			return _.keys(this.getVariablesFilters).length
		},
	},
	components: {
		VueSlider,
		CustomSlider
	}
}