import axios from '../common/axios.js'

export default {
	data(){
		return {
			content: ''
		}
	},
	created(){
		let self = this
		this.content = "<p style='text-align: center'>... loading ...</p>";
		axios.get('/cms/arborescences/get_page_content/'+this.$store.state.ui.modal_content)
		.then(function(response){
			self.content = response.data
		})
	},
	methods:{
		close_modal(event){
			if(Object.values(event.target.classList).indexOf('c-modal') != -1){
				this.$store.commit('UI_SET_MODAL_CONTENT', null);
			}
		}
	},
	components: {}
}