import Debounce from 'lodash/debounce'
import carousel from 'vue-owl-carousel'

export default {
	data(){
		return {
			total_barre: 0
		}
	},
	props: ['company', 'total'],
	created(){
	},
	methods:{
		
	},
	computed:{
	    isActive(){
	      return this.total > 1 && this.company.id == this.$store.state.company.company.id
	    },
	    infos(){
	    	return this.$store.state.ui.sourcing.marker_infos.split(',')
	    },
		getCompanyName(){
			return this.company.nom
		},
		getCompanyNameTruncat(){
			return _.truncate(this.company.nom, {length: 50})
		},
		getActivite(){
			let activite = ''
			if(this.company.familles_ids && this.company.familles_ids[0]){
				activite = this.$store.getters.getFamilleNom(this.company.familles_ids[0])
			}
			return activite
		},
		getActiviteTruncat(){
			return _.truncate(this.getActivite, {length: 40})
		},
		getNace(){
			return typeof this.$store.state.ui.code_naces[this.company.nace_id] != 'undefined' ? this.$store.state.ui.code_naces[this.company.nace_id]['titre'] : ''
		},
		getNaceTruncat(){
			return typeof this.$store.state.ui.code_naces[this.company.nace_id] != 'undefined' ? _.truncate(this.$store.state.ui.code_naces[this.company.nace_id]['titre'], {length: 40}) : ''
		}
	},
	components:{
	}
}