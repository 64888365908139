import axios from '../../common/axios.js'
import CryptoJS from 'crypto-js'
import Input from '../../common/forms/Input.vue'
import LoadingBtn from '../../common/ui/loading_btn.vue'

export default {
	data(){
		return {
			passwordType: 'password',
			password: '',
			email: '',
			error: false,
			message_erreur: 'connection_erreur',
			loading: false
		}
	},
	created(){
	},
	methods:{
		switchInput(){
			this.passwordType = this.passwordType == 'password' ? 'text' : 'password'
		},
		connecter(){
			var self = this;
			self.error = false;

			let infos = {
				data:{
					Profil: {
						email: self.email,
						password: self.password
					}
				}
			}

			this.loading = true;

			//AS--> Demande d'autorisation de connexion à PHP
			axios.post('/plugin_projet/plugin_projet_iannuaire/connecte', infos)
			.then(function (response) {
				//AS--> Si PHP refuse de connecter la personne
				if(typeof response.data == 'string' && response.data.substring(0,3) == 'KO:'){
					self.error = true;
					self.message_erreur = response.data.substring(3);

				//AS--> Si PHP autorise la personne à se connecter
				}else{

					// infos.data.Profil.password = 'poc'
					//AS--> Demande d'autorisation de connexion à API inex
					axios.post('api=inex/login/', infos)
					.then(function (response2) {

						self.$store.commit('UI_SET_MODAL', {modal: null});
						self.$store.commit('UI_SET_COMPANY_MODAL', {id:null, prev:null, next:null})
						self.$store.dispatch('init_user_data', response.data).then(function(){
							self.$store.dispatch('user_after_connection');
						});

					})
					.catch(function (error) {
						self.error = true;
						self.message_erreur = 'iNex refused to connect the user';
						console.log(error);
					})
					.finally(function () {
						self.loading = false;
					});
				}
			}).catch(function (error) {
				console.log(error);
			}).finally(function () {
				self.loading = false;
			});
		}
	},
	components: {
		Input,
		LoadingBtn
	}
}
