import _ from 'lodash'

export default {
	data() {
		return {
			
		}
	},
	props: {
		title:{
			type: String
		},
		reinit:{
			type: Function | Boolean
		},
		reinit_on:{
			type: Boolean | Number,
			default: true
		},
		selectAll:{
			type: Function | Boolean
		},
		selectAll_on:{
			type: String
		},
		close:{
			type: Function
		},
		menu_index:{
			type: Number
		},
	},
	created(){
		
	},
	methods: {
		push_reinit(){
			//AS--> Si on a un ID de menu, c'est qu'on est en mode filtres YAML. On indique sur le menu qu'on veut déclencher une réinitialisation en incrémentant menu.reinit_index
			if(this.menu_index != null){
				let menus = _.cloneDeep(this.$store.getters.getUiFiltres)
				let menu = menus[this.menu_index]
				if(!menu.reinit_index) menu.reinit_index = 0
				menu.reinit_index++
				menus[this.menu_index] = menu
				this.$store.commit('UI_SET_FILTRES', menus)
			}else{
				if(this.reinit_on) this.reinit()
			}
		},
		push_selectAll(){
			//AS--> Si on a un ID de menu, c'est qu'on est en mode filtres YAML. On indique sur le menu qu'on veut déclencher un selectAll en incrémentant menu.selectAll_index
			if(this.menu_index != null){
				let menus = _.cloneDeep(this.$store.getters.getUiFiltres)
				let menu = menus[this.menu_index]
				if(!menu.selectAll_index) menu.selectAll_index = 0
				menu.selectAll_index++
				menus[this.menu_index] = menu
				this.$store.commit('UI_SET_FILTRES', menus)
			}else{
				this.selectAll()
			}
		},
	},
	computed:{
	},
	components:{
		
	}
}