export default {
	data() {
    return {

    }
  },
  methods:{
    numberFormat(number){
      if (number >= 0 && number <= 1) {
        return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(number);
      }else{
        return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 0}).format(number);
      }
    }
  },
  computed:{
    getColumns(){
      let variables = [];
      _.forEach(this.$store.state.ui.sourcing.variables, function(var_infos, var_field){
        var_infos['field'] = var_field;
        variables.push(var_infos);
      });
      variables = _.orderBy(variables, ['ordre'], ['asc']);
      return variables;
    }
  }
}