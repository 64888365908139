export default {
	data() {
    return {

    }
  },
  methods:{
    numberFormat(number){
      if (number >= 0 && number <= 1) {
        return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(number);
      }else{
        return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 0}).format(number);
      }
    }
  },
  computed:{
    getColumns(){
      let capacites = [];
      _.forEach(this.$store.state.ui.sourcing.capacites, function(cap_infos, cap_field){
        if(cap_infos.colonne){
          cap_infos['field'] = cap_field;
          capacites.push(cap_infos);
        }
      });
      capacites = _.orderBy(capacites, ['ordre'], ['asc']);
      return capacites;
    }
  }
}