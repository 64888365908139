import _ from 'lodash'
import BarreRecherches from '../barre_recherches/barre.vue';
import Indicateurs from '../indicateurs/indicateurs.vue';
import Actions from '../actions/actions.vue';

export default {
	data() {
		return {
			
		}
	},
	created(){
		
	},
	methods: {
		
	},
	computed:{
		
	},
	components:{
		BarreRecherches,
		Indicateurs,
		Actions,
	}
}
