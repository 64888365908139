export default {
    data() {
        return {
            limit: 17
        }
    },
    props: ['entreprise'],
    computed: {
        colonne(){
            let identifiant = this.entreprise.identifiant

            //AS--> Si l'identifiant fait plus de 10 caractères, on le raccourci à 7 et on ajoute '...', sinon, on le retourne sans modification
            return (identifiant.length > this.limit) ? identifiant.substr(0,this.limit-3) + '...' : identifiant

        },
        tooltip(){
            let identifiant = this.entreprise.identifiant

            //AS--> Si l'identifiant fait plus de 10 caractères, on le raccourci à 7 et on ajoute '...', sinon, on le retourne sans modification
            return (identifiant.length > this.limit) ? identifiant : null

        }
    }
}