// import AdvancedSelect from '@myena/advanced-select';
import vSelect from 'vue-select'
import axios from '../../common/axios.js'
import _ from 'lodash'
import Input from '../../common/forms/Input.vue'
import Phone from '../../common/forms/Phone.vue'
import LoadingBtn from '../../common/ui/loading_btn.vue'

export default {
	data(){
		return {
			indicatif: '',
			tel: '',
			tel_validation: {},
			errors: {},
			error: false,
			loading: false
		}
	},
	created(){
	},
	methods:{
		submit(){
			console.log('submit');
			var self = this;
			var fields = ['tel'];
			self.errors = {};
			self.error = false;
			
			//AS--> Vérifie tous les champs
			fields.forEach(function(field){
				if(field == 'tel' && !self.validateTel(self[field])){
					self.errors[field] = self.$t('signin_error_tel');
				}
			})

			//AS--> S'il n'y a pas d'erreur, on poursuit la validation par téléphone
			if(_.isEmpty(self.errors)){
				this.loading = true;
				axios.post('/plugin_projet/plugin_projet_iannuaire/phone_validation', {
					data:{
						Profil: {
							tel1: self.telconcat
						}
					}
				})
				.then(function (response) {
					if(typeof response.data.erreurs != 'undefined'){
						self.error = '<ul>';
						_.forEach(response.data.erreurs, function(text, field){
							self.error += '<li>'+text+'</li>';
						})
						self.error += '</ul>';
					}else{
						self.$store.commit('UI_SET_MODAL', {modal: 'phone_code'});
						var user = _.cloneDeep(self.$store.state.user.user);
						user.tel = self.tel;
						user.indicatif = self.indicatif;
						self.$store.commit('USER_SET_USER', user);
					}
				})
				.catch(function (error) {})
				.finally(function () {
					self.loading = false;
				});
			}
		},
		validateTel() {
			return this.tel_validation.valid === true
		},
		phoneValidation(validation){
			this[validation.field+'_validation'] = validation
		}
	},
	computed:{
		options(){
			let self = this
			let pays = [
				['France', 33],
				['Allemagne', 49],
				['Australie', 61],
				['Autriche', 43],
				['Belgique', 32],
				['Bulgarie', 359],
				['Canada', 1],
				['Chypre', 357],
				['Croatie', 385],
				['Danemark', 45],
				['Espagne', 34],
				['Estonie', 372],
				['Etats-unis', 1],
				['Finlande', 358],
				['Gibraltar', 350],
				['Grèce', 30],
				['Hongrie', 36],
				['Irlande', 353],
				['Islande', 354],
				['Italie', 39],
				['Lettonie', 371],
				['Liechtenstein', 423],
				['Lituanie', 370],
				['Luxembourg', 352],
				['Malte', 356],
				['Norvège', 47],
				['Pays-Bas', 31],
				['Pologne', 48],
				['Portugal', 351],
				['République Tchèque', 420],
				['Roumanie', 40],
				['Royaume-Uni', 44],
				['Slovaquie', 421],
				['Slovénie', 386],
				['Suède', 46],
			]

			let options = []

			pays.forEach(function(pays){
				options.push({
					label: self.$t('pays_'+pays[0]) + ' (+'+pays[1]+')',
					value: '+'+pays[1]
				})
			})

			return options
		},
		telconcat(){
			let tel = this.tel
			if(this.indicatif.length && this.tel.length){
				let regex = /^0/i;
				let _tel = this.tel.replace(regex, '')
				let regex2 = /^\+/i;
				let _indicatif = this.indicatif.replace(regex2, '00')
				tel = _indicatif + _tel
			}
			return tel
		}
	},
	components: {
		vSelect,
		Input,
		Phone,
		LoadingBtn
	}
}