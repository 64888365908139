export default {
	props: ['index'],
	methods: {
		open_results(){
			let show_filters = (this.index+1) == this.$store.state.ui.show_filter_index ? !this.$store.state.ui.show_filters : true
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: show_filters});
			this.$store.commit('UI_SET_FILTER_INDEX', {filter_index: (this.index+1)});
			if (this.$store.state.ui.content != 'result' && this.$store.state.ui.content != 'graph') {
				this.$store.commit('UI_SET_CONTENT', {content: 'result'});
			}
		}
	},
	computed:{
		hasFiltersContacts(){
			return this.$store.getters.hasFiltersContacts;
		}
	}
}