import vueCustomScrollbar from 'vue-custom-scrollbar'
import _ from 'lodash'
import FiltreHeader from './filtres/_filtre_header.vue';

export default {
	data() {
		return {
		}
	},
	created(){
	},
	methods: {
		reset_filters(){
			this.$store.dispatch('resetContacts');
		},
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
		}
	},
	computed:{
		
	},
	components: {
    	vueCustomScrollbar,
    	FiltreHeader
	}
}