import Menu from './menu.vue';
import Filters from './filters.vue';
import FiltresFiltres from './filtres/filtres.vue';
import Content from './content.vue';

export default {
	methods:{

	},
	components: {
		Menu,
		Filters,
		FiltresFiltres,
		Content
	}
}