<template>
  <!-- Ajouter la class haut pour avoir un champ de taille standard -->
  <!-- Ajouter la class no_mini_label pour avoir un champ sans label intégré -->
  <div
    class="input v2"
    :class="['input-' + _kebabCase(field), { errors: getErrors }, { picto: picto }, { vider: vider }, {has_data: value}]"
  >
    <input
      class="v2"
      :type="type"
      :placeholder="$t(label)"
      :value="value"
      :id="field"
      :name="field"
      :disabled="disabled"
      :step="step"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
      ref="thisinput"
    />
    <label :for="field">{{ $t(label) }}</label>
    <span v-if='picto' :class="['picto', pictoColor]">
      <icon-base :viewBox="viewBox" width="14" height="14"><component v-bind:is="picto" /></icon-base>
    </span>
    <span v-if='vider' class="vider" @click="empty()">
      <icon-base viewBox="0 0 23 23" width="14" height="14" ><IconClose2 /></icon-base>
    </span>
    <span v-for="(error, key) in getErrors" :key="key" class="fields-errors">{{
      error
    }}</span>
  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
export default {
  name: "Input",
  data() {
    return {};
  },
  props: {
    value: String,
    datas: Object,
    errors: Object,
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: -1,
    },
    step: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    field: String,
    label: String,
    picto: String,
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    pictoColor: {
      type: String,
      default: '' //AS--> Green by default. Others : black
    },
    vider: {
      type: Boolean,
      default: false //AS--> True to show a cross at the right
    },
    viderAction: {
      type: Function
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
  },
  created() {},
  mounted() {
    if(this.autoFocus){
      this.$refs.thisinput.focus()
    }
  },
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
    empty(){
      this.$emit('input', '')
      if(this.viderAction) this.viderAction()
    }
  },
  computed: {
    getErrors() {
      let errors = get(this.errors, this.field)
      if(typeof errors == 'string') errors = [this.$t(errors)]
      return errors;
    }
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.input {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content;
  gap: 3px;
  input,label {
    grid-row: 1 / 1;
  }

  .fields-errors {
    margin: 0;
    color: red;
    padding: 0;
    display: block;
    height: auto;
    padding: 0 2px;
    // background-color: fade-out(red, 0.8);
    // border: 1px solid fade-out(red, 0.8);
    // border-radius: 5px;
  }
}
</style>
