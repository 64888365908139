<template>
  <!-- Ajouter la class haut pour avoir un champ de taille standard -->
  <!-- Ajouter la class no_mini_label pour avoir un champ sans label intégré -->
  <!-- 
  Pour récupérer la validation du téléphone, créer sur le composant parent une variable
  data() {
    return {
      [field]_validation: {}
    }
  }
  
  Ajouter la méthode
  phoneValidation(validation){
    this[validation.field+'_validation'] = validation
  }

  Sur le composant dans la vue, ajouter l'écouteur et le field
  <Phone ... field="tel" @validation="phoneValidation" ... />

  -->
  <div
    class="input v2 vue-tel-input-wrapper"
    :class="['input-' + _kebabCase(field), { errors: getErrors }, {has_data: value}, {has_data_or_focused: value || focused}]"
  >
    <vue-tel-input 
      v-model="phone" 
      @validate="phoneValidation"
      @input="change"
      @focus="focused = true"
      @blur="focused = false"
    ></vue-tel-input>
    <label :for="field">{{ $t(label) }}</label>
    <span v-for="(error, key) in getErrors" :key="key" class="fields-errors">{{
      error
    }}</span>
  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";

import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  name: "Phone",
  data() {
    return {
      phone: this.value,
      focused: false,
      validation: {}
    };
  },
  props: {
    value: String,
    errors: Object,
    field: String,
    label: String,
  },
  created() {},
  mounted() {},
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
    empty(){
      this.$emit('input', '')
      if(this.viderAction) this.viderAction()
    },
    phoneValidation(validation){
      validation.valid ??= 'empty'
      validation.field = this.field
      this.validation = validation
      this.$emit('validation', validation)
    },
    change(value, tel_object){
      this.$emit('input', tel_object.number)
    }
  },
  computed: {
    getErrors() {
      let errors = get(this.errors, this.field)
      if(typeof errors == 'string') errors = [this.$t(errors)]
      return errors;
    }
  },
  components: {},
};
</script>
