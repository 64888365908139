import Modale from '../../common/ui/modale.vue'

export default {
	data(){
		return {}
	},
	components:{
		Modale
	}
}
