export default {
	data() {
    return {}
  },
  props: ['entreprise'],
  computed: {
  	getFamillesSvg(){
  		let familles_ids = this.entreprise.familles_ids
  		let svg = [];

  		_.forEach(this.$store.state.ui.sourcing.familles, function(famille){
  			if(famille.parent_id != 0 && familles_ids.includes(famille.id) && famille.svg_light){
  				svg.push(famille.svg_light)
  			}
  		})

  		return svg;
  	}
  }
}