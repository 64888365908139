import ImageAuto from "../../common/ui/image_auto.vue";
import Modale from '../../common/ui/modale.vue'
import CalculsDechets from '@/common/mixins/CalculsDechets.js'
import GraphIp from '@/common/ui/graphIp.vue'

export default {
	mixins: [CalculsDechets],
	data(){
		return {
		}
	},
	methods:{
		getUnite(unite_id){
			if(this.$store.state.ui.unites[unite_id] && this.$store.state.ui.unites[unite_id].nc) return this.$store.state.ui.unites[unite_id].nc;
		}
	},
	computed:{
		getDechetNom(){
			return this.$store.getters.getDechetField(this.$store.state.ui.matiere.material_id, 'nom')
		},
		getDechetDescription(){
			if(this.$store.state.ui.matiere.material_id) return this.$store.getters.getDechetField(this.$store.state.ui.matiere.material_id, 'description');
		},
		getImage(){
			// if(this.$store.state.ui.matiere.picto_personnalise_large) return this.$store.state.ui.matiere.picto_personnalise_large;
			// if(this.$store.state.ui.matiere.material_id) return this.$store.getters.getDechetField(this.$store.state.ui.matiere.material_id, 'picto_large');
			return 'api=inex/companies/'+this.$store.state.company.company.complement.identifiant+'/products/'+this.$store.state.ui.matiere.product_id+'/'+this.$store.state.ui.matiere.material_id+'/image'
		},
		getCaracteristiques(){
			let self = this
			
			if(this.$store.state.ui.matiere.material_id){

				//AS--> Caractéristiques propres à la matière à afficher
				let caracs_matiere = this.$store.state.ui.matiere.characteristics;

				//AS--> Détermine la langue à afficher
				let langue_id = this.$store.state.ui.langues_ids[this.$store.state.ui.langue]

				if(this.$store.state.ui.dechets[0] && this.$store.state.ui.dechets[0].data.caracteristiques_modale){
					let returns = [];

					//AS--> Pour chacune des caractéristiques définies dans déchet interface pour afficher dans la modale
					_.forEach(this.$store.state.ui.dechets[0].modale, carac_modale => {

						let value = null

						//AS--> On regarde si la matière possède cette caractéristique
						caracs_matiere.forEach(function(carac){

							//AS--> Si la caractéristique existe pour la matière et la langue à afficher
							if(carac.forme_id == carac_modale.id && carac.langue_id == langue_id){
								value = {
									...carac_modale,
									value: carac.value
								}
							}
						})

						//AS--> Si la caractéristique n'a pas été trouvée, on la recherche sans tenir compte de la langue
						if(value === null){
							caracs_matiere.forEach(function(carac){
								if(carac.forme_id == carac_modale.id){
									value = {
										...carac_modale,
										value: carac.value
									}
								}
							})
						}

						//AS--> On l'ajoute au tableau des caractéristiques à afficher
						if(value !== null){
							value.value = self.$store.getters.getNomenclatureValue('caracteristics', value.id, value.value)
							returns.push(value)
						}

					})
					if(returns.length) return returns;
				}
			}

			return false;
		}
	},
	components:{
		ImageAuto,
		Modale,
		GraphIp
	}
}