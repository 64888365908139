import _ from 'lodash'

export default {
	data() {
		return {}
	},
	props: ['params','menu_index'],
	watch: {
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
			if(before[this.menu_index]?.selectAll_index != after[this.menu_index]?.selectAll_index) this.switchAll()
		}
	},
	methods: {
		switchMarche(marche_id){
			let marches_ids = _.cloneDeep(this.$store.state.filters.filters.marches_ids);
			if(marches_ids.includes(marche_id)){
				_.pull(marches_ids, marche_id);
			}else{
				marches_ids.push(marche_id);
			}
			this.$store.dispatch('setFilters', {marches_ids});
		},
		reset_filters(){
			this.$store.dispatch('resetMarches');
		},
		switchAll(){
			if(this.getCheckAllState == 'on'){
				this.$store.dispatch('setFilters', { marches_ids: [] });
			}else{
				let marches_ids = this.getMarchesFiltresDispos.map(m => m.id);
				this.$store.dispatch('setFilters', { marches_ids });
			}
		}
	},
	computed:{
		getMarchesFiltresDispos(){
			let self = this;
			let _marches = [];
			this.$store.state.ui.marches.forEach(function(marche){
				if(self.$store.state.filters.marches_ids.includes(marche.id)) _marches.push(marche);
			})
			return _marches;
		},
		getCheckAllState(){
			let nbr_checked = this.$store.state.filters.filters.marches_ids ? this.$store.state.filters.filters.marches_ids.length : 0;
			if(nbr_checked == 0) return '';
			let nbr_items = this.getMarchesFiltresDispos.length;
			return nbr_checked == nbr_items ? 'on' : 'mid';
		}
	},
	components: {}
}