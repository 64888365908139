export default {
  data() {
    return {

    }
  },
  props: ['entreprise'],
  methods:{
    getAffichageCapacite(entreprise, capacite){

      let capacite_value = '';

      if(entreprise.capacites && typeof entreprise.capacites[capacite.id] != 'undefined'){

        //AS--> Récupère la valeur de la capacité
        capacite_value = entreprise.capacites[capacite.id]

        //AS--> Récupère le type de capacité
        let fonctionnenement = this.$store.getters.getCapaciteFonctionnement(capacite.id);

        //AS--> Si c'est un boolean, on affiche oui ou non
        if(fonctionnenement == 'boolean') capacite_value = capacite_value ? this.$t('oui') : this.$t('non')
      }

      return capacite_value;
    }
  },
  computed:{
    getColumns(){
      let capacites = [];
      _.forEach(this.$store.state.ui.sourcing.capacites, function(cap_infos, cap_field){
        if(cap_infos.colonne){
          cap_infos['field'] = cap_field;
          capacites.push(cap_infos);
        }
      });
      capacites = _.orderBy(capacites, ['ordre'], ['asc']);
      return capacites;
    }
  }
}