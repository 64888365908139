export default {
	data() {
    return {
    	
    }
  },
  props: ['entreprise'],
  computed:{
    getDistance() {
      return this.entreprise.dist
    }
  }
}