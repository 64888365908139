import vueCustomScrollbar from 'vue-custom-scrollbar'
import _ from 'lodash'
import ParcellesFiltres from './filtres/filtre_parcelles.vue'
import FiltreHeader from './filtres/_filtre_header.vue'

export default {
	data() {
		return {
			reinit_index: 0
		}
	},
	created(){},
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
		},
		pushReinit(){
			this.reinit_index++
		}
	},
	components: {
    	vueCustomScrollbar,
    	ParcellesFiltres,
    	FiltreHeader
	}
}