import vueCustomScrollbar from 'vue-custom-scrollbar'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import filter_parametres from './filter_params.vue'
import filter_acteurs from './filter_actors.vue'
import filter_dechets from './filter_wast.vue'
import filter_secteurs from './filter_sector.vue'
import filter_caracteristiques from './filter_characteristics.vue'
import filter_variables from './filter_variables.vue'
import filter_contacts from './filter_contacts.vue'
import filter_parcelles from './filter_parcelles.vue'
import filter_suivi from './filter_suivi.vue'

export default {
	data() {
    return {
      settings: {
        // maxScrollbarLength: 60
      },
      height: '0vh'
    }
  },
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
		}
	},
	computed:{
		filterIndex(){
			return this.$store.state.ui.show_filter_index;
		},
		myStyles(){
			return {
				transform: 'translateY('+this.height+')'
			}
		}
	},
	watch: {
		filterIndex(newValue, oldValue){
			this.height = '-'+((newValue-1)*100)+'vh';
		}
	},
	components: {
		vueCustomScrollbar,
		VueSlider,
		filter_parametres,
		filter_acteurs,
		filter_dechets,
		filter_secteurs,
		filter_caracteristiques,
		filter_variables,
		filter_contacts,
		filter_parcelles,
		filter_suivi
  }
}