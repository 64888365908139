import _ from 'lodash'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Debounce from 'lodash/debounce'

export default {
	data() {
    return {
      // marks: null,
      is_float: null,
      dechet_qty: ['','']
    }
  },
  props: ['item', 'type'],
  created(){},
  mounted(){
    // this.marks = this.item.valeurs;
    // this.item.old_valeur = _.clone(this.item.valeurs);
    // this.is_float = false;
    // if(this.item.valeurs[0] == 0 && this.item.valeurs[1] == 1){
    //   this.is_float = true;
    //   this.marks = {0: {label:0},100: {label: '1'}}
    //   this.item.valeurs[1] = 100;
    // }
  },
  methods:{
    get_custom_filter(){
    },
    set_custom_slider_filter(value){
    },
    formatData(value){
      if (this.is_float) {
        return value/100;
      } else {
        return value;
      }
    },
    switch_carac(){
      if (this.type == 'carac') {
        this.$store.dispatch('switchFilterCarac', this.item);
      }else{
        this.$store.dispatch('switchFilterVariable', this.item);
      }
    },
    getVal(valeurs, check_float, key){
      let filter_id = this.item.id
      if(check_float && this.is_float && valeurs[filter_id][1] <= 1){
        valeurs[filter_id][0] = valeurs[filter_id][0] * 100;
        valeurs[filter_id][1] = valeurs[filter_id][1] * 100;
      }

      return key == 'all' ? valeurs[filter_id] : valeurs[filter_id][key]
    },
    setVal(valeur, key){
      let v = _.cloneDeep(valeur)
      if(this.is_float){
        v[0] = v[0]/100;
        v[1] = v[1]/100;
      }

      if (this.type == 'carac') {
          let filter = _.cloneDeep({filter_id:this.item.id, value: v})
          this.$store.dispatch('setCaracteristiques',filter)
        }else{
          let filter = _.cloneDeep({variable_code:this.item.id, value: v})
          this.$store.dispatch('setVariables',filter)
        }
      }
  },
  watch:{},
  computed:{
    getSliderStyle(){
      return {display: this.minMaxEqual ? 'none' : 'inherit'}
    },
    minMaxEqual(){
      return this.item.vals[0] == this.item.vals[1]
    },
    getCaracCheck(){
      if (this.type == 'carac') {
        return this.$store.state.filters.caracteristiques_ids.includes(this.item.id)
      }else{
        return this.$store.state.filters.variables_codes.includes(this.item.id)
      }
    },
    marks(){
      let marks = this.item.vals;
      this.item.old_valeur = _.clone(this.item.vals);
      this.is_float = false;
      if(this.item.vals[0] == 0 && this.item.vals[1] == 1){
        this.is_float = true;
        marks = {0: {label:0},100: {label: '1'}}
        this.item.vals[1] = 100;
      }

      return marks
    },
    getMinValue:{
      get: function () {
        let caracteristiques = _.cloneDeep(this.$store.state.filters.caracteristiques)
        let data_caracteristiques = this.$store.state.ui.custom_filters

        let variables = _.cloneDeep(this.$store.state.filters.variables)
        let data_variables = this.$store.state.ui.variables_filters

        return this.getVal(this.type == 'carac' ? caracteristiques : variables, false, 0)
      },
      // mutateur
      set: function (value) {
        let filter_id = this.item.id
        let caracteristiques = _.cloneDeep(this.$store.state.filters.caracteristiques)

        let variables = _.cloneDeep(this.$store.state.filters.variables)
        if (this.type == 'carac') {
          let _v = caracteristiques[filter_id]
          _v[0] = _.cloneDeep(value)
          
          if (_v[1] < _v[0]) _v[0] = _v[1]
          if (this.marks[0] > _v[0]) {
            _v[0] = this.marks[0]
          }

          let filter = _.cloneDeep({filter_id:this.item.id, value: _v})
          this.$store.dispatch('setCaracteristiques',filter)
        }else{
          let _v = variables[filter_id]
          _v[0] = _.cloneDeep(value)
          
          if (_v[1] < _v[0]) _v[0] = _v[1]
          if (this.marks[0] > _v[0]) {
            _v[0] = this.marks[0]
          }

          let filter = _.cloneDeep({variable_code:this.item.id, value: _v})
          this.$store.dispatch('setVariables',filter)
        }
      }
    },
    getMaxValue:{
      get: function () {
        let caracteristiques = _.cloneDeep(this.$store.state.filters.caracteristiques)
        let data_caracteristiques = this.$store.state.ui.custom_filters

        let variables = _.cloneDeep(this.$store.state.filters.variables)
        let data_variables = this.$store.state.ui.variables_filters
        return this.getVal(this.type == 'carac' ? caracteristiques : variables, false, 1)
      },
      // mutateur
      set: function (value) {
        let filter_id = this.item.id
        let caracteristiques = _.cloneDeep(this.$store.state.filters.caracteristiques)
        let variables = _.cloneDeep(this.$store.state.filters.variables)
        
        if (this.type == 'carac') {
          let _v = caracteristiques[filter_id]
          _v[1] = _.cloneDeep(value)
          
          if (_v[1] < _v[0]) _v[1] = _v[0]
          if (this.marks[1] < _v[1]) {
            _v[1] = this.marks[1]
          }

          let filter = _.cloneDeep({filter_id:this.item.id, value: _v})
          this.$store.dispatch('setCaracteristiques',filter)
        }else{
          let _v = variables[filter_id]
          _v[1] = _.cloneDeep(value)
          
          if (_v[1] < _v[0]) _v[1] = _v[0]
          if (this.marks[1] < _v[1]) {
            _v[1] = this.marks[1]
          }

          let filter = _.cloneDeep({variable_code:this.item.id, value: _v})
          this.$store.dispatch('setVariables',filter)
        }
      }
    },
    getValue:{
      get: function () {
        let caracteristiques = _.cloneDeep(this.$store.state.filters.caracteristiques)
        let data_caracteristiques = this.$store.state.ui.custom_filters

        let variables = _.cloneDeep(this.$store.state.filters.variables)
        let data_variables = this.$store.state.ui.variables_filters

        return this.getVal(this.type == 'carac' ? caracteristiques : variables, true, 'all')
      },
      set: Debounce(function (value) {
        this.setVal(value, 'all')
      }, 500)
    },
    unite(){
      if(this.type == 'carac'){
        let unite = this.$store.getters.getUniteCarac(this.item.id)
        if(unite) unite = '('+unite+')'
        return unite
      }
      return null
    },
  },
  components:{
    VueSlider
  }
}