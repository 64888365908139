<template>
  <div :class="['checkbox', 'checkbox-' + _kebabCase(getField)]">
    <div class="flex">
      <span v-if='picto' :class="['picto', 'picto-'+picto]">
        <icon-base class="icon" :viewBox="viewBox" width="24" height="24"><component v-bind:is="'icon-'+picto" /></icon-base>
      </span>
      <span v-if="label" class="label">{{label}}</span><template v-if="getTooltip()">&nbsp;<span v-tooltip="{content: getTooltip(), placement: 'bottom', offset: 0, delay: { show: 250, hide: 100 } }" class="layer-info has-tooltip">
          <icon-base viewBox="0 0 512 512" iconName=""><IconInfo /></icon-base>
        </span>
      </template>
    </div>
    <div @click="updateValue()">
      <icon-base viewBox="0 0 18 18" :class="['custom_check', 'custom_check_size', {on: value}]" ><icon-selected-dark /></icon-base>
    </div>
  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
export default {
  name: "Checkbox",
  data() {
    return {
      active: false
    };
  },
  props: {
    value: Boolean,
    errors: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    field: String,
    tooltip: String,
    label: String,
    picto: String,
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    action: Function
  },
  created() {
    this.init()
  },
  mounted() {},
  watch: {
    //AS--> Modification de la valeur par l'extérieur
    value: function(after, before){
      
    }
  },
  methods: {

    init(){
      
    },

    //AS--> Transforme le nom du champ en kababcase pour en faire une class
    _kebabCase(field) {
      return kebabCase(field);
    },

    //AS--> Met à jour la value de référence en la passant au parent. La value interne sera mise à jour automatiquement
    updateValue(){
      console.log('updateValue');
      let val = !this.value
      if(this.action){
        this.action(val)
      }else{
        this.$emit('input', val)
      }
    },

    //AS--> Renvoie le tooltip de l'option
    getTooltip(){
      if(this.tooltip) return this.tooltip
      return false
    },

    
  },
  computed: {
    getErrors() {
      return get(this.errors, this.field);
    },

    //AS--> Renvoie le field en fonction des différentes options de paramétrage
    getField(){
      return this.field
    },

    //AS--> Renvoie le label en fonction des différentes options de paramétrage
    getLabel(){
      if(this.params.label) return this.params.label
      if(this.params.name) return this.params.name
      return this.label
    },

  },
  components: {},
};
</script>