import axios from '../../common/axios.js'

export default {
	data(){
		return {
			phase: 1
		}
	},
	methods:{
		valider(){
			axios.get('api=inex/parcelles/'+this.$store.state.parcelle.parcelle_on_id+'/owner')
				.then(function (response) {
					
				}).catch(function (error) {

				})
			this.phase = 2;
		}
	},
	computed:{
		
	},
	components: {}
}