import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Debounce from 'lodash/debounce'
import CustomSlider from '../blocs/custom_slider.vue'

export default {
	data() {
		return {}
	},
	props: ['params'],
	methods: {},
	computed:{
		getPertinance: {
			// accesseur
			get: function() {
				return this.$store.state.filters.filters.pertinance * -1
			},
			// mutateur
			set: Debounce(function(newValue) {
				this.$store.dispatch('setPertinance', newValue * -1);
			}, 500)
		}
	},
	watch: {},
	components: {
		VueSlider,
		CustomSlider
	}
}