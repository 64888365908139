<template>
  <!-- Ajouter la class no_mini_label pour avoir un champ sans label intégré -->
  <!-- Ajouter la class w100 pour que la zone déroulante soit de la largeur de la zone d'input -->
  <div
    class="input select v2"
    :class="['select-' + _kebabCase(field), { errors: getErrors, picto: picto, 'opened': opened, has_data: value && value != empty}]"
  >
    <input
      class="v2"
      :type="type"
      :placeholder="$t(label)"
      :value="value"
      :id="field"
      :name="field"
      :disabled="disabled"
      :step="step"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
      @click.prevent="opened=!opened"
    />
    
    <label :for="field" @click.prevent="opened=!opened">{{ $t(label) }}</label>

    <div :class="['affichage', {'placeholder':hasOptionLabel}]" @click.prevent="opened=!opened">
      {{getOptionLabel}}
    </div>

    <div v-if="opened" class='options' style="z-index: 1000">
      
      <!-- Ajout d'un input pour faire une recherche dans la liste -->
      <div v-if="showSelfSearch" class="selfsearch">
        <CustomInput field="selfsearch" :autoFocus="true" v-model="selfsearch_value" class="no_mini_label" :label="$t('filtrer_liste')" :vider="true" />
      </div>

      <ul>

        <li v-if="empty != 'noempty'" @click='empty_value()'>{{empty_label}}</li>
        <li v-for="_option in sort(getOptions)" @click='select(_option)'>{{_option.label}}</li>

        <!-- Pour debugger -->
        <!-- <li><small>{{empty}}</small></li>
        <li><small>{{options}}</small></li> -->
        <!-- li>{{value}}</li>
        <li>{{option}}</li>
        <li>{{options}}</li -->

      </ul>

    </div>

    <span v-if='picto' class="picto">
      <icon-base :viewBox="viewBox" width="14" height="14"><component v-bind:is="picto" /></icon-base>
    </span>
    <span v-for="(error, key) in getErrors" :key="key" class="fields-errors">{{
      error
    }}</span>
  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import _ from "lodash";
import CustomInput from "./Input";
export default {
  name: "Select",
  data() {
    return {
      option: {},
      selfsearch_value: '',
      opened: false
    };
  },
  props: {
    value: {},
    options: {
      type: Array
    },
    datas: Object,
    errors: Object,
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: -1,
    },
    step: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selfsearch: {
      type: Boolean,
      default: false,
    },
    field: String,
    label: String,
    placeholder: String,
    picto: String,
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    //AS--> Pour donner la possibilité de vider le champ, indiquer une valeur à vide
    empty:{
      default: 'noempty'
    },
    //AS--> Pour changer le label du vide
    empty_label:{
      type: String,
      default: '---'
    }
  },
  created() {
    let self = this
    if(this.value != null && typeof this.value != 'undefined' && (this.value.length || typeof this.value == 'number') && this.options.length){
      this.options.forEach(function(option){
        if(option.value == self.value) self.option = option
      })
    }
  },
  mounted(){
    //AS--> Ajout un événement sur le body pour fermer la modale
    document.querySelector("#app_content").addEventListener('click', this.close);
  },
  beforeDestroy: function() {
    document.querySelector("#app_content").removeEventListener('click', this.close);
  },
  watch: {
    value: function(){
      let self = this
      if(this.value != null && typeof this.value != 'undefined' && (this.value.length || typeof this.value == 'number') && this.options.length){
        this.options.forEach(function(option){
          if(option.value == self.value) self.option = option
        })
      }else{
        this.option = {}
        this.opened = false
      }
    }
  },
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
    select(option){
      this.option = option
      this.$emit('input', option.value)
      this.opened = false
    },
    close(event){
      let self = this
      setTimeout(function(){
        if (event.target.closest('.input.select') == null) {
          self.opened = false
        }
      }, 10)
    },
    empty_value(){
      this.option = {}
      this.$emit('input', this.empty)
      this.opened = false
    },
    sort(list){
      return _.sortBy(list, 'label')
    }
  },
  computed: {
    getErrors() {
      return get(this.errors, this.field);
    },
    showSelfSearch(){
      return this.selfsearch && this.options.length > 10
    },
    getOptions(){
      //AS--> S'il n'y a pas de recherche interne, on renvoie toutes les options
      if(!this.showSelfSearch || !this.selfsearch_value) return this.options

      //AS--> Sinon on filtre les options à renvoyer
      // let regex = new RegExp(this.selfsearch_value.split('').join('.*'), 'i') // regex du style a.*g.*s.*t.* ...
      let regex = new RegExp('.*'+this.selfsearch_value+'.*', 'i') // regex 'qui contient'
      return _.filter(this.options, function(o) {
        return o.label && o.label.match(regex)
      });
    },
    getOptionLabel(){
      if(this.value == this.empty) return this.empty_label
      return this.option.label || this.placeholder || this.$t(this.label)
    },
    hasOptionLabel(){
      if(this.value == this.empty) return false
    }
  },
  components: {
    CustomInput
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.select {
  .fields-errors {
    margin: 0;
    color: red;
    padding: 0;
    display: block;
    height: auto;
    padding: 0 2px;
    // background-color: fade-out(red, 0.8);
    // border: 1px solid fade-out(red, 0.8);
    // border-radius: 5px;
  }
}
</style>
