export default {
	data() {
		return {
			
		}
	},
	props: {
		closeBtn: { 		//AS--> Pour afficher ou non la croix de fermeture
			type: Boolean,
			default: true
		},
		picto: {
			default: false
		},
		titre: {
			default: false
		},
		w: {
			type: Number,
			default: 24
		},
		h: {
			type: Number,
			default: 24
		},
		vw: {
			type: Number,
			default: 24
		},
		vh: {
			type: Number,
			default: 24
		}
	},
	created(){
		console.log('modale.js created --')
	},
	methods: {
		
	},
	computed:{
	},
	components:{
		
	}
}