import _ from 'lodash'
import vueCustomScrollbar from 'vue-custom-scrollbar'

import FiltreHeader from './_filtre_header.vue'

import filtre_title from './filtre_title.vue'
import filtre_show_companies from './filtre_show_companies.vue'
import filtre_show_heatmap from './filtre_show_heatmap.vue'
import filtre_layers from './filtre_layers.vue'
import filtre_separator from './filtre_separator.vue'
import filtre_families from './filtre_families.vue'
import filtre_sectors from './filtre_sectors.vue'
import filtre_spacer from './filtre_spacer.vue'
import filtre_quantity from './filtre_quantity.vue'
import filtre_relevance from './filtre_relevance.vue'
import filtre_contacts from './filtre_contacts.vue'
import filtre_waste from './filtre_waste.vue'
import filtre_variables from './filtre_variables.vue'
import filtre_caracteristics from './filtre_caracteristics.vue'
import filtre_markets from './filtre_markets.vue'
import filtre_skills from './filtre_skills.vue'
import filtre_statuses from './filtre_statuses.vue'
import filtre_parcelles from './filtre_parcelles.vue'

export default {
	data() {
    return {
      settings: {
        // maxScrollbarLength: 60
      },
      height: '0vh'
    }
  },
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
		},

		//AS--> Vérifie si un des filtres de ce menu propose une réinitialisation
		check_reinit(menu){
			let filters = _.cloneDeep(menu.filters)
			filters = filters.map(filter => filter.type)
			return _.intersection(filters, ['caracteristics','contacts','families','markets','parcelles','sectors','skills','variables','waste','show_companies','show_heatmap','layers','statuses']).length > 0
		},

		//AS--> Vérifie si un des filtres de ce menu propose une réinitialisation
		check_switchAll(menu){
			let filters = _.cloneDeep(menu.filters)
			filters = filters.map(filter => filter.type)
			return _.intersection(filters, ['families','markets','sectors','skills','variables','waste','statuses']).length > 0
		},
		hasFilters(menu){
			let self = this;
			let hasFilters = false;
			menu.filters.forEach(function(filter){
				if(
					(typeof self.$store.getters['hasFilters_'+filter.type] == 'function' && self.$store.getters['hasFilters_'+filter.type](filter)) || 
					(typeof self.$store.getters['hasFilters_'+filter.type] == 'boolean' && self.$store.getters['hasFilters_'+filter.type])
				){
					hasFilters = true;
				}
			})
			return hasFilters;
		},
	},
	computed:{
		filterIndex(){
			return this.$store.state.ui.show_filter_index;
		},
		myStyles(){
			return {
				transform: 'translateY('+this.height+')'
			}
		},
	},
	watch: {
		filterIndex(newValue, oldValue){
			this.height = '-'+((newValue-1)*100)+'vh';
		}
	},
	components: {
		vueCustomScrollbar,

		FiltreHeader,

		filtre_title,
		filtre_show_companies,
		filtre_show_heatmap,
		filtre_layers,
		filtre_separator,
		filtre_families,
		filtre_sectors,
		filtre_spacer,
		filtre_quantity,
		filtre_relevance,
		filtre_contacts,
		filtre_waste,
		filtre_variables,
		filtre_caracteristics,
		filtre_markets,
		filtre_skills,
		filtre_parcelles,
		filtre_statuses,
  }
}