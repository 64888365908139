import _ from 'lodash'
import Loading from './../../common/ui/loading_inex.vue';

export default {
	data() {
		return {
			carousel_index: 0
		}
	},
	created(){
		
	},
	watch:{
		"$store.state.entreprises.stats_familles": function(after, before){
			//AS--> Remet le carrousel sur la position 0 si aucune famille n'est retournée par la route index
			if(before == 'loading' && Array.isArray(after) && after.length == 0){
				this.carousel_index = 0
			}
		}
	},
	methods: {
		incrementCarousel(sens){
			let new_index = this.carousel_index + sens

			let max_index = 1
			if(this.$store.state.entreprises.stats_familles != 'loading') max_index = _.values(this.$store.state.entreprises.stats_familles).length

			if (new_index < 0) {
				new_index = max_index
			}else if(new_index > max_index){
				new_index = 0
			}

			this.carousel_index = new_index
		},
		getPictoFamille(famille_id){
			let picto = '';
			this.$store.state.ui.sourcing.familles.forEach(function(famille){
				if(famille.id == famille_id) picto = famille.svg_light
			})
			return picto;
		},
		getNomFamille(famille_id){
			let titre = '';
			this.$store.state.ui.sourcing.familles.forEach(function(famille){
				if(famille.id == famille_id) titre = famille.titre
			})
			return titre;
		},
		getTotalFormat(total) {
			return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(Math.round(total, 1))
		},
		getPvrMeta(pvr) {
			return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(Math.round(pvr, 0))
		},
		getCapaciteStatSomme(id, val){
			let value = '';
			_.forEach(this.$store.state.ui.sourcing.capacites, function(cpct){
				if(cpct.id == id){
					value = val / cpct.stat_somme_facteur;
					let digits = 0
					if(value < 1000) digits = 1
					if(value < 10) digits = 2
					value = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: digits }).format(value) + ' ' + cpct.stat_somme_unite;
				}
			})
			return value;
		},
		getCapaciteStatMoyenne(id, val){
			let value = '';
			_.forEach(this.$store.state.ui.sourcing.capacites, function(cpct){
				if(cpct.id == id){
					let digits = 0
					if(val < 1000) digits = 1
					if(val < 10) digits = 2
					value = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: digits }).format(val) + ' ' + cpct.unite;
				}
			})
			return value;
		},
		getCapaciteSommeLabel(id){
			let label = '';
			_.forEach(this.$store.state.ui.sourcing.capacites, function(cpct){
				if(cpct.id == id){
					label = cpct.stat_somme_label;
				}
			})
			return label;
		},
		getCapaciteMoyenneLabel(id){
			let label = '';
			_.forEach(this.$store.state.ui.sourcing.capacites, function(cpct){
				if(cpct.id == id){
					label = cpct.stat_moyenne_label;
				}
			})
			return label;
		}
	},
	computed:{
		getNbrCompanies() {
			return this.$store.state.entreprises.total
		},
		getTotal() {
			return this.$store.state.entreprises.qty_dechets
		},
		getMoyenne() {
			let value = this.$store.state.entreprises.total_liste > 0 ? Math.round(this.getTotal / this.$store.state.entreprises.total_liste, 1) : 0
			return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(value)
		},
		getStats(){
			let self = this
			let stats = _.values(this.$store.state.entreprises.capacites_stats);
			
			stats.forEach(function(stat){
				self.$store.state.ui.sourcing.familles.forEach(function(famille){
					if(famille.id == stat.f_id){
						stat.picto = famille.svg_dark
						stat.f_nom = famille.titre
					}
				})
				stat.famille_nbr = self.$store.state.entreprises.familles_nbr[stat.f_id]
				stat.unite = self.$store.state.ui.sourcing.capacites[stat.c_id].stat_somme_unite
				stat.label = self.$store.state.ui.sourcing.capacites[stat.c_id].stat_somme_label
				//AS--> Calcul la stat et l'arrondi
				let value = stat.stat / self.$store.state.ui.sourcing.capacites[stat.c_id].stat_facteur;
				let digits = 0
				if(value < 1000) digits = 1
				if(value < 10) digits = 2
				stat.stat = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: digits }).format(value);
			})
			return stats;
		},
		getStyleCarousel(){
			let style = {}

			if(this.$store.state.entreprises.stats_familles == 'loading' && this.carousel_index > 1){
				this.carousel_index = 1
				style.transition = 'none'
			}
			let y = 56 * this.carousel_index

			style.transform = 'translateY(-'+y+'px)'
			return style;
		},
		getNbrParcelles(){
			if (this.$store.state.parcelle.menu_parcelles_affiche == false) return false
			if (this.$store.getters.getNbrParcelles == null) return false

			return this.$store.getters.getNbrParcelles > 100 ? '100+' : this.$store.getters.getNbrParcelles
		},
	},
	components:{
		Loading
	}
}