<template>
	<div class="loading-inex">
		<div class="loading" :style="{height: height}">
			<div class="barre" style="animation-delay: 0s;"></div>
			<div class="barre" style="animation-delay: 0.1s;"></div>
			<div class="barre" style="animation-delay: 0.2s;"></div>
			<div class="barre" style="animation-delay: 0.3s;"></div>
			<div class="barre" style="animation-delay: 0.4s;"></div>
			<div class="barre" style="animation-delay: 0.5s;"></div>
		</div>
		<template v-if="label">
			{{$t(label)}}
		</template>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	props: {
		label: {
			type: String|Boolean,
			default: "loading_inex_text"
		},
		height: {
			type: String,
			default: "16px"
		}
	}
}
</script>