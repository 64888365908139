import _ from 'lodash'

export default {
	data() {
		return {
			
		}
	},
	props: [],
	created(){
		
	},
	methods: {
		
	},
	computed:{
		configured(){
			return this.$store.state.ui.sourcing.slite && this.$store.state.ui.sourcing.slite.length > 0
		}
	},
	components:{
		
	}
}