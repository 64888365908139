import _ from 'lodash'
import Boutons from './boutons_action.vue'
import Outils from './outils.vue'
import Affichages from './affichages/affichages.vue'
import Telechargements from './telechargements/telechargements.vue'

export default {
	data() {
		return {
			
		}
	},
	props: [],
	created(){
		
	},
	methods: {
		
	},
	computed:{
		
	},
	components:{
		Boutons,
		Outils,
		Affichages,
		Telechargements,
	}
}