<template>
  <div :class="['input-' + _kebabCase(getField)]">
    
    <!-- Ligne de titre avec activation -->
    <div class="slider_filter regular">
      <div class="label">
        <div class="check_bloc" @click="switch_active()">
          <icon-base viewBox="0 0 18 18" :class="['custom_check', {on: active}]" ><icon-selected-dark /></icon-base>
          <span class="title">{{getLabel}}</span>
        </div>
      </div>
    </div>

    <!-- Lignes d'options' -->
    <div v-if="active" class="checkers regular custom_checkers" :class="{'oneColumn':oneColumn}">
      <div v-for="(option, option_i) in getOptions" @click="switch_val(option.value)">
        <icon-base viewBox="0 0 18 18" :class="['custom_check', {on: is_check_val(option.value)}]" ><icon-selected-dark /></icon-base>
        <span class="label">{{option.name}}</span>
      </div>
    </div>

  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
export default {
  name: "Input",
  data() {
    return {
      active: false
    };
  },
  props: {
    value: null,
    options: Object,
    datas: Object,
    errors: Object,
    oneColumn: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: -1,
    },
    step: {
      type: Number,
      default: null,
    },
    multi: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    field: String,
    label: String,
    picto: String,
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    params: {
      type: Object,
      default: {}
    },
  },
  created() {},
  mounted() {},
  watch: {
    //AS--> Réinitialisation des filtres par l'extérieur. Si la value passe à undefined, on ferme le filtre
    value: function(after, before){
      if(typeof after == 'undefined'){
         this.active = false
      }
    }
  },
  methods: {

    //AS--> Transforme le nom du champ en kababcase pour en faire une class
    _kebabCase(field) {
      return kebabCase(field);
    },

    //AS--> Active l'affichage des choix, ou masque les choix et gère l'initialisation de la value en fonction
    switch_active(){
      
      //AS--> Si c'était actif
      if(this.active){
        this.active = false
        this.$emit('input', null)

      //AS--> Si c'était inactif
      }else{
        this.active = true
        this.$emit('input', this.isMultiSelect ? this.getOptionsValues : this.getOptions[0].value)
      }

    },

    //AS--> Mais à jour la value de référence en la passant au parent. La value interne sera mise à jour automatiquement
    updateValue(vals){
      this.$emit('input', vals)
    },

    //AS--> Ajoute ou retire une valeur
    switch_val(val){
      let _values = this.value

      if(this.isMultiSelect){
        if(_values.includes(val)){
          _values = _.without(_values, val);
        }else{
          _values.push(val);
        }
      }else{
        _values = val
      }

      this.updateValue(_values)
    },

    //AS--> Vérifie qu'une valeur est choisie ou non
    is_check_val(val){
      if(this.isMultiSelect){
        return this.value && this.value.length && this.value.includes(val)
      }else{
        return this.value === val
      }
    }
  },
  computed: {
    getErrors() {
      return get(this.errors, this.field);
    },

    //AS--> Renvoie le field en fonction des différentes options de paramétrage
    getField(){
      if(this.params.code) return this.params.code
      return this.field
    },

    //AS--> Renvoie le label en fonction des différentes options de paramétrage
    getLabel(){
      if(this.params.label) return this.params.label
      if(this.params.name) return this.params.name
      return this.label
    },

    //AS--> Renvoie la liste des options en fonction des différentes options de paramétrage
    getOptions(){
      let options = []
      if(this.params.options){
        options = this.params.options
      }else{
        options = this.options
      }
      return options
    },

    //AS--> Renvoie la liste des valeurs disponibles en fonction des différentes options de paramétrage
    getOptionsValues(){
      let options = []
      let _options = []
      if(this.params.options){
        _options = this.params.options
      }else{
        _options = this.options
      }
      if(_options.length){
        _.forEach(_options, function(option){
          options.push(option.value)
        })
      }
      return options
    },

    //AS--> Vérifie si on est en fonctionnement multiselect en fonction des différentes options de paramétrage
    isMultiSelect(){
      if(this.params.type && this.params.type == 'select') return false
      if(this.params.type && this.params.type == 'multiselect') return true
      return this.multi
    }
  },
  components: {},
};
</script>