export default {
	props: ['index'],
	methods: {
		switch_allfavoris(event){
			this.$store.dispatch('setCompany', null);
			if (this.$store.state.ui.content == 'allfavoris') {
				this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
				this.$store.commit('UI_SET_CONTENT', {content: 'result'});
			} else {
				this.$store.commit('UI_SET_SHOW_FAVORIS_MENU', true)
				this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
				this.$store.commit('UI_SET_CONTENT', {content: 'allfavoris'});
			}
		},
	}
}