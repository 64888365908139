import axios from '../../common/axios.js'
import vSelect from 'vue-select'
import carousel from 'vue-owl-carousel'
import Contacts from '../blocs/contacts.vue'

export default {
	data(){
		return {
			dechet: null,
			dechet_detail: '',
			tonnage: null,
			tonnage_detail: '',
			contact: null,
			contact_detail_nom: '',
			contact_detail_tel: '',
			remarques: '',
		}
	},
	props: ['params'],
	methods:{
		send(){
			let self = this
		}
	},
	computed:{
		getStars(){
			this.note_hover
			this.note
			return this.stars
		}
	},
	components: {}
}