import Barre from '../blocs/barre.vue'

export default {
	data() {
    return {
    	
    }
  },
  props: ['entreprise'],
  computed:{
    
  },
  components: {
    Barre
  }
}