import StatusesSelector from '../../common/ui/statuses_selector.vue'

export default {
    data() {
        return {}
    },
    props: ['entreprise'],
    methods:{
    },
    components: {
        StatusesSelector
    },
}