import Debounce from 'lodash/debounce'
import CompanyDetail from './blocs/company_detail.vue'
import Barre from './blocs/barre.vue'
import axios from '../common/axios.js'

import Colonne_familles from './colonnes/colonne_familles.vue'
import Colonne_site from './colonnes/colonne_site.vue'
import Colonne_nom from './colonnes/colonne_nom.vue'
import Colonne_commune from './colonnes/colonne_commune.vue'
import Colonne_secteur from './colonnes/colonne_secteur.vue'
import Colonne_nouveau from './colonnes/colonne_nouveau.vue'
import Colonne_distance from './colonnes/colonne_distance.vue'
import Colonne_ip from './colonnes/colonne_ip.vue'
import Colonne_dechets from './colonnes/colonne_dechets.vue'
import Colonne_dechets_total from './colonnes/colonne_dechets_total.vue'
import Colonne_variables from './colonnes/colonne_variables.vue'
import Colonne_capacites from './colonnes/colonne_capacites.vue'
import Colonne_identifiant from './colonnes/colonne_identifiant.vue'
import Colonne_telephone from './colonnes/colonne_telephone.vue'
import Colonne_statut from './colonnes/colonne_statut.vue'
import Colonne_comment from './colonnes/colonne_comment.vue'

export default {
	data() {
		return {
			visible: false,
			total: []
		}
	},
	props: ['index', 'entreprise', 'columns'],
	created() {},
	beforeDestroy: function() {
		// if(self.unwatch_state_ui_plage) self.unwatch_state_ui_plage();
	},
	mounted() {},
	methods: {
		numberFormat(number) {
			if (number >= 0 && number <= 1) {
				return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(number);
			} else {
				return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(number);
			}
		},
		getTooltip(item) {
			return '<span>' + item.nom + '</span><br><strong>' + item.total + ' t</strong>'
		},
		getStyle(bar, column) {
			let style = {}

			if (column.total == 0) {
				style.width = Math.round(100 / column.nbr, 2) + '%'
			} else {
				style.width = Math.round((bar.total * 100) / column.total, 2) + '%'
			}

			if (bar.bg) {
				if (bar.bg.substring(0, 15) == 'linear-gradient') {
					style['background-image'] = bar.bg
				} else {
					style['background-color'] = bar.bg
				}
			}
			return style
		},
		getTotalBar(total) {
			if (total >= 0 && total <= 1) {
				return numberFormat(total);
			} else {
				return numberFormat(total);
			}
		},
		setHover: Debounce(function(e) {
			if (e.target.closest('.item-company:hover') != null) {
				let company_id = this.entreprise.id
				//				this.$store.dispatch('set_company_hover', company_id)
			}
		}, 200),
		switchRecherche_compagnyId(entreprise_id){
			if(!this.$store.state.ui.recherche_supprIds.includes(entreprise_id)){
				this.$store.dispatch('add_recherche_supprId', entreprise_id)
			}else{
				this.$store.dispatch('remove_recherche_supprId', entreprise_id)
			}
		}
	},
	computed: {
		isLeadCompanie() {
			return this.entreprise.lead == 1;
		},
		isActive() {
			return this.entreprise.id == this.$store.state.company.company.id
		},
		isVisibleInfo() {
			return this.$store.state.filters.filters.q == ''
		},
		getCompanyName() {
			return this.entreprise.nom
		},
		getCompanyNameTruncat() {
			return _.truncate(this.entreprise.nom, { length: 50 })
		},
		getNace() {
			return typeof this.$store.state.ui.code_naces[this.entreprise.nace_id] != 'undefined' ? this.$store.state.ui.code_naces[this.entreprise.nace_id]['titre'] : ''
		},
		getNaceTruncat() {
			return typeof this.$store.state.ui.code_naces[this.entreprise.nace_id] != 'undefined' ? _.truncate(this.$store.state.ui.code_naces[this.entreprise.nace_id]['titre'], { length: 40 }) : ''
		},
		getCompanyCall() {
			return this.$store.state.user.companies_calls.includes(this.entreprise.id)
		},
		isSelected() {
			return this.$store.state.ui.selected_companies.includes(this.entreprise.id)
		},
		notInUserFavori() {
			return !Object.keys(this.$store.state.user.favoris_ids).includes(this.entreprise.id.toString())
		},
		notInUserCall() {
			return !this.$store.state.user.companies_calls.includes(this.entreprise.id)
		},
		notInUserDownloadedIds() {
			return !this.$store.state.user.downloaded_ids.includes(this.entreprise.id)
		}
	},
	components: {
		CompanyDetail,
		Barre,
		Colonne_familles,
		Colonne_site,
		Colonne_nom,
		Colonne_commune,
		Colonne_secteur,
		Colonne_nouveau,
		Colonne_distance,
		Colonne_ip,
		Colonne_dechets,
		Colonne_variables,
		Colonne_capacites,
		Colonne_identifiant,
		Colonne_telephone,
		Colonne_dechets_total,
		Colonne_statut,
		Colonne_comment
	}
}