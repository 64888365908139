import _ from 'lodash'
import MenuCalculerIsoline from './menus/calculer_isoline.vue'
import MenuCalculerItineraire from './menus/calculer_itineraire.vue'
import MenuMesurerDistance from './menus/mesurer_distance.vue'
import MenuMesurerSurface from './menus/mesurer_surface.vue'

export default {
	data() {
		return {
			open: false
		}
	},
	props: [],
	mounted(){
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector(".c-results-page").addEventListener('click', this.close_modal_outter);
	},
	beforeDestroy: function() {
		document.querySelector(".c-results-page").removeEventListener('click', this.close_modal_outter);
	},
	methods: {
		close_modal_outter(event) {
			let self = this
			setTimeout(function(){
				if (event.target.closest('#outils > button') == null && !self.$store.state.ui.map_tools_surface && !self.$store.state.ui.map_tools_distance) {
					self.close_modal()
				}
			}, 10)
		},
		close_modal(event) {
			this.open = false
		}
	},
	computed:{
		menus(){
			let self = this
			let outils = this.$store.state.ui.sourcing.outils.split(',')
			let menus = []
			if(outils.length){
				outils.forEach(function(outil){

					//AS--> Skip les anciennes fonctionnalités indésirables
					if(['filtre_zone'].includes(outil)) return

					outil = outil.replace('_', '-')

					//AS--> Cas spécial pour mesurer-surface
					if(outil == "mesurer-distance" && (self.$store.state.ui.content != 'result' || !self.$store.getters.canMesureDistance)) return

					//AS--> Cas spécial pour mesurer-surface
					if(outil == "mesurer-surface" && (self.$store.state.ui.content != 'result' || !self.$store.getters.canMesureSurface)) return

					//AS--> Cas spécial pour calculer itinéraire
					if(outil == "calculer-itineraire" && self.$store.state.ui.content != 'result') return

					//AS--> Cas spécial pour calculer isoline
					if(outil == "calculer-isoline" && self.$store.state.ui.content != 'result') return

					menus.push(outil)
				})
			}
			menus = _.filter(menus, function(menu){ return ![''].includes(menu) })
			
			return menus
		}
	},
	components:{
		MenuCalculerIsoline,
		MenuCalculerItineraire,
		MenuMesurerDistance,
		MenuMesurerSurface,
	}
}