import { render, staticRenderFns } from "./filter_characteristics.vue?vue&type=template&id=cfd4a4fa"
import script from "./filter_characteristics.js?vue&type=script&lang=js&external"
export * from "./filter_characteristics.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports