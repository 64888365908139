import axios from '../../../common/axios.js'
import _ from 'lodash'
import html2canvas from "html2canvas"
// import domtoimage from "dom-to-image-more"
import jsPDF from 'jspdf' 
import MenuSousMenu from '../../../common/ui/menu_sous-menu.vue'

export default {
	data() {
		return {
			open: false,
			img: null
		}
	},
	props: [],
	created(){
		
	},
	mounted(){
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector(".c-results-page").addEventListener('click', this.close_modal);
	},
	beforeDestroy: function() {
		document.querySelector(".c-results-page").removeEventListener('click', this.close_modal);
	},
	methods: {
		close_modal(event) {
			this.open = false
		},
		set_mode(mode){
			this.$store.dispatch('set_mode_affichage', mode)
			
			if(mode == 'graph'){
				this.$store.dispatch('openGraph')
			}else{
				this.$store.dispatch('openCarte')
			}
		},

		//AS--> Methode html2canvas
		screenPdf(){
			let self = this

			this.$store.commit('UI_SET_SCREEN_PDF_ON', true);

			setTimeout(function(){

				let node = document.getElementById('app_content')
				
				const doc = new jsPDF({
					orientation: "landscape",
				});
				html2canvas(node, {foreignObjectRendering:true})
					.then(function (canvas) {
						self.$store.commit('UI_SET_SCREEN_PDF_ON', false);
						const img = canvas.toDataURL("image/png");
						self.img = img
						console.log(img);
						doc.addImage(img,'JPEG',0,0);
						doc.save("sample.pdf");
					})

			}, 50)
				
		},

		//AS--> Methode getDisplayMedia
		screenPdfDisplayMedia(){
			const canvas  = document.createElement("canvas");
			const context = canvas.getContext("2d");
			const video   = document.createElement("video");

			const doc = new jsPDF({
					orientation: "landscape",
				});

			// Request media
			navigator.mediaDevices.getDisplayMedia()
				.then(stream => {
				  // Grab frame from stream
				  let track = stream.getVideoTracks()[0];
				  let capture = new ImageCapture(track);
				  capture.grabFrame().then(bitmap => 
				  {
				    // Stop sharing
				    track.stop();
				      
				    // Draw the bitmap to canvas
				    canvas.width = bitmap.width;
				    canvas.height = bitmap.height;
				    canvas.getContext('2d').drawImage(bitmap, 0, 0);
				      
				    const img = canvas.toDataURL("image/png");
					self.img = img
					console.log(img);
					doc.addImage(img,'JPEG',0,0);
					doc.save("sample.pdf");
				  });
				})
				.catch(e => console.log(e));
		},

		//AS--> Methode printScreen
		screenPdfDisplayMedia(){
			window.print()
		},

		exportXls(){
			this.$store.commit('UI_SET_EXPORT_ID', false)
			this.$store.commit('UI_SET_EXPORT_TYPE', 'csv')
			this.$store.dispatch('open_modal', 'export_list')
		},

		exportCrm(){
			this.$store.commit('UI_SET_EXPORT_ID', false)
			this.$store.commit('UI_SET_EXPORT_TYPE', 'crm')
			this.$store.dispatch('open_modal', 'export_list')
		},
		openPendingFlowModal(promise) {
			let self = this

			let trad_code = 'waste_dashboard'

			self.$store.dispatch('open_modal', {
				code: 'pending_api', params: {
					promise,					
					pending_title: 'modal_export_pending_title_' + trad_code,
					pending_message: 'modal_export_pending_message_' + trad_code,
					success_title: 'modal_export_success_title_' + trad_code,
					success_message: 'modal_export_success_message_' + trad_code,
					error_title: 'modal_export_error_title_' + trad_code,
					error_message: 'modal_export_error_message_' + trad_code,
					error_html: '<p>' + self.$t('modal_export_error_message_contact_' + trad_code) + '<br><a href="mailto:' + self.$t('modal_export_error_message_contact_mail_' + trad_code) + '">' + self.$t('modal_export_error_message_contact_mail_' + trad_code) + '</a></p>'
				}
			})
		},
		exportMatieres(){
			let self = this
			const promise = axios.post("api=inex/companies/export/xls/{view_code}/waste_dashboard", self.$store.getters.getFiltersForExport, {
				headers: {'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
					responseType: 'arraybuffer'
			}).then(function (response) {
				return Promise.resolve(response)
			}).catch(function (error) {
				return Promise.reject(error)
			})
			// .finally(function(){
			// 	self.$store.dispatch('open_modal', 'export_matieres_ok')
			// })

			this.openPendingFlowModal(promise)	
		}
	},
	computed:{
		modes(){
			return this.$store.state.ui.sourcing.modes_affichage.split(',')
		},
		menu_telecharger(){
			let menu = {
				menu: {
					picto: 'Download2',
					label: false
				},
				sousmenu: {
					list: []
				}
			}

			//AS--> Export en Xls
			if(this.$store.getters.canExportCSV){
				menu.sousmenu.list.push({
					picto: 'Xls',
					label: this.$t('statut_bar_export'),
					action: this.exportXls,
					width: 18,
					height: 18
				})
			}

			//AS--> Export en Xls
			if(this.$store.getters.canExportCRM){
				menu.sousmenu.list.push({
					picto: 'Crm',
					label: this.$t('statut_bar_export_crm'),
					action: this.exportCrm,
					viewBox: '0 0 17 13',
					width: 17,
					height: 13
				})
			}

			//AS--> Export des matières
			if(this.$store.getters.canExportMatieres){
				menu.sousmenu.list.push({
					picto: 'Waste',
					label: this.$t('statut_bar_export_matieres'),
					action: this.exportMatieres,
					viewBox: '-2 0 52 46',
					width: 18,
					height: 18
				})
			}

			//AS--> Export en PDF
			menu.sousmenu.list.push({
				picto: 'Image',
				label: this.$t('statut_bar_pdf'),
				action: this.screenPdfDisplayMedia
			})

			return menu
		}
	},
	components:{
		MenuSousMenu
	}
}