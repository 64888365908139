import { render, staticRenderFns } from "./colonne_variables_header.vue?vue&type=template&id=7098e81e"
import script from "./colonne_variables_header.js?vue&type=script&lang=js&external"
export * from "./colonne_variables_header.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports