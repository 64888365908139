import _ from 'lodash'

export default {
	data() {
    return {
    	
    }
  },
  props: ['entreprise'],
  methods:{
    numberFormat(number){
      if (number >= 0 && number <= 1) {
        return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(number);
      }else{
        return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 0}).format(number);
      }
    },
    getDechetNom(dechet_id){
      let nom = '';
      if(this.$store.state.ui.dechets[0]){
        _.forEach(this.$store.state.ui.dechets[0].childs, function(_dechet, _dechet_id){
          if(_dechet_id == dechet_id) nom = _dechet.data.titre
        })
      }
      return nom;
    }
  }
}