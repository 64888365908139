import _ from 'lodash'

export default {
	data() {
		return {}
	},
	props: ['params','menu_index'],
	watch: {
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
			if(before[this.menu_index]?.selectAll_index != after[this.menu_index]?.selectAll_index) this.switchAll()
		}
	},
	methods: {
		switchCompetence(competence_id){
			let competences_ids = _.cloneDeep(this.$store.state.filters.filters.competences_ids);
			if(competences_ids.includes(competence_id)){
				_.pull(competences_ids, competence_id);
			}else{
				competences_ids.push(competence_id);
			}
			this.$store.dispatch('setFilters', {competences_ids});
		},
		reset_filters(){
			this.$store.dispatch('resetCompetences');
		},
		switchAll(){
			if(this.getCheckAllState == 'on'){
				this.$store.dispatch('setFilters', { competences_ids: [] });
			}else{
				let competences_ids = this.getCompetencesFiltresDispos.map(c => c.id);
				this.$store.dispatch('setFilters', { competences_ids });
			}
		}
	},
	computed:{
		getCompetencesFiltresDispos(){
			let self = this;
			let _competences = [];
			this.$store.state.ui.competences.forEach(function(competence){
				if(self.$store.state.filters.competences_ids.includes(competence.id)) _competences.push(competence);
			})
			return _competences;
		},
		getCheckAllState(){
			let nbr_checked = this.$store.state.filters.filters.competences_ids ? this.$store.state.filters.filters.competences_ids.length : 0;
			if(nbr_checked == 0) return '';
			let nbr_items = this.getCompetencesFiltresDispos.length;
			return nbr_checked == nbr_items ? 'on' : 'mid';
		}
	},
}