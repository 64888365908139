export default {
  data() {
    return {

    }
  },
  props: ['entreprise'],
  methods:{
    getAffichageVariable(entreprise, variable){
      if(variable.slice(-5) == '_bool'){
        if(entreprise[variable] === 1){
          return this.$t('oui');
        }else if(entreprise[variable] === 0){
          return this.$t('non');
        }else{
          return '';
        }
      }else{
        return entreprise[variable];
      }
    }
  },
  computed:{
    getColumns(){
      let variables = [];
      _.forEach(this.$store.state.ui.sourcing.variables, function(var_infos, var_field){
        var_infos['field'] = var_field;
        variables.push(var_infos);
      });
      variables = _.orderBy(variables, ['ordre'], ['asc']);
      return variables;
    }
  }
}