import axios from '../../common/axios.js'
import Modale from '../../common/ui/modale.vue'
import moment from 'moment'
import CustomInput from '../../common/forms/Input.vue'

export default {
	data(){
		return {
			erreur: false,
			encours: false,
			succes: false,
			nom: '',
			pardefaut: false,
			recherche_exclue: null,
			combiner: false
		}
	},
	props:{
		mode:{
			default: 'modale' // modale | update
		},
		picto:{
			default: true
		},
		close:{
			default: true
		}
	},
	created(){

	},
	methods:{
		save(){
			var self = this;
			self.$emit('save_start')
			let update = this.mode == 'update';

			if(!update && self.nom == ''){
				self.erreur  = this.$t('save_search_erreur');
				self.encours = false;
			}else{
				self.erreur  = false;
				self.encours = true;
				self.succes  = false;

				let recherche = {}

				//RZ--> Dans le cas d'un update
				if(update){
					recherche           = _.cloneDeep(this.$store.state.ui.recherche)
					recherche.recherche = self.$store.state.filters.filters
					recherche.layers    = self.$store.getters.getLayersToSave
					recherche.update    = true

				//RZ--> Si on est sur un ajout
				}else{
					let email = this.$store.state.user.user.email
					recherche = {
						nom       : self.nom,
						recherche : self.$store.state.filters.filters,
						pardefaut : self.pardefaut,
						layers    : self.$store.getters.getLayersToSave,
						email     : this.$store.state.user.user.email
					}
    				if(this.combiner && this.recherche_exclue) recherche.recherche.substract_search = this.recherche_exclue.nom
				}

				//AS--> Enregistre la recherche
				self.$store.dispatch('send_recherche', recherche).then(function(response){
					self.succes  = true;

					self.$store.commit('UI_SET_RECHERCHE_ID', recherche.nom)
					self.$store.dispatch('setSaveSearch', recherche)

					setTimeout(function(){
						self.$store.dispatch('close_modal', 'save_search')
					},2000);

					//AS--> Recharge les recherches dans le stores
					self.$store.dispatch('load_recherches')

				}).catch(function(error){
					self.succes  = false;
					self.erreur  = error.message ?? null;

				}).finally(function(){
					self.encours = false
					self.$emit('save_end')
				})
				
			}
		}
	},
	computed:{
		recherches(){
			let recherches = _.cloneDeep(this.$store.state.user.recherches)
			recherches.forEach(function(recherche, index){
				if(recherche.nom == "__last__") recherches = _.without(recherches, recherche)
			})
			return recherches
		}
	},
	components:{
		Modale,
		CustomInput
	}
}
