import FiltreLayersGroup from './filtre_layers_group.vue';

export default {
	data() {
		return {
			capacite_opened: [],
			familles_0_show: []
		}
	},
	props: ['params','menu_index'],
	watch: {
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
			if(before[this.menu_index]?.selectAll_index != after[this.menu_index]?.selectAll_index) this.switchAll()
		}
	},
	mounted(){
		let self = this
		this.$store.state.ui.sourcing.familles.forEach(function(famille){
			if(self.hasFamilleId(famille)) self.familles_0_show.push(famille.parent_id)
		})
	},
	methods: {
		reset_filters(){
			this.$store.dispatch('resetActors');
			this.$store.dispatch('resetFamilyLayers');
		},
		getCapacites(famille){
			let capacites = this.$store.state.ui.sourcing.capacites;	//AS--> Capacités définies par le sourcing
			let capacites_data = this.$store.state.ui.capacites 		//AS--> Filtres de capacité redéfini par les retours de index.php
			let _capacites = []
			_.each(capacites, function(item){
				if(item.famille_id == famille.id && item.filtre && (typeof capacites_data[item.id] != 'undefined' && item.fonctionnement != 'numeric')){
					_capacites.push(item)
				}
			})
			_capacites = _.sortBy(_capacites, ['ordre']);

			return _capacites;
		},
		switch_capacite(capacite_id){
			if(this.capacite_opened.includes(capacite_id)){
				this.capacite_opened = this.capacite_opened.filter(a => a !== capacite_id)
			}else{
				this.capacite_opened.push(capacite_id);
			}
	    	// this.$store.dispatch('switchFilterCapacite', capacite_id);
	    },
	    getCapacitesCheck(capacite_id){
			return this.$store.state.filters.capacites_ids.includes(capacite_id);
		},
		getCapacitesData(capacite){
			let data = {}

			if (capacite.fonctionnement == 'boolean') {
				data = {oui: this.$t('oui'), non: this.$t('non')}
			}else{
				let capacites_data = this.$store.state.ui.capacites
				capacites_data[capacite.id].forEach(function(v){
					data[v] = v
				})
			}

			return data;
	    },
	    set_capacite(capacite_id, value){
	    	if(!isNaN(value)) value = parseFloat(value);
			// let capacites = this.$store.state.filters.capacites
			// return capacites[capacite_id].includes(value)
			let filter = { capacite_id, value }
			this.$store.dispatch('setCapacites', filter)
	    },
		allCapacite(capacite_id){
			this.$store.dispatch('setCapacites', {capacite_id: capacite_id, check_all: true})
		},
		noCapacite(capacite_id){
			this.$store.dispatch('setCapacites', {capacite_id: capacite_id, check_all: false})
		},
	    is_check(capacite_id, value){
	    	let capacites = this.$store.state.filters.filters.capacites
	    	console.log(capacites);

	    	if(!isNaN(value)) value = parseFloat(value);

			return capacites[capacite_id] != undefined ? capacites[capacite_id].includes(value) : false
	    },
		hasCapaciteId(capacite){
			return typeof this.$store.state.filters.filters.capacites[capacite.id] != 'undefined' && this.$store.state.filters.filters.capacites[capacite.id] === true 
		},
		hasFamilleId(famille){
			return this.$store.state.filters.filters.familles_ids.includes(famille.id)
		},
		switchAll(){
			if(this.getCheckAllState == 'on'){
				this.$store.dispatch('setFilters', { familles_ids: [] });
			}else{
				let familles_ids = this.$store.state.ui.sourcing.familles.filter(f => f.parent_id != 0).map(f => f.id);
				this.$store.dispatch('setFilters', { familles_ids });
			}
		},
		switchShowFamille(famille){
			if(this.familles_0_show.includes(famille.id)){
				this.familles_0_show = this.familles_0_show.filter(f_id => f_id !== famille.id)
			}else{
				this.familles_0_show.push(famille.id);
			}
		}
	},
	computed:{
		getCheckAllState(){
			let nbr_checked = this.$store.state.filters.filters.familles_ids ? this.$store.state.filters.filters.familles_ids.length : 0;
			if(nbr_checked == 0) return '';
			let nbr_items = this.$store.state.ui.sourcing.familles ? this.$store.state.ui.sourcing.familles.filter(f => f.parent_id != 0).length : 0;
			return nbr_checked >= nbr_items ? 'on' : 'mid';
		}
	},
	components: {
		FiltreLayersGroup
	}
}