import _ from 'lodash'
import uf from './uf.vue'
import chat from './chat.vue'
import slite from './slite.vue'

export default {
	data() {
		return {
			opened: false,
			centre_aide: []
		}
	},
	props: [],
	created(){
		this.centre_aide = this.$store.state.ui.sourcing.centre_aide.length > 0 ? this.$store.state.ui.sourcing.centre_aide.split(',') : []
	},
	mounted(){
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector("#app_content").addEventListener('click', this.close);
	},
	beforeDestroy: function() {
		document.querySelector("#app_content").removeEventListener('click', this.close);
	},
	methods: {
		close(event){
			let self = this
			setTimeout(function(){
				if (event.target.closest('#centre-aide') == null) {
					self.opened = false
				}
			}, 10)
		}
	},
	computed:{
		blocHeight(){
			//AS--> Base
			let height = 70

			//AS--> Si contact
			if(this.showContact) height += 42

			//AS--> Si outils
			if(this.outils) height += 30 * this.outils.length + 13 * (this.outils.length-1)

			//AS--> Si contact + outils, ajout du séparateur
			if(this.showContact && this.outils) height += 24

			return height+'px';
		},
		outils(){
			return _.without(this.centre_aide, 'contact');
		},
		showContact(){
			return this.centre_aide.includes('contact')
		},
		getMail(){
			return this.$store.getters.isRefashion ? 'recycle@refashion.fr' : this.$t('centre_aide_contactez_mail')
		}
	},
	components:{
		uf,
		chat,
		slite
	}
}