import _ from 'lodash'

export default {
	data() {
		return {
			show_choices: false
		}
	},
	created(){
		if(!this.$store.state.ui.barre_recherche_active){
			let barres = this.getBarres
			this.$store.commit('UI_SET_BARRE_RECHERCHE_ACTIVE', barres[0]);
		}
	},
	methods: {
		setBarreRecherche(barre){
			this.show_choices = false;
			this.$store.commit('UI_SET_BARRE_RECHERCHE_ACTIVE', barre);
		}
	},
	computed:{
		getBarres(){
			
			//AS--> Ajoute la barre de recherche de lieu en premier -- 2024-02-06 plus maintenant
			let barres = [] 

			//AS--> Ajoute les autres barres issues de la config BO
			if(this.$store.state.ui.sourcing.barre_recherches) barres = barres.concat(this.$store.state.ui.sourcing.barre_recherches.split(','))

			//AS--> Skip les anciennes fonctionnalités indésirables 'entreprise' et 'adresse' et les résultats vides
			barres = _.filter(barres, function(barre){ return !['entreprise', 'adresse', ''].includes(barre) })

			return barres;
		}
	},
	components:{
		RechercheParcelle: () => import('./recherche_parcelle.vue'),
		// RechercheEntreprise: () => import('./recherche_entreprise.vue'),
		// RechercheAdresse: () => import('./recherche_adresse.vue'),
		RechercheLieu: () => import('./recherche_lieu.vue'),
	}
}