import Arbo from '../blocs/arbo.vue'

export default {
	data() {
		return {}
	},
	props: ['params','menu_index'],
	watch: {
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
			if(before[this.menu_index]?.selectAll_index != after[this.menu_index]?.selectAll_index) this.switchAll()
		}
	},
	methods: {
		reset_filters(){
			this.$store.dispatch('resetDechets');
		},
	    switchAll(){
	      let etat = this.getDechetsCheckAll

	      if (etat == 'mid') {
	        this.$store.dispatch('resetDechets');
	      }else if (etat == 'on') {
	        this.$store.dispatch('disabledDechets');
	      }else{
	        this.$store.dispatch('resetDechets');
	      }
    	},
	},
	computed:{
	    getDechetsCheckAll(){
			let self = this;
			let ids_in = []

			let main_dechet_id_filtrers = self.$store.state.filters.filters //AS--> Pour la réactivité
			let main_dechet_id = self.$store.state.filters.filters.main_dechet_id

			let dechets_ids = [];
			let all_dechets_ids = [];
			for (let i in self.$store.state.ui.dechets) {
				if (!self.$store.state.ui.dechets[i].disabled && self.$store.state.ui.dechets[i].data['id'] == main_dechet_id) {
					dechets_ids = self.$store.state.ui.dechets[i].last_childs_ids
					all_dechets_ids = self.$store.state.ui.dechets[i].all_dechets_ids
					break
				}
			}

			ids_in = _.intersection(_.compact(self.$store.state.filters.filters.all_dechets_ids), all_dechets_ids)

			let stat = '';
			if (ids_in.length > 0) {
				stat = ids_in.length == all_dechets_ids.length ? 'on' : 'mid';
			}

			return stat;
   		},
	},
	components: {
		Arbo
	}
}