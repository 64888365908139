export default {
	data() {
    return {}
  },
  props: ['entreprise'],
  computed:{
    getNace(){
        return typeof this.$store.state.ui.code_naces[this.entreprise.nace_id] != 'undefined' ? this.$store.state.ui.code_naces[this.entreprise.nace_id]['code'] + ' - ' + this.$store.state.ui.code_naces[this.entreprise.nace_id]['titre'] : ''
    },
    getNaceTruncat(){
        return typeof this.$store.state.ui.code_naces[this.entreprise.nace_id] != 'undefined' ? _.truncate(this.$store.state.ui.code_naces[this.entreprise.nace_id]['titre'], {length: 40}) : ''
    }
  }
}