import CompanyDetail from '../blocs/company_detail.vue'

export default {
	data() {
    return {}
  },
  props: ['entreprise'],
  components: {
    CompanyDetail
  }
}