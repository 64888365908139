export default {
	methods: {
		close_menu(event){
			this.$store.commit('UI_SET_MENU', false);
		},
		open_results(filter_index, isFavoris){
			let show_filters = filter_index == this.$store.state.ui.show_filter_index ? !this.$store.state.ui.show_filters : true
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: show_filters});
			this.$store.commit('UI_SET_FILTER_INDEX', {filter_index: filter_index});
			if (this.$store.state.ui.content != 'result' && this.$store.state.ui.content != 'graph') {
				this.$store.commit('UI_SET_CONTENT', {content: 'result'});
			}

			// this.$store.commit('UI_SET_SHOW_FILTERS', {filters: true});
			// this.$store.commit('UI_SET_FILTER_INDEX', {filter_index: filter_index});
			// if(isFavoris){
			// 	this.$store.commit('UI_SET_CONTENT', {content: 'favoris'});
			// }else{
			// 	this.$store.commit('UI_SET_CONTENT', {content: 'result'});
			// }
		},
		open_favoris(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
			this.$store.commit('UI_SET_CONTENT', {content: 'favoris'});
		},
		open_search(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
			this.$store.commit('UI_SET_CONTENT', {content: 'search'});
		},
		open_account(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
			this.$store.commit('UI_SET_CONTENT', {content: 'account'});
		}
	},
	computed:{
		getTotalRecherche(){
			return this.$store.getters.getTotalRecherche
		},
		getTotalFavoris(){
			return this.$store.getters.getTotalFavoris
		},
		hasFiltersParams(){
			return this.$store.getters.hasFiltersParams;
		},
		hasFiltersActeurs(){
			return this.$store.getters.hasFiltersActeurs;
		},
		hasFiltersDechets(){
			return this.$store.getters.hasFiltersDechets;
		},
		hasFiltersCaracteristiques(){
			return this.$store.getters.hasFiltersCaracteristiques;
		},
		hasFiltersSecteurs(){
			return this.$store.getters.hasFiltersSecteurs;
		}
	}
}