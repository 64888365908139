export default {
  data() {
    return {

    }
  },
  props: ['entreprise'],
  methods:{
    numberFormat(number){
      if(number == '') return '';
      if (number >= 0 && number <= 1) {
        return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(number);
      }else{
        return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 0}).format(number);
      }
    },
  },
  computed:{
    getAffichageCapacite(){
      return this.numberFormat(this.entreprise.qty ? this.entreprise.qty : '');
    }
  }
}