// import AdvancedSelect from '@myena/advanced-select';
import vSelect from 'vue-select'
import axios from '../../common/axios.js'
import _ from 'lodash'
import Input from '../../common/forms/Input.vue'
import LoadingBtn from '../../common/ui/loading_btn.vue'

export default {
	data(){
		return {
			tel: '',
			error: false,
			n1:'',
			n2:'',
			n3:'',
			n4:'',
			success: false,
			loading: false
		}
	},
	created(){
	},
	mounted(){
		this.$el.querySelector('#n1').focus();
	},
	watch:{
		n1: function(after, before){
			if(after.length) this.next('n2')
		},
		n2: function(after, before){
			if(after.length) this.next('n3')
		},
		n3: function(after, before){
			if(after.length) this.next('n4')
		},
		n4: function(after, before){
			if(after.length) this.submitLast()
		}
	},
	methods:{
		submit(){
			console.log('submit');
			var self = this;
			var fields = ['n1', 'n2', 'n3', 'n4'];
			self.error = false;
			
			//AS--> Vérifie tous les champs
			for (var i = 1; i <= 4; i++){
				if(self['n'+i].length != 1) self.error = '<ul><li>Veuillez saisir les 4 chiffres, 1 par cellule</li></ul>';
			}

			//AS--> S'il n'y a pas d'erreur, on poursuit la validation par téléphone
			if(self.error === false){
				this.loading = true;
				axios.post('/plugin_projet/plugin_projet_iannuaire/phone_code', {
					data:{
						Profil: {
							code: ''+self.n1+self.n2+self.n3+self.n4
						}
					}
				})
				.then(function (response) {
					if(typeof response.data.erreurs != 'undefined'){
						self.error = '<ul>';
						_.forEach(response.data.erreurs, function(text, field){
							self.error += '<li>'+text+'</li>';
						})
						self.error += '</ul>';
					}else{
						self.success = true;
					}
				})
				.catch(function (error) {})
				.finally(function(){
					self.loading = false;
				});
			}
		},
		next(cible){
			console.log(cible);
			this.$el.querySelector('#'+cible).focus();
		},
		submitLast($event){
			this.submit();
		}
	},
	computed:{
		phone_number(){
			let phone = '***'
			if(this.$store.state.user.user.tel) phone = this.$store.state.user.user.tel.substring(0,this.$store.state.user.user.tel.length-4)+'****';
			return phone
		}
	},
	components: {
		vSelect,
		Input,
		LoadingBtn
	}
}