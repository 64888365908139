<template>
  <div :class="['switcher', 'switcher-' + _kebabCase(getField)]">
    
    <div v-for="(option, option_i) in getOptions" :class="{active: value === option.value}" @click="updateValue(option.value)">
      {{option.name}}&nbsp;<span v-if="getTooltip(option)" v-tooltip="{content: getTooltip(option), placement: 'bottom', offset: 0, delay: { show: 250, hide: 100 } }" class="switcher_tooltip">
        <icon-base viewBox="0 0 512 512" iconName=""><IconInfo /></icon-base>
      </span>
    </div>

  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
export default {
  name: "Switcher",
  data() {
    return {
      active: false
    };
  },
  props: {
    value: null,
    options: Object,
    datas: Object,
    errors: Object,
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    field: String,
    label: String,
    params: {
      type: Object,
      default: {}
    },
  },
  created() {
    this.init()
  },
  mounted() {},
  watch: {
    //AS--> Réinitialisation des filtres par l'extérieur. Si la value passe à undefined, on ferme le filtre
    value: function(after, before){
      if(typeof after == 'undefined'){
        this.init()
      }
    }
  },
  methods: {

    init(){
      this.updateValue(this.getOptions[0].value)
    },

    //AS--> Transforme le nom du champ en kababcase pour en faire une class
    _kebabCase(field) {
      return kebabCase(field);
    },

    //AS--> Mais à jour la value de référence en la passant au parent. La value interne sera mise à jour automatiquement
    updateValue(val){
      this.$emit('input', val)
    },

    //AS--> Renvoie le tooltip de l'option
    getTooltip(option){
      if(option.tooltip) return option.tooltip
      return false
    },

    
  },
  computed: {
    getErrors() {
      return get(this.errors, this.field);
    },

    //AS--> Renvoie le field en fonction des différentes options de paramétrage
    getField(){
      if(this.params.code) return this.params.code
      return this.field
    },

    //AS--> Renvoie le label en fonction des différentes options de paramétrage
    getLabel(){
      if(this.params.label) return this.params.label
      if(this.params.name) return this.params.name
      return this.label
    },

    //AS--> Renvoie la liste des options en fonction des différentes options de paramétrage
    getOptions(){
      let options = []
      if(this.params.options){
        options = this.params.options
      }else{
        options = this.options
      }
      return options
    },

  },
  components: {},
};
</script>