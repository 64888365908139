import vueCustomScrollbar from 'vue-custom-scrollbar'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Debounce from 'lodash/debounce'
import CustomSlider from './blocs/custom_slider.vue'
import FiltreHeader from './filtres/_filtre_header.vue';

export default {
	data() {
		return {
			settings: {
				// maxScrollbarLength: 60
			},
			carac_opened: [],
			sliders: []
		}
	},
	methods: {
		reset_filters() {
			// this.$store.dispatch('init_custom_filters');
			this.$store.dispatch('resetFilterCaracteristiques');
		},
		close_filter() {
			this.$store.commit('UI_SET_SHOW_FILTERS', { filters: false });
		},
		switch_dechets_qty() {
			this.$store.dispatch('switchFilterDechetsQty');
		},
		switch_dechet_qty() {
			this.$store.dispatch('switchFilterDechetQty');
		},
		switch_no_call() {
			this.$store.dispatch('switchFilterContactNoCall');
		},
		switch_call() {
			this.$store.dispatch('switchFilterContactCall');
		},
		set_custom_filters(filter_id, value) {
			let filter = { filter_id, value }
			this.$store.dispatch('setCaracteristiques', filter)
		},
		is_check(filter_id, value) {
			let caracteristiques = this.$store.state.filters.caracteristiques
			return caracteristiques[filter_id].includes(value)
		},
		getCaracCheck(carac_id) {
			return this.$store.state.filters.caracteristiques_ids.includes(carac_id)
		},
		minMaxEqual() {

		},
		formatData(value) {
			if (this.is_float) {
				return value / 100;
			} else {
				return value;
			}
		},
		switch_carac(carac_id) {
			if(this.carac_opened.includes(carac_id)){
				this.carac_opened = this.carac_opened.filter(a => a !== carac_id)
			}else{
				this.carac_opened.push(carac_id);
			}
		},
		allCarac(carac_id){
			this.$store.dispatch('setCaracteristiques', {filter_id: carac_id, check_all: true})
		},
		noCarac(carac_id){
			this.$store.dispatch('setCaracteristiques', {filter_id: carac_id, check_all: false})
		},
	},
	computed: {
		getNoCall() {
			return this.$store.state.filters.filters.contact.no_call
		},
		getCall() {
			return this.$store.state.filters.filters.contact.call
		},
		getCustomFilters() {
			let custom_filters = this.$store.state.ui.custom_filters;
			let dechets = this.$store.state.ui.dechets
			let main_dechet_id = this.$store.state.filters.filters.main_dechet_id
			let filtres_ids = false

			_.forEach(dechets, function(dechet) {
				if (dechet.data.id == main_dechet_id) {
					if (dechet.filtres && Object.values(dechet.filtres).length != 0) {
						filtres_ids = dechet.filtres_ids;
					}
				}
			})

			let _filtres = {}
			_.each(filtres_ids, function(filtre_id, k){
				if (typeof custom_filters[filtre_id] != 'undefined') {
					_filtres[k] = custom_filters[filtre_id]
				}
			})
			return _filtres
		},
		hasCustomFilters() {
			return _.keys(this.getCustomFilters).length
		},


		getPertinance: {
			// accesseur
			get: function() {
				return this.$store.state.filters.filters.pertinance * -1
			},
			// mutateur
			set: Debounce(function(newValue) {
				this.$store.dispatch('setPertinance', newValue * -1);
			}, 500)
		},

		

		//AS--> DECHETS -----------------------------------------

		filterDechetsMin(){
			if(this.$store.state.filters.dechets_qty_active && Array.isArray(this.$store.state.filters.filters.dechets_qty) && typeof this.$store.state.filters.filters.dechets_qty[0] == 'number') return this.$store.state.filters.filters.dechets_qty[0]
			return false
		},

		filterDechetsMax(){
			if(this.$store.state.filters.dechets_qty_active && Array.isArray(this.$store.state.filters.filters.dechets_qty) && typeof this.$store.state.filters.filters.dechets_qty[1] == 'number') return this.$store.state.filters.filters.dechets_qty[1]
			return false
		},

		getSliderDechetsMin() {
			if(Array.isArray(this.$store.state.filters.dechets_qty_slider) && typeof this.$store.state.filters.dechets_qty_slider[0] == 'number') return Math.floor(this.$store.state.filters.dechets_qty_slider[0])
			return 0
		},

		getSliderDechetsMax() {
			if(Array.isArray(this.$store.state.filters.dechets_qty_slider) && typeof this.$store.state.filters.dechets_qty_slider[1] == 'number') return Math.floor(this.$store.state.filters.dechets_qty_slider[1])
			return 0
		},

		//AS--> Pour le slider
		filtreDechets: {
			get: function(){
				return [this.filterDechetsMin,this.filterDechetsMax]
			},
			set: function(value){
				this.$store.dispatch('setDechetsQty', value)
			}
		},

		//AS--> Pour le champ min
		filtreDechetsMin: {
			get: function(){
				return this.$store.state.filters.dechets_qty_active && this.filterDechetsMin !== false ? this.$store.state.filters.filters.dechets_qty[0] : this.getSliderDechetsMin
			},
			set: function(value){
				this.$store.dispatch('setDechetsQty', [value, this.filterDechetsMax ?? this.getSliderDechetsMax])
			}
		},

		//AS--> Pour le champ max
		filtreDechetsMax: {
			get: function(){
				return this.$store.state.filters.dechets_qty_active && this.filterDechetsMax !== false ? this.$store.state.filters.filters.dechets_qty[1] : this.getSliderDechetsMax
			},
			set: function(value){
				this.$store.dispatch('setDechetsQty', [this.filterDechetsMin ?? this.getSliderDechetsMin, value])
			}
		},





		//AS--> DECHET -----------------------------------------

		filterDechetMin(){
			if(this.$store.state.filters.dechet_qty_active && Array.isArray(this.$store.state.filters.filters.dechet_qty) && typeof this.$store.state.filters.filters.dechet_qty[0] == 'number') return this.$store.state.filters.filters.dechet_qty[0]
			return false
		},

		filterDechetMax(){
			if(this.$store.state.filters.dechet_qty_active && Array.isArray(this.$store.state.filters.filters.dechet_qty) && typeof this.$store.state.filters.filters.dechet_qty[1] == 'number') return this.$store.state.filters.filters.dechet_qty[1]
			return false
		},

		getSliderDechetMin() {
			if(Array.isArray(this.$store.state.filters.dechet_qty_slider) && typeof this.$store.state.filters.dechet_qty_slider[0] == 'number') return Math.floor(this.$store.state.filters.dechet_qty_slider[0])
			return 0
		},

		getSliderDechetMax() {
			if(Array.isArray(this.$store.state.filters.dechet_qty_slider) && typeof this.$store.state.filters.dechet_qty_slider[1] == 'number') return Math.floor(this.$store.state.filters.dechet_qty_slider[1])
			return 0
		},

		//AS--> Pour le slider
		filtreDechet: {
			get: function(){
				return [this.filterDechetMin,this.filterDechetMax]
			},
			set: function(value){
				this.$store.dispatch('setDechetQty', value)
			}
		},

		//AS--> Pour le champ min
		filtreDechetMin: {
			get: function(){
				return this.$store.state.filters.dechet_qty_active && this.filterDechetMin !== false ? this.$store.state.filters.filters.dechet_qty[0] : this.getSliderDechetMin
			},
			set: function(value){
				this.$store.dispatch('setDechetQty', [value, this.filterDechetMax ?? this.getSliderDechetMax])
			}
		},

		//AS--> Pour le champ max
		filtreDechetMax: {
			get: function(){
				return this.$store.state.filters.dechet_qty_active && this.filterDechetMax !== false ? this.$store.state.filters.filters.dechet_qty[1] : this.getSliderDechetMax
			},
			set: function(value){
				this.$store.dispatch('setDechetQty', [this.filterDechetMin ?? this.getSliderDechetMin, value])
			}
		},
	},
	watch: {},
	components: {
		vueCustomScrollbar,
		VueSlider,
		CustomSlider,
		FiltreHeader
	}
}