import Modale from '../../common/ui/modale.vue'
import PendingFlow from '../../common/ui/pending_flow.vue'

export default {
	data(){
		return {
			force_show_success: false,
			force_show_error: true
		}
	},
	created(){
		let self = this

		if (typeof self.params.force_show_success != 'undefined'){
			self.force_show_success = self.params.force_show_success
		}

		if(typeof self.params.force_show_error != 'undefined'){
			self.force_show_error = self.params.force_show_error
		}

		self.params.promise.then(() => {
			if(self.force_show_success){
				self.$store.dispatch('open_modal', { code: 'pending_api', params: self.params })
			}
		}).catch(() => {
			if (self.force_show_error){
				self.$store.dispatch('open_modal', { code: 'pending_api', params: self.params })
			}
		})
	},
	props: ['params'],
	methods:{
		getTitle(state){
			return this.params[state + '_title'] != undefined ? this.$t(this.params[state + '_title']) : this.$t('modal_export_'+state+'_titre') 
		},
		getContent(state){
			return this.params[state + '_message'] != undefined ? this.$t(this.params[state + '_message']) : this.$t('modal_export_'+state+'_message') 
		},
		getContentHtml(state){
			return this.params[state + '_html'] != undefined ? this.params[state + '_html'] : false 
		}
	},
	computed:{},
	components:{
		Modale,
		PendingFlow
	}
}