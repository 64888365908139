<template>
	<div>
		<icon-base v-if="typeof ip == 'number'" width="48" height="48" :class="['custom_graph', 'ip'+ip]" v-tooltip="{content: $t('ip_label_' + ip), classes:'auto_xl', placement: 'bottom', offset: 0, delay: { show: 250, hide: 100 } }"><icon-graph5 /></icon-base>
		<template v-else>
			-
		</template>
	</div>
</template>

<script>
export default {
	props: ['ip'],
}
</script>