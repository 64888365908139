import axios from '../../common/axios.js'
import carousel from 'vue-owl-carousel'

export default {
	props: ['contacts', 'company_id'],
	methods:{
		set_phoned(contact_id){
			let self = this
			let company_id = self.company_id

			let data = new FormData();
			data.append('contact_id', contact_id);
			data.append('company_id', company_id);

			this.contacts[contact_id].phoned = true
			self.$store.dispatch('update_companies_calls', company_id)
			self.$store.dispatch('set_contact_id_phoned', {contact_id, company_id})

			axios.post('/plugin_projet/plugin_projet_iannuaire/update_contact_tel', data).then()
		}
	},
	components:{
		carousel
	}
}