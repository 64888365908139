import vueCustomScrollbar from 'vue-custom-scrollbar'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Arbo from './blocs/arbo.vue'
import axios from '../common/axios.js'
import _ from 'lodash'
import Utils from '../common/utils.js';
import FiltreHeader from './filtres/_filtre_header.vue';

export default {
	mixins: [Utils],
	data() {
		return {
			capacite_opened: [],
			familles_0_show: [],
			legendOpened: [],
			formatter1: '{value}%',
			sliders:{}
		}
	},
	created(){
	},
	mounted(){
		let self = this
		this.$store.state.ui.sourcing.familles.forEach(function(famille){
			if(self.hasFamilleId(famille)) self.familles_0_show.push(famille.parent_id)
		})
	},
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
		},
		reset_filters(){
			this.$store.dispatch('resetActors');
			this.$store.dispatch('resetFamilyLayers');
		},
		getCapacites(famille){
			let capacites_sourcing = this.$store.state.ui.sourcing.capacites;	//AS--> Capacités définies par le sourcing
			let capacites_data     = this.$store.state.ui.capacites 			//AS--> Filtres de capacité redéfini par les retours de index.php
			let _capacites         = []
			_.each(capacites_sourcing, function(capacite_sourcing){
				if(
					capacite_sourcing.famille_id == famille.id 
					&& capacite_sourcing.filtre 
					&& (
						typeof capacites_data[capacite_sourcing.id] != 'undefined' 	//AS--> Existe dans les filtres de capacités ajustés dans index.php
						&& capacite_sourcing.fonctionnement != 'numeric'			//AS--> Capacité de type booléen ou string
						&& (typeof capacites_data[capacite_sourcing.id] != 'object' || capacites_data[capacite_sourcing.id].length) //AS--> Si c'est un Array de valeurs, il faut que l'array contienne des données
					)){
					_capacites.push(capacite_sourcing)
				}
			})
			_capacites = _.sortBy(_capacites, ['ordre']);

			return _capacites;
		},
		hasFamilleId(famille){
			return this.$store.state.filters.filters.familles_ids.includes(famille.id)
		},
		hasCapaciteId(capacite){
			return typeof this.$store.state.filters.filters.capacites[capacite.id] != 'undefined' && this.$store.state.filters.filters.capacites[capacite.id] === true 
		},
		hasNotCapaciteId(capacite){
			return typeof this.$store.state.filters.filters.capacites[capacite.id] != 'undefined' && this.$store.state.filters.filters.capacites[capacite.id] === false 
		},
		getCapacitesCheck(capacite_id){
			return this.$store.state.filters.capacites_ids.includes(capacite_id);
		},
		switch_capacite(capacite_id){
			if(this.capacite_opened.includes(capacite_id)){
				this.capacite_opened = this.capacite_opened.filter(a => a !== capacite_id)
			}else{
				this.capacite_opened.push(capacite_id);
			}
	    },
		getCapacitesData(capacite){
			let data = {}

			if (capacite.fonctionnement == 'boolean') {
				if(this.$store.state.ui.capacites[capacite.id].includes(true)) data['true'] = this.$t('oui')
				if(this.$store.state.ui.capacites[capacite.id].includes(false)) data['false'] = this.$t('non')
			}else{
				let capacites_data = this.$store.state.ui.capacites
				capacites_data[capacite.id].forEach(function(v){
					data[v] = v
				})
			}

			return data;
	    },
	    is_check(capacite_id, value){
	    	let capacites = this.$store.state.filters.filters.capacites

	    	if(!isNaN(value)) value = parseFloat(value);

			return capacites[capacite_id] != undefined ? capacites[capacite_id].includes(value) : false
	    },
		getLegendeStyle(style){
			let _style = {};
			if(undefined != style.fillColor) _style['background-color'] = style.fillColor;
			if(undefined != style.strokeColor) _style['border-color'] = style.strokeColor;
			return !_style['background-color'] && !_style['border-color'] ? false : _style
		},
		getLegendeIcon(style){
			let icon = false
			if(undefined != style.icon){
				icon = style.icon.substring(0, 4) != 'http' ? '/sourcing_icons/'+style.icon : style.icon
			}
			return icon
		},
		getLayerCouleurs(layer){
			if(layer.type == 'multi'){

				//AS--> Détermine le calque à afficher
				let zone_size = this.$store.state.ui.map_zone_google.areaActif;
				if(typeof zone_size != 'undefined'){
				  let layer_selected = null;
				  layer.layers.forEach(function(layer){
				    if(layer.max == '') layer.max = 99999999999999999999999999999999999;
				    if(layer.max > zone_size && (layer_selected == null || layer.max < layer_selected.max)) layer_selected = layer;
				  })

				  if(layer_selected && layer_selected.couleurs){
				  	//AS--> S'il n'y a qu'une définition de couleur et qu'elle n'a pas de légende
				  	if(layer_selected.couleurs.length == 1 && !layer_selected.couleurs[0].legende) return null;
				  	return layer_selected.couleurs;
				  }else{
				  	return null;
				  }
				}

				return null
			}else{
				return layer.couleurs;
			}
		},
		getFamilleLayers(famille_id){
			let layers = [];
			this.$store.state.ui.layers.forEach(function(layer, i){
				if(layer.famille == famille_id) layers.push(layer);
			})
			return layers;
		},
		set_capacite(capacite_id, value){
	    	if(!isNaN(value)) value = parseFloat(value);
			// let capacites = this.$store.state.filters.capacites
			// return capacites[capacite_id].includes(value)
			let filter = { capacite_id, value }
			this.$store.dispatch('setCapacites', filter)
	    },
		allCapacite(capacite_id){
			this.$store.dispatch('setCapacites', {capacite_id: capacite_id, check_all: true})
		},
		noCapacite(capacite_id){
			this.$store.dispatch('setCapacites', {capacite_id: capacite_id, check_all: false})
		},
		switchShowFamille(famille){
			if(this.familles_0_show.includes(famille.id)){
				this.familles_0_show = this.familles_0_show.filter(f_id => f_id !== famille.id)
			}else{
				this.familles_0_show.push(famille.id);
			}
		},
		switchAll(){
			if(this.getCheckAllState == 'on'){
				this.$store.dispatch('setFilters', { familles_ids: [] });
			}else{
				let familles_ids = this.$store.state.ui.sourcing.familles.filter(f => f.parent_id != 0).map(f => f.id);
				this.$store.dispatch('setFilters', { familles_ids });
			}
		},
		getSubLayerId(layer){
			if(layer.type == 'multi'){

				//AS--> Détermine le calque à afficher
				let zone_size = this.$store.state.ui.map_zone_google.areaActif;
				if(typeof zone_size != 'undefined'){
				  let layer_selected = null;
				  layer.layers.forEach(function(layer){
				    if(layer.max == '') layer.max = 99999999999999999999999999999999999;
				    if(layer.max > zone_size && (layer_selected == null || layer.max < layer_selected.max)) layer_selected = layer;
				  })
				  return layer_selected._id
				}

				return null
			}else{
				return layer.layer;
			}
		},
		switchLegendOpened(i){
			if(this.legendOpened.includes(i)){
				this.legendOpened = _.without(this.legendOpened, i);
			}else{
				this.legendOpened.push(i)
			}
		},
		isLegendOpened(i){
			return this.legendOpened.includes(i)
		},
		toggleLayer(layer){
			delete this.sliders[layer]
			this.sliderChange(layer)
			this.$store.dispatch('toggle_layer', layer)
		},
		sliderChange(layer){
			let opacity = typeof this.sliders[layer] == 'undefined' ? null : this.sliders[layer]
			this.$store.dispatch('change_layer_opacity', {'layer_id':layer, 'opacity': opacity})
		},
		layerHasLineOrPolygon(_layer){
			let hasLineOrPolygon = false
			if(this.$store.state.ui.layers_data[_layer] && this.$store.state.ui.layers_data[_layer].features){
				this.$store.state.ui.layers_data[_layer].features.forEach(function(feature){
					if(feature.geometry && feature.geometry.type && ["MultiPolygon", "Polygon", "Line"].includes(feature.geometry.type)) hasLineOrPolygon = true
				})

			}else{
				hasLineOrPolygon = true

			}
			return hasLineOrPolygon;
		}
	},
	computed:{
		filterIndex(){
			return this.$store.state.ui.show_filter_index;
		},
		myStyles(){
			return {
				transform: 'translateY('+this.height+')'
			}
		},
		getCheckAllState(){
			let nbr_checked = this.$store.state.filters.filters.familles_ids ? this.$store.state.filters.filters.familles_ids.length : 0;
			if(nbr_checked == 0) return '';
			let nbr_items = this.$store.state.ui.sourcing.familles ? this.$store.state.ui.sourcing.familles.filter(f => f.parent_id != 0).length : 0;
			return nbr_checked >= nbr_items ? 'on' : 'mid';
		}
	},
	components: {
	    vueCustomScrollbar,
	    VueSlider,
	    Arbo,
	    FiltreHeader
	}
}