import _ from 'lodash'

export default {
	data() {
    return {
    	
    }
  },
  props: ['entreprise'],
  methods:{
    
  }
}