import Throttle from 'lodash/throttle'
import Debounce from 'lodash/debounce'
import carousel from 'vue-owl-carousel'
import _ from 'lodash'


export default {
	data() {
		return {
			open_search_bar: false,
			carousel_index: 0
		}
	},
	methods: {
		switch_map() {
			this.$store.dispatch('toggle_mini_map');
		},
		open_modal_save() {
			this.$store.commit('UI_SET_MODAL', { modal: 'save_search' });
		},
		open_graph() {
			this.$store.commit('UI_SET_CONTENT', { content: 'graph' });
		},
		open_map_filtres() {
			this.$store.commit('UI_SET_CONTENT', { content: 'map' });
		},
		open_map() {
			this.$store.commit('UI_SWITCH_MAP', true);
			this.$store.commit('UI_SET_CONTENT', { content: 'result' });
		},
		open_list() {
			this.$store.commit('UI_SWITCH_MAP', false);
			this.$store.commit('UI_SET_CONTENT', { content: 'result' });
		},
		open_filters() {
			this.$store.commit('UI_SET_SHOW_FILTERS', { filters: true });
			if (this.$store.state.ui.content == 'result') {
				this.$store.commit('UI_SET_FILTER_INDEX', { filter_index: 2 });
			} else {
				this.$store.commit('UI_SET_FILTER_INDEX', { filter_index: 4 });
			}
		},
		open_q() {
			this.open_search_bar = true
			document.getElementById("search_bar_q").focus();
		},
		resetQ() {
			// this.open_search_bar = false;
			this.$store.dispatch('setQ', '');
		},
		incrementCarousel(sens){
			let new_index = this.carousel_index + sens
			let max_index = _.values(this.$store.state.entreprises.stats_familles).length

			if (new_index < 0) {
				new_index = max_index
			}else if(new_index > max_index){
				new_index = 0
			}

			this.carousel_index = new_index
		},
		searchBlur(){
			let self = this;
			setTimeout(function(){
				self.open_search_bar = false
			}, 200);
		},
		getPictoFamille(famille_id){
			let picto = '';
			this.$store.state.ui.sourcing.familles.forEach(function(famille){
				if(famille.id == famille_id) picto = famille.svg_dark
			})
			return picto;
		},
		getNomFamille(famille_id){
			let titre = '';
			this.$store.state.ui.sourcing.familles.forEach(function(famille){
				if(famille.id == famille_id) titre = famille.titre
			})
			return titre;
		},
		getTotalFormat(total) {
			return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(Math.round(total, 1))
		},
		getPvrMeta(pvr) {
			return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(Math.round(pvr, 0))
		},
		getCapaciteStatSomme(id, val){
			let value = '';
			_.forEach(this.$store.state.ui.sourcing.capacites, function(cpct){
				if(cpct.id == id){
					value = val / cpct.stat_somme_facteur;
					let digits = 0
					if(value < 1000) digits = 1
					if(value < 10) digits = 2
					value = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: digits }).format(value) + ' ' + cpct.stat_somme_unite;
				}
			})
			return value;
		},
		getCapaciteStatMoyenne(id, val){
			let value = '';
			_.forEach(this.$store.state.ui.sourcing.capacites, function(cpct){
				if(cpct.id == id){
					let digits = 0
					if(val < 1000) digits = 1
					if(val < 10) digits = 2
					value = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: digits }).format(val) + ' ' + cpct.unite;
				}
			})
			return value;
		},
		getCapaciteSommeLabel(id){
			let label = '';
			_.forEach(this.$store.state.ui.sourcing.capacites, function(cpct){
				if(cpct.id == id){
					label = cpct.stat_somme_label;
				}
			})
			return label;
		},
		getCapaciteMoyenneLabel(id){
			let label = '';
			_.forEach(this.$store.state.ui.sourcing.capacites, function(cpct){
				if(cpct.id == id){
					label = cpct.stat_moyenne_label;
				}
			})
			return label;
		}
	},
	computed: {
		getNbrCompanies() {
			return this.$store.state.entreprises.total
		},
		getTotal() {
			return this.$store.state.entreprises.qty_dechets
		},
		getMoyenne() {
			let value = this.$store.state.entreprises.total_liste > 0 ? Math.round(this.getTotal / this.$store.state.entreprises.total_liste, 1) : 0
			return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(value)
		},
		getStats(){
			let self = this
			let stats = _.values(this.$store.state.entreprises.capacites_stats);
			
			stats.forEach(function(stat){
				self.$store.state.ui.sourcing.familles.forEach(function(famille){
					if(famille.id == stat.f_id){
						stat.picto = famille.svg_dark
						stat.f_nom = famille.titre
					}
				})
				stat.famille_nbr = self.$store.state.entreprises.familles_nbr[stat.f_id]
				stat.unite = self.$store.state.ui.sourcing.capacites[stat.c_id].stat_somme_unite
				stat.label = self.$store.state.ui.sourcing.capacites[stat.c_id].stat_somme_label
				//AS--> Calcul la stat et l'arrondi
				let value = stat.stat / self.$store.state.ui.sourcing.capacites[stat.c_id].stat_facteur;
				let digits = 0
				if(value < 1000) digits = 1
				if(value < 10) digits = 2
				stat.stat = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: digits }).format(value);
			})
			return stats;
		},
		getQ: {
			// accesseur
			get: function() {
				return this.$store.state.filters.filters.q
			},
			// mutateur
			set: Debounce(function(newValue) {
				this.$store.dispatch('setQ', newValue);
			}, 500)
		},
		getStyleCarousel(){
			let style = {}

			let y = 70 * this.carousel_index

			style.transform = 'translateY(-'+y+'px)'
			return style;
		}
	},
	components:{
		carousel
	}
}