// import AdvancedSelect from '@myena/advanced-select';
import vSelect from 'vue-select'
import axios from '../../common/axios.js'
import _ from 'lodash'
import Input from '../../common/forms/Input.vue'
import Phone from '../../common/forms/Phone.vue'
import LoadingBtn from '../../common/ui/loading_btn.vue'

export default {
	data(){
		return {
			nom: '',
			prenom: '',
			email: '',
			tel: '',
			passwordType: 'password',
			passwordBType: 'password',
			password: '',
			password_b: '',
			entreprise: '',
			fonction: '',
			accepte: false,
			validation: false,
			error: false,
			tel_validation: {},
			errors: {},
			loading: false
		}
	},
	methods:{
		switchInput(){
			this.passwordType = this.passwordType == 'password' ? 'text' : 'password'
		},
		switchInputB(){
			this.passwordBType = this.passwordBType == 'password' ? 'text' : 'password'
		},
		submit(){
			console.log('test ????');
			var self = this;
			// var fields = ['nom', 'prenom', 'email', 'password', 'password_b', 'entreprise', 'fonction', 'accepte'];
			var fields = ['nom', 'prenom', 'email', 'password', 'password_b', 'entreprise', 'fonction', 'tel'];
			self.errors = {};
			self.error = false;

			self.validation = true
			
			//AS--> Vérifie tous les champs
			fields.forEach(function(field){
				if(_.isString(self[field])){
					if(field == 'password' && !self.validatePwdStrong(self[field])){
						self.errors[field] = "--erreur--";
					}
					if(field == 'password_b' && self.password != self[field]){
						self.errors[field] = self.$t('signin_error_password_confirmation');
					}
					if(field == 'email' && !self.validateEmail(self[field])){
						self.errors[field] = self.$t('signin_error_email');
					}
					if(self[field].length == 0){
						self.errors[field] = self.$t('signin_error_empty');
					}
				}
				if(_.isBoolean(self[field]) && self[field] === false){
					self.errors[field] = self.$t('signin_error_cgu');
				}
				if(field == 'tel' && !self.validateTel(self[field])){
					self.errors[field] = self.$t('signin_error_tel');
				}
			})


			//AS--> S'il n'y a pas d'erreur, on poursuit l'inscription
			if(_.isEmpty(self.errors)){
				this.loading = true;
				axios.post('/plugin_projet/plugin_projet_iannuaire/signin', {
					data:{
						Profil: {
							nom: self.nom,
							prenom: self.prenom,
							email: self.email,
							password: self.password,
							entreprise: self.entreprise,
							fonction: self.fonction,
							tel1: self.tel,
						}
					}
				})
					.then(function (response) {
						if (typeof response.data.erreurs != 'undefined') {
							self.error = '';
							let tag = response.data.erreurs.length > 1 ? 'li' : 'p';
							_.forEach(response.data.erreurs, function (text, field) {
								self.error += '<' + tag + '>' + self.$t(text) + '</' + tag + '>';
							})

							if (tag == 'li') {
								self.error = '<ul>' + self.error + '</ul>';
							}
						} else {

						//AS--> Mémorise le numéro de téléphone dans l'app
						var user = _.cloneDeep(self.$store.state.user.user);
						user.tel = self.tel;
						self.$store.commit('USER_SET_USER', user);

						self.$store.commit('UI_SET_MODAL', {modal: 'phone_code'});
						self.$store.commit('UI_SET_COMPANY', null)
						self.$store.commit('UI_SET_COMPANY_PREV', null);
						self.$store.commit('UI_SET_COMPANY_NEXT', null);
						self.$store.commit('UI_SET_COMPANY_ID', null)
						self.$store.dispatch('init_user_data', response.data);
					}
				}).catch(function (error) {})
				.finally(function () {
					self.loading = false;
				});
			}
		},
		validateEmail(email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		validatePwdLen(password) {
			return password.length >= 8;
		},
		validatePwdStrong(password) {
			return this.password_validator.all
		},
		validateTel() {
			return this.tel_validation.valid === true
		},
		phoneValidation(validation){
			this[validation.field+'_validation'] = validation
		}
	},
	computed:{
		password_validator(){
			let validations = {
				nbr_cars: false,
				majuscule: false,
				chiffre: false,
				special_car: false,
				all: false
			}

			if(this.password.length >= 8) validations.nbr_cars = true

			let re = /[A-Z]+/
			if(re.test(this.password)) validations.majuscule = true

			re = /\d+/
			if(re.test(this.password)) validations.chiffre = true

			re = /[^a-z\d\s]+/i
			if(re.test(this.password)) validations.special_car = true

			validations.all = validations.nbr_cars && validations.majuscule && validations.chiffre && validations.special_car 

			return validations
		}
	},
	components: {
		vSelect,
		Input,
		Phone,
		LoadingBtn
	}
}
