import _ from 'lodash'

export default {
	name: "Arbo",
	data() {
    return {
    	selectable: true,
      disabled: false,
      item: {},
    	childs: {},
    	showCloseAll: this.initialShowCloseAll,
    	childs_open: false
    }
  },
  props: ['initialItem', 'initialShowCloseAll', 'modelName', 'arboTitle', 'forceClose'],
  created(){
    let self = this
  	self.item = self.initialItem.data;
    self.selectable = typeof self.initialItem.selectable == 'undefined' ? true : self.initialItem.selectable;
    self.disabled = typeof self.initialItem.disabled == 'undefined' ? false : self.initialItem.disabled;
    self.childs_open = typeof self.initialItem.childs_open == 'undefined' ? false : self.initialItem.childs_open;
  	
    self.childs = typeof self.initialItem.childs != 'undefined' ? _.sortBy(self.initialItem.childs, [function(d) { return d.data.ordre; }]) : false;
    // self.childs = self.initialItem.childs;



    if(self.initialItem.level == 0){
      self.childs_open = self.$store.state.filters.filters.main_dechet_id == self.item.id

      self.$store.watch(function(){
        return self.$store.state.filters.filters.main_dechet_id
      }, function(){
        let main_dechet_id = self.$store.state.filters.filters.main_dechet_id
        self.childs_open = main_dechet_id == self.item.id
        self.close_all()
      })
    }
  },
  methods:{
  	toggle_childs(){
      let self = this;
      if (self.canOpen()) {
        if (self.modelName == 'ProjetDechetsInterface' && self.initialItem.level == 0 && self.disabled == false && self.item.id != self.$store.state.filters.filters.main_dechet_id) {
          self.$store.dispatch('setMainDechetId', self.item.id)
        }
  		  self.childs_open = !self.childs_open;
      }
  	},
    checkItem(){
      let self = this;
      if(self.modelName == 'ProjetNace'){
        self.$store.dispatch('setSecteursIds', self.initialItem._last_childs_ids);
      }else{
        let d = {
          ids: self.initialItem.last_childs_ids
        }
        // self.$store.dispatch('updateDechetsIds', d);
        self.$store.dispatch('setDechetsIds', d);
      }
    },
    canOpen(){
      let self = this;
      if (self.disabled) {
        self.$store.dispatch('open_modal', 'alert_categorie_dechet');
      }

      return !self.disabled// && this.childs
    },
    close_all(){
      for (let key in this.$children) {
        this.$children[key].close_all();
      }
      if(this.initialItem.level != 0){
        this.childs_open = false
      }
    }
  },
  watch:{
    forceClose(newQuestion, oldQuestion){

    }
  },
  computed:{
    getTitle(){
      if (this.modelName == 'ProjetNace') {
        let title = ''
        if (typeof this.item['title'] == 'undefined') {
          title = this.item['code_str'].substring(1)+' - '+this.item['titre']
        }else{
          title = this.item['title']
        }
        return title
      } else {
        return this.item['titre']
      }
    },
    getDefinition(){
      return this.item.definition ? this.item.definition : null;
    },
  	checkboxStat(){
  		let self = this;
      let ids_in = []
      let last_childs_ids = self.modelName == 'ProjetNace' ? self.initialItem._last_childs_ids : self.initialItem.last_childs_ids

      if(typeof last_childs_ids == 'undefined') last_childs_ids = []
      if(self.modelName == 'ProjetNace'){
        ids_in = _.intersection(self.$store.state.filters.filters.secteurs_ids, last_childs_ids)
      }else{
        ids_in = _.intersection(self.$store.state.filters.filters.dechets_ids, last_childs_ids)
      }
  		
      let stat = '';
      if (ids_in.length > 0) {
        stat = ids_in.length == last_childs_ids.length ? 'on' : 'mid';
      }

  		return stat;
  	}
  },
}