import _ from 'lodash'
import Debounce from 'lodash/debounce'
import {gmapApi} from 'vue2-google-maps'
// import VueGoogleMapsCluster from 'vue2-google-maps/dist/components/cluster'
import CompanyDetail from './blocs/company_detail.vue'
import Supercluster from 'supercluster';
import axios from '../common/axios.js'
import GeojsonLayer from './maps/GeojsonLayer.js'
import GeojsonLayerEntreprise from './maps/GeojsonLayerEntreprise.js'
import GeojsonLayerStandard from './maps/GeojsonLayerStandard.js'
import mapstyle from '../common/mapstyle.js'
import VueSlider from 'vue-slider-component'
import vSelect from 'vue-select'
import 'vue-slider-component/theme/default.css'
import GeojsonArea  from '@mapbox/geojson-area'
import GeojsonExtent from '@mapbox/geojson-extent';
import * as turf from '@turf/turf'
import MapMixins from "./maps/map_mixins";
import SaveRecherche from './modals/save_search.vue'
import moment from 'moment'
import Loading from '../common/ui/loading_inex.vue'

export default {
	mixins: [MapMixins],
	data() {
		return {
			loaded: false,
			map_zoom_center_init: false, 
			map_zoom_center_saved: false, 
			center: {lat: 0, lng: 0}, 
			zoom: 10,
			entreprises: [],
			infoContent: '',
			infoWindowPos: {
				lat: 0,
				lng: 0
			},
			infoWinOpen: false,
			infoWindowLoading: false,
			currentMidx: null,
			infoOptions: {
				pixelOffset: {
					width: -2,
					height: -50
				}
			},
			infoOptionsDistance: {
				hideCloseButton: true,
				pixelOffset: {
					width: 0,
					height: -3
				}
			},
			mapOptions: {
				disableDefaultUi: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				styles: mapstyle,
				mapTypeControl: true,
				mapTypeControlOptions: {
					position: 6
				},
				zoomControlOptions: {
					position: 9
				},
				streetViewControlOptions: {
					position: 9
				},
				maxZoom: 50,
				clickableIcons: false
			},
			path_options: {
				strokeColor: '#00CEA8',
				fillColor: '#00CEA8',
				fillOpacity: 0.1,
				strokeWeight: 3,
				strokeOpacity: 1,
				clickable: false,
				zIndex: 100
			},
			path_options_distance: {
				clickable:false,
				draggable:false,
				strokeColor: '#ff8100',
				strokeOpacity: 0,
				icons: [],
				zIndex: 250
			},
			path_options_surface: {
				clickable:false,
				draggable:false,
				fillColor: '#ff8100',
				fillOpacity: 0.1,
				strokeColor: '#ff8100',
				strokeOpacity: 0,
				icons: [],
				zIndex: 250
			},
			path_options_distance_icons: [{
				icon: {
					path: "M 0,-1 0,1",
					strokeOpacity: 1,
					scale: 4
				},
				offset: "0",
				repeat: "20px"
			}],
			distance_shape: {coords: [8,8,8], type: 'circle'},
			distance_shape_label: {coords: [8,8,8], type: 'circle'},
			surface_shape_button: {coords: [0,0,34,34], type: 'rect'},
			map_bounds: null,
			current_zoom: null,
			shape_range: null,
			shape_zone: null,
			super_cluster: false,
			markers_version: 0,
			markers: [],
			clusters: [],
			selected_marker: false,
			heatmap: {},
			heatmapPvrmeta: {},
			layer_clusters: true,
			layer_heatmap: false,
			layer_heatmapPvrmeta: false,
			paths: [],
			paths_tmp: null, //AS--> Permet de sauvegarder le path quand on n'est pas en mode 'trace' et le remettre en place quand on revient en mode 'trace'
			map_object: null,
			filtre_city: '',
			cities: [],
			bounds: null,
			position: false,
			range: 200,
			familles_markers: {},
			clusters_markers: [],
			group_markers: [],
			
			tools_distance: false,
			tools_distance_result: 0,
			distance_path: [],

			tools_surface: false,
			tools_surface_result: 0,
			surface_path: [],

			map_cursor: [],

			minPoints: 1,

			map_filter_mode: this.$store.state.ui.content == 'map' && this.$store.state.filters.filters.map_filter_mode == 'aucun' ? 'rayon' : this.$store.state.filters.filters.map_filter_mode,

			zones_layer: null,
			zones_filter: this.$store.state.filters.filters.zones_filter, 	// {layer: '$XXXXXXXXXXXXXXXXXXX', zones: ['XXXXXXXXX', 'XXXXXXXXX', 'XXXXXXXXX']}
			modal_surface: false,
			surface_name: '',
			surface_id: null,
			surface_erreur: '',
			zoom_surface: false,
			shape_filter_to_show: null,
			map: null,
			recherche_show_saving:false,
			recherche_show_modified:false,
			lastPositionRange: '',
			infoWindowCustomOpen: false
		}
	},
	created(){

		let self = this

		//AS--> Création initiale de SuperCluster
		this.super_cluster = new Supercluster({
			radius: 80,
			maxZoom: 12,
			log: false
		});

		//AS--> Récupération des informations de filtre rayon
		this.position = this.$store.state.filters.filters.position

		if (this.$store.state.filters.filters.position_name) {
			this.filtre_city = {nom: this.$store.state.filters.filters.position_name}
		}

		if(this.$store.state.filters.filters.range){
			this.range = this.$store.state.filters.filters.range
		}

		//AS--> Récupération des données de centre et de zoom si elles ont été mémorisées
		let map_state = this.$store.state.ui.map_state
		if(map_state.zoom){
			this.zoom                  = map_state.zoom;
			this.center                = map_state.center;
			this.map_zoom_center_init  = true;
			this.map_zoom_center_saved = true;
		}

		this.getPathsTransformed();

		//AS--> Réaffiche une surface si demandée
		if(self.$store.state.ui.surface_id && _.isNumber(self.$store.state.ui.surface_id)){
			let self = this
			let surface_id = self.$store.state.ui.surface_id

			self.$store.commit('UI_SET_MAP_TOOLS_SURFACE', true)
			self.mesureSurface(true);
			self.surface_id = surface_id

			_.forEach(self.$store.state.user.surfaces, function(surface){
				if (surface.id == surface_id) {
					self.surface_name         = surface.nom;
					self.surface_path         = JSON.parse(surface.surface)
					self.tools_surface_result = surface.area

					self.$store.commit('UI_SET_SURFACE_ID', null);
				}
			})

		}

	},
	mounted(){

		let self = this;

		//RZ--> La carte est créé
		self.$refs.map.$mapPromise.then((map_object) => {
			self.map = self.$refs.map;
			self.map_object = map_object;
			if (self.$store.state.ui.sourcing.limit_zoom) {
				self.mapOptions.maxZoom = self.$store.state.ui.sourcing.limit_zoom
			}

			self.loaded = true;

			self.shape_range = new google.maps.Polygon({
				strokeColor: '#00CEA8',
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: '#000000',
				fillOpacity: 0.17,
				map: null
			});

			self.shape_zone = new google.maps.Polygon({
				strokeColor: '#00CEA8',
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: '#000000',
				fillOpacity: 0.17,
				map: null
			});

			self.shape_filter_to_show = new google.maps.Polygon({
				strokeColor: '#00CEA8',
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: '#000000',
				fillOpacity: 0.17,
				map: null
			});

			// document.getElementById("btn-add-path").addEventListener("click", self.addPath);
			// document.getElementById("btn-validate-path").addEventListener("click", self.sendPath);
			// document.getElementById("btn-reset-path").addEventListener("click", self.resetPath);
			// document.getElementById("btn-remove-path").addEventListener("click", self.removePath);

			// document.getElementById("btn-validate-path").addEventListener("click", self.validatePath);
			// document.getElementById("btn-reset-path").addEventListener("click", self.resetPath);
			// document.getElementById("btn-change-zone-range").addEventListener("click", self.changeZoneRange);
			// document.getElementById("btn-change-zone-path").addEventListener("click", self.changeZonePath);

			// self.$refs.map.$mapObject.addListener('zoom_changed', self.zoom_changed)
			self.$refs.map.$mapObject.addListener('bounds_changed', self.bounds_changed)
			// self.updateMap();

			// let google = self.google;
			// let heatMapData = [
			// 	{location: new google.maps.LatLng(37.782, -122.447), weight: 0.5},
			// 	new google.maps.LatLng(37.782, -122.445),
			// 	{location: new google.maps.LatLng(37.782, -122.443), weight: 2},
			// 	{location: new google.maps.LatLng(37.782, -122.441), weight: 3},
			// 	{location: new google.maps.LatLng(37.782, -122.439), weight: 2},
			// 	new google.maps.LatLng(37.782, -122.437),
			// 	{location: new google.maps.LatLng(37.782, -122.435), weight: 0.5},

			// 	{location: new google.maps.LatLng(37.785, -122.447), weight: 3},
			// 	{location: new google.maps.LatLng(37.785, -122.445), weight: 2},
			// 	new google.maps.LatLng(37.785, -122.443),
			// 	{location: new google.maps.LatLng(37.785, -122.441), weight: 0.5},
			// 	new google.maps.LatLng(37.785, -122.439),
			// 	{location: new google.maps.LatLng(37.785, -122.437), weight: 2},
			// 	{location: new google.maps.LatLng(37.785, -122.435), weight: 3}
			// ];

			//AS--> Clic droit pour afficher les coordonnées pointées sur la carte
			self.$refs.map.$mapObject.addListener('rightclick', function(event) {
				var lat = event.latLng.lat();
				var lng = event.latLng.lng();
				alert(lat + "; " + lng);
			});

			//AS--> Clic gauche quand l'option est activée pour afficher les coordonnées pointées sur la carte
			self.$refs.map.$mapObject.addListener('click', function(event) {
				if(self.$store.state.ui.map_get_coords){
					var lat = event.latLng.lat();
					var lng = event.latLng.lng();
					self.$store.dispatch('set_map_coords_pointed', {lat, lng})
				}
			});

			//AS--> Enregistre l'affichage et le masquage de StreetMap
			self.$refs.map.$mapObject.getStreetView().addListener('visible_changed', function(event) {
				self.$store.dispatch('set_streetmap_visible', this.getVisible());
			});

		})

		//AS--> Mise en place du premier zoomAuto
		if(this.$store.state.ui.zoomAuto == 'report' && this.$store.state.filters.filters.map_filter_mode != 'zones'){
			setTimeout(function(){
				self.$store.commit('UI_UPDATE_ZOOMAUTO')
			}, 500)
		}
	},
	watch:{

		//AS--> Opération sur les changements de mode de filtre rayon/trace/zones
		map_filter_mode: function(after, before){

			//AS--> Si on était en mode 'trace', on sauvegarde le path
			if(before == 'trace'){
				this.paths_tmp = _.clone(this.paths);
				this.paths     = [];
			}

			//AS--> Si on revient en mode 'trace', on reprend le path
			if(after == 'trace'){
				if(this.paths_tmp){
					this.paths = _.clone(this.paths_tmp);
					this.paths_tmp = null;
				}else{
					this.initPath()
				}
			}

			//AS--> Si on arrive en mode zone et qu'il n'y a qu'un calque, on le sélectionne
			if(after == 'zones' && this.getZonesOptions.length === 1){
				this.changeLayer(this.getZonesOptions[0]);
			}
		},

		selected_marker: function(after, before){
			if(after !== false && this.$refs.map.$mapObject && after.is_group === false){
				this.$refs.map.$mapObject.setCenter({lat: after.geometry.coordinates[1], lng: after.geometry.coordinates[0]});
				if(this.$store.state.ui.sourcing.zoom_entreprise) this.$refs.map.$mapObject.setZoom(this.$store.state.ui.sourcing.zoom_entreprise);
			}
		},

		//AS--> A la mise à jour des filtres, on magouille un peu pour que l'info passe bien vers les sous-composants
		"$store.state.filters.filters": function(after, before){
			let self = this;
			this.map_filter_mode = this.$store.state.ui.content == 'map' && this.$store.state.filters.filters.map_filter_mode == 'aucun' ? 'rayon' : this.$store.state.filters.filters.map_filter_mode;
			this.zones_filter    = {layer: null, zones: []};
			setTimeout(function(){
				self.range = self.$store.state.filters.filters.range;
				self.position = self.$store.state.filters.filters.position;
				self.getPathsTransformed();
				self.zones_filter    = self.$store.state.filters.filters.zones_filter;

				//AS--> Zoom auto sur la modification du rayon
				if(self.$store.state.filters.filters.map_filter_mode == 'rayon' && before.range != after.range){
					self.$store.commit('UI_UPDATE_ZOOMAUTO')
				}
				
			}, 50)
		},

		//AS--> A la modification du choix de parcelle sélectionnée, on recentre la carte dessus
		"$store.state.parcelle.parcelle_on": function(after, before){
			let self = this;
			if(after){
				let bounds = this.geojsonBounds(this.$store.state.parcelle.parcelle_on)
				this.$refs.map.fitBounds(bounds)
				this.$refs.map.$mapObject.setZoom(this.$refs.map.$mapObject.getZoom()-1);
			}
		},

		//AS--> A la modification d'une adresse recherchée, on déplace la carte et on zoom
		"$store.state.ui.map_adresse": function(after, before){
			let self = this;
			if(after){
				this.$refs.map.$mapObject.setCenter(after.geometry.location);
				this.$refs.map.$mapObject.setZoom(13);
			}
		},

		//AS--> Surveille l'activation / désactivation du l'outil de mesure des distances
		"$store.state.ui.map_tools_distance": function(after, before){
			this.mesureDistance(after)
		},

		//AS--> Surveille l'activation / désactivation du l'outil de mesure des surfaces
		"$store.state.ui.map_tools_surface": function(after, before){
			this.mesureSurface(after)
		},

		//AS--> Switch l'affichage du curseur en fonction de l'activation de la récupération des coords alt/lng au clic
		"$store.state.ui.map_get_coords": function(after, before){
			this.$refs.map.$mapObject.setOptions({draggableCursor: after ? 'crosshair' : 'grab'});
		},

		//AS--> Switch l'affichage du curseur en fonction de l'activation de la récupération des coords alt/lng au clic
		"$store.state.ui.map_pin_pointed": function(after, before){
			if(after != null){
				if (this.tools_distance) {
					this.distance_path.push(after)

				} else if (this.tools_surface) {
					this.surface_path.push(after)
				}
			}
		},

		//AS--> Switch l'affichage du curseur en fonction de l'activation de la récupération des coords alt/lng au clic
		"$store.state.ui.zoomAuto": function(after, before){
			this.map_zoom_center_saved = false
		},

		//AS--> Ouvre l'info window sur le changement de l'entreprise sélectionnée
		"$store.state.company.company": function (after, before) {
			let self = this;
			//RZ--> Lors d'un changement d'id d'entreprise
			if (after.id != before.id) {
				//RZ--> Si on a un id d'entreprise
				if (after.id != null) {
					let company_id = after.id
					let marker = self.$store.getters.getMarkerById(company_id)
					
					//RZ--> Si on a trouvé le marker
					if (marker) {
						let group_markers = self.$store.getters.getGroupMarkers
						let is_group = false
						if (group_markers) {
							//RZ--> On regard si le marker est dans un cluster
							let _group_markers = Object.values(group_markers)
							for (let i = 0; i < _group_markers.length; i++) {
								if (_group_markers[i].ids.indexOf(marker._id) != -1) {
									is_group = true
									marker.ids = _group_markers[i].ids
									break
								}
							}
						}

						//RZ--> On affiche le marker jaune si ce n'est pas un groupe
						self.selected_marker = marker
						self.selected_marker.is_group = is_group
						let prefix_marker = is_group === false ? '_selected-marker' : '_marker'
						
						//RZ--> Si la fenêtre d'info n'est pas ouverte, on l'ouvre
						self.toggleInfoWindow(marker, company_id + prefix_marker)
					}
					//RZ--> Si on a pas d'id d'entreprise, on ferme la fenêtre d'info
				} else {
					self.selected_marker = false;
					self.infoWinOpen = false;
				}
			}
		},

		"$store.state.ui.map_custom_info_window": function(after, before){
			this.infoWindowCustomOpen = after !== null
		}
	},

	destroyed(){
		let self = this
		// document.getElementById("btn-add-path").removeEventListener("click", self.addPath);
		// document.getElementById("btn-validate-path").removeEventListener("click", self.sendPath);
		// document.getElementById("btn-reset-path").removeEventListener("click", self.resetPath);
		// document.getElementById("btn-remove-path").removeEventListener("click", self.removePath);

		// document.getElementById("btn-validate-path").removeEventListener("click", self.validatePath);
		// document.getElementById("btn-reset-path").removeEventListener("click", self.resetPath);
		// document.getElementById("btn-change-zone-range").removeEventListener("click", self.changeZoneRange);
		// document.getElementById("btn-change-zone-path").removeEventListener("click", self.changeZonePath);
	},
	methods:{
		getPathsTransformed(){
			//AS--> Récupération des informations de filtre trace
			if (this.$store.state.filters.filters.path) {
				let path = JSON.parse(this.$store.state.filters.filters.path);

				let new_paths = []
				_.each(path.coordinates, function(_paths){
					let new_path = []
					_.each(_paths[0], function(c, k){
						if (_paths[0].length > k + 1) {
							new_path.push({ lng: c[0], lat: c[1]})
						}
					})
					new_paths.push(new_path);
				})

				if(this.map_filter_mode == 'trace'){
					this.paths = new_paths
				}else{
					this.paths_tmp = new_paths
				}
			}
		},
		bounds_changed(){
			this.map_bounds     = this.$refs.map.$mapObject.getBounds()
			this.current_zoom   = this.$refs.map.$mapObject.getZoom()
			this.current_center = this.$refs.map.$mapObject.getCenter()
			this.save_map_state()
			this.bounds_changed_dispatch()
		},
		save_map_state: Debounce(function(){
			if(this.map_zoom_center_init){
				let self = this
				this.$store.dispatch('set_map_state', {
					center: {lat: self.current_center.lat(), lng: self.current_center.lng()},
					zoom: self.current_zoom
				});
				this.map_zoom_center_saved = true;
			}
		}),
		bounds_changed_dispatch: Debounce(function(){
			let ne = this.map_bounds.getNorthEast();
			let sw = this.map_bounds.getSouthWest();

			//AS--> Polygon de la zone
			let polygon = {
				"type": "Polygon",
				"coordinates": [[
					// lng, lat
					// lat = n <=> s
					// lng = e <=> o
					[sw.lng(),ne.lat()],
					[ne.lng(),ne.lat()],
					[ne.lng(),sw.lat()],
					[sw.lng(),sw.lat()],
					[sw.lng(),ne.lat()]
				]]
			}

			let geoJsonActif = null;

			//AS--> Zone à ne pas conserver dans la zone Google, basé sur le zone définie en BO 	//
			let inverse_zone_bo = turf.difference(polygon, this.$store.state.ui.sourcing.zone);		// Ces 2 actions remplacent un intersect, mais le intersect ne prend pas en compe les MultiPolygon
			//AS--> Calcul la zone à conserver d'après la fenêtre Google moins inverse_zone_bo		// On calcul donc la forme à conserver par suppression des zones à ne pas prendre en compte (zone Google - zone à prendre en compte)
			geoJsonActif = inverse_zone_bo ? turf.difference(polygon, inverse_zone_bo) : polygon;	//

			//AS--> S'il y a un filtre geo personnalisé, on l'applique en complément d'intersection
			if(this.$store.state.filters.filters.map_filter_mode != 'aucun'){
				switch(this.$store.state.filters.filters.map_filter_mode){

					case 'rayon':

						let center   = this.$store.state.filters.filters.position;
						center       = [center.lng, center.lat];
						let radius   = this.$store.state.filters.filters.range;
						let options  = {steps: 64, units: 'kilometers'};
						let circle   = turf.circle(center, radius, options);
						geoJsonActif = geoJsonActif ? turf.intersect(geoJsonActif, circle) : null;
					
						break;

					case 'trace':
						
						//AS--> Récupère les paths bruts
						let trace = this.getValidePaths(false);
	
						//AS--> Transforme tous les paths bruts en un Polygon uni						
						let trace_polygons = [];
						if(trace && trace.coordinates){
							trace.coordinates.forEach(function(poly){
								trace_polygons.push(turf.polygon(poly));
							})
						}
						let trace_zone_exterieure = turf.union.apply(this, trace_polygons);

						//AS--> Calcul la zone à ne pas prendre en compte dans la fenêtre actuelle
						let inverse_trace = turf.difference(polygon, trace_zone_exterieure);
						
						//AS--> Calcul la zone à conserver en prenant la zone geoJsonActif et en lui retirant tout ce qui est hors du calque de tracé
						if(inverse_trace) geoJsonActif = turf.difference(geoJsonActif, inverse_trace);

						break;

					case 'zones':

						//AS--> Transforme tous les paths bruts en un Polygon uni						
						if(this.$store.state.filters.zones_selected_polygons.length){

							if(this.$store.state.searches.layers.length){
								
								//AS--> Calcul du geojson du filtre
								let zones_zone_exterieure = null;
								this.$store.state.searches.layers.forEach(function(feature){
									zones_zone_exterieure = !zones_zone_exterieure ? feature : turf.union(zones_zone_exterieure, feature)
								})

								//AS--> Calcul la zone à ne pas prendre en compte dans la fenêtre actuelle
								let inverse_zones = turf.difference(polygon, zones_zone_exterieure);

								//AS--> Calcul la zone à conserver en prenant la zone geoJsonActif et en lui retirant tout ce qui est hors du calque de tracé
								if(inverse_zones) geoJsonActif = turf.difference(geoJsonActif, inverse_zones);
							}
						}

						break;

				}
			}

			//AS--> Ne récupère que la partie géometrie
			if(geoJsonActif){
				if(geoJsonActif.type == 'Feature') geoJsonActif = geoJsonActif.geometry

				this.$store.dispatch('set_map_zone_google', {
					bounds : 	this.map_bounds,
					zoom: 		this.current_zoom,
					polygon,
					geoJsonActif,
					area : turf.area(polygon) / 1000000,
					areaActif : turf.area(geoJsonActif) / 1000000
				});
			}

		}, 500),
		updateMap(){
			let self = this;
			if (self.loaded) {
				if(self.map_bounds == null) self.bounds_changed()
				self.infoWinOpen = false;

				let google = self.google;
				let bounds = new google.maps.LatLngBounds()
				let circle = self.getRangePath
				for(let i in circle){
					bounds.extend(new google.maps.LatLng(circle[i].lat(), circle[i].lng()))
				}
				self.$refs.map.fitBounds(bounds);
			}
		},
		toggleInfoWindow: function(marker, idx) {
			let self = this;
			let infoWinOpen = self.infoWinOpen;
			
			setTimeout(() => {
				self.infoWindowPos = {lat:marker.geometry.coordinates[1], lng:marker.geometry.coordinates[0]};

				//AS--> commit infoWindowPos sur UI_SET_MAP_PIN_POINTED
				self.$store.commit('UI_SET_MAP_PIN_POINTED', self.infoWindowPos);

				let marker_type = idx.split('_');

				// if(marker_type[1] != 'selected-marker') this.$store.dispatch('setCompany', null);

				let option_height = 0;
				switch(marker_type[1]){
					case 'marker':
						option_height = -15;
						break
					case 'cluster':
						option_height = -25;
						break
					case 'selected-marker':
						option_height = -55;
						break
				}

				self.infoOptions = {
					pixelOffset: {
						width: 0,
						height: option_height
					}
				}

				//check if its the same marker that was selected if yes toggle
				if (self.currentMidx == idx) {
					infoWinOpen = !infoWinOpen;
				}
				//if different marker set infowindow to open and reset current marker index
				else {
					infoWinOpen = true;
					self.currentMidx = idx;

				}

				if(infoWinOpen){
					let entreprises_ids = []
					if (marker.ids != undefined) {
						entreprises_ids = marker.ids
					}else if (marker.type != undefined && marker.type == 'Feature') {
						let entreprises = self.super_cluster.getLeaves(marker.properties.cluster_id, 20, 0)

						_.forEach(entreprises, function(_entreprises){
							entreprises_ids.push(_entreprises._id)
						})
					}else{
						entreprises_ids.push(marker._id)
					}

					//AS--> Ne garder que les 100 premiers
					entreprises_ids = entreprises_ids.slice(0, 100)

					let main_dechet_id = self.$store.state.filters.filters.main_dechet_id
					let national_ids = [];

					entreprises_ids.forEach(function(entreprise){
						national_ids.push('national_ids='+entreprise)
					})
					national_ids = national_ids.join('&')

					//AS--> On vérifie si la liste des entreprises à changé. Sinon, on ne fait pas la requête. 
					let old_entreprises_ids = self.entreprises.map(function(entreprise){
						return entreprise.id
					})
					if(old_entreprises_ids.sort().join(',') != entreprises_ids.sort().join(',')){
						self.infoWindowLoading = true;

						axios.get('api=inex/companies/?'+national_ids+'&format=markers')
							.then(function (response) {
								if(response.data != 'CONNEXION'){
									self.infoWinOpen = true;
									self.entreprises = response.data
								}
							}).catch(function (error) {

							}).finally(function(){
								if (self.infoWindowLoading == true) self.infoWindowLoading = false;
							})
					}else{
						self.infoWinOpen = true;
					}
				}else{
					self.infoWinOpen = false
				}
			}, 10)
		},
		openGroupMarker(marker, idx){
			let m = {
				geometry: {
					coordinates: [marker.coord[0], marker.coord[1]]
				}
			}

			marker = _.merge(marker, m)
			this.toggleInfoWindow(marker, idx);
		},
		clickCluster: function(marker, idx) {
			let max_zoom = 12
			if (marker.properties.point_count <= 1) {
				this.toggleInfoWindow(marker, idx);
			}else{
				this.$refs.map.$mapObject.panTo({lat: marker.geometry.coordinates[1], lng: marker.geometry.coordinates[0]});
				this.$refs.map.$mapObject.setZoom(this.current_zoom + 1);
			}
		},
		switchClusters: function() {
			this.layer_clusters = !this.layer_clusters
		},
		addPath(){
			this.initPath()
		},
		deletePath(){
			this.paths = this.paths.slice(0, -1);
		},
		initPath: function () {

			// obtain the bounds, so we can guess how big the polygon should be
			var bounds    = this.map_object.getBounds()
			var northEast = bounds.getNorthEast()
			var southWest = bounds.getSouthWest()
			var center    = bounds.getCenter()
			var degree    = this.paths.length + 1;
			var f         = Math.pow(0.66, degree)

			// Draw a triangle. Use f to control the size of the triangle.
			// i.e., every time we add a path, we reduce the size of the triangle
			var path = [
				{ lng: center.lng(), lat: (1-f) * center.lat() + (f) * northEast.lat() },
				{ lng: (1-f) * center.lng() + (f) * southWest.lng(), lat: (1-f) * center.lat() + (f) * southWest.lat() },
				{ lng: (1-f) * center.lng() + (f) * northEast.lng(), lat: (1-f) * center.lat() + (f) * southWest.lat() },
			]

			this.paths.push(path)
		},
		// closeLoop: funct{
		closeLoop: function(path) {
			return path.concat(path.slice(0, 1))
		},
		updateEdited: function (mvcPaths, path_key) {
			mvcPaths = _.clone(mvcPaths)
			this.path_active = path_key;

			let path = [];
			for (let j=0; j<mvcPaths.getAt(0).getLength(); j++) {
				let point = mvcPaths.getAt(0).getAt(j);
				path.push({lat:point.lat(), lng:point.lng()});
			}

			this.$set(this.paths, path_key, path)	//AS--> Formule de mise à jour du tableau pour garder la réactivité
		},
		handleClickForDelete($event) {
			if ($event.vertex) {
				this.$refs.polygon.$polygonObject.getPaths()
				.getAt($event.path)
				.removeAt($event.vertex)
			}
		},
		setSelectedCity(value){
			let self = this;

			if (value != null) {
				axios.get('/plugin_projet/plugin_projet_iannuaire/get_place_id/'+value.place_id)
				  	.then(function (response) {
						self.position = response.data.position
						// self.$store.dispatch('setPosition', {lat: self.position.lat, lng: self.position.lng});
						// self.cities = response.data
					}).catch(function (error) {

					})	
			}else{
				self.position = {
					lat: null,
					lng: null
				}
			}
		},
		search_villes: Debounce(function(search, loading){
			let self = this;
			loading(true)
			axios.get('/plugin_projet/plugin_projet_iannuaire/places_autocompletion/'+encodeURI(search))
				.then(function (response) {
					self.cities = response.data
					loading(false)
				}).catch(function (error) {

				})
		}, 500),
		getValidePaths(stringify){
			let self = this;
			let paths = this.paths

			if (paths == null) {
				paths = this.paths
			}

			if (paths.length == 0) return null


			let center = this.map_object.getBounds().getCenter();
			let zoom   = this.map_object.getZoom();
			let coordinates = [];

			//AS--> Trasnforme les coordonnées des paths en coordonnées pour GeoJson
			paths.forEach(function(path){
				let _path = [];
				path.forEach(function(c){
					_path.push([c.lng, c.lat]);
				})
				_path = self.closeLoop(_path);
				coordinates.push([_path]);
			})

			//AS--> Monte le GeoJson et le transforme en string
			let polygonGeojson = {
				type: 'MultiPolygon',
				coordinates,
				center,
				zoom
			}
			if(undefined == stringify || true == stringify) polygonGeojson = JSON.stringify(polygonGeojson, null, 2);

			return polygonGeojson;
		},
		validateFilters(){
			let zone_filters = {
				zones_filter: 	this.zones_filter,
				area_filter: 	true,
				range: 			this.range,
				position: 		this.position,
				position_name: 	this.filtre_city != null ? this.filtre_city.nom : '',
				path: 			this.getValidePaths(),
				area: 			Math.round(this.getAireActuelle)
			}

			//AS--> Si on est en mode Tracé mais qu'il n'y a pas de tracé réalisé, on dit qu'il n'y a pas de filtre
			if(this.map_filter_mode == 'trace' && zone_filters.path == null) 					this.map_filter_mode = 'aucun';
			
			//AS--> Si on est en mode Zones mais qu'il n'y a pas de zones sélectionnées, on dit qu'il n'y a pas de filtre
			if(this.map_filter_mode == 'zones' && zone_filters.zones_filter.zones.length < 1) 	this.map_filter_mode = 'aucun';
			
			//AS--> Si on est en mode Rayon mais que le rayon est à 0, on dit qu'il n'y a pas de filtre
			if(this.map_filter_mode == 'rayon' && !zone_filters.range) 							this.map_filter_mode = 'aucun';

			zone_filters.map_filter_mode = this.map_filter_mode;

			this.$store.dispatch('setZoneFilters', zone_filters);
			this.$store.commit('UI_SET_CONTENT', {content: 'result'});
		},
		resetFilters(){
			this.$store.dispatch('resetGeographique');
			this.$store.commit('UI_SET_CONTENT', {content: 'result'});
		},
		mesureDistance(active){
			this.distance_path = []
			this.surface_path = []

			this.tools_distance_result = 0
			this.tools_surface_result = 0
			this.tools_distance = active
			this.tools_surface = 0
		},
		mesureSurface(active){
			this.distance_path = []
			this.surface_path = []

			this.surface_name = ''
			this.surface_id = null
			this.zoom_surface = true

			this.tools_distance_result = 0
			this.tools_surface_result = 0
			this.tools_surface = active
			this.tools_distance = 0
		},
		mapClick(e){
			if (this.tools_distance) {
				if (this.tools_distance_result != 0) {
					this.distance_path = []
					this.tools_distance_result = 0
				}

				let lat = e.latLng.lat();
				let lng = e.latLng.lng();
				this.distance_path.push({lat, lng})
			}

			if (this.tools_surface) {
				if (this.tools_surface_result != 0) {
					this.surface_path = []
					this.tools_surface_result = 0
				}

				let lat = e.latLng.lat();
				let lng = e.latLng.lng();
				this.surface_path.push({lat, lng})
			}
		},
		mapMouseMove: _.throttle(function(e){
			let lat = e.latLng.lat();
			let lng = e.latLng.lng();
			this.map_cursor = {lat, lng}
		},50),
		calcul_distance(){
			let distance = 0;
			let last = null
			_.forEach(this.distance_path, function(v){
				if (last != null) {
					let _distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(v['lat'], v['lng']), new google.maps.LatLng(last['lat'], last['lng']));
					distance += _distance
				}
				last = v
			})

			this.tools_distance_result = distance
		},
		calcul_surface(){
			let distance = 0;
			let last = null

			this.surface_path.push(this.surface_path[0])

			let p = []
			_.forEach(this.surface_path, function(m){
				p.push(new google.maps.LatLng(m.lat, m.lng))
			})
			let area = self.google.maps.geometry.spherical.computeArea(p)
			this.tools_surface_result = area
		},
		updateSurfacePath: function(mvcPaths){
			let path = [];
			for (let i=0; i < mvcPaths.getLength(); i++) {
				for (let j=0; j<mvcPaths.getAt(i).getLength(); j++) {
					let point = mvcPaths.getAt(i).getAt(j);
					path.push({lat: point.lat(), lng: point.lng()});
				}
			}

			this.surface_path = path
		},
		changeLayer: function(val){
			let zones_filter = _.clone(this.zones_filter);
			zones_filter = {layer: val == null ? null : val._id, zones: []};
			this.zones_filter = zones_filter;
			// this.$set(this.zones_filter, 'layer', val == null ? null : val._id)
			// this.$set(this.zones_filter, 'zones', [])
			this.$store.commit('UI_SWITCH_MAP_LAYER', this.zones_filter.layer);
		},
		zonesChanged: function(zones){
			let zones_filter = _.clone(this.zones_filter);
			zones_filter.zones = zones;
			this.zones_filter = zones_filter;
		},
		open_save_surface: function(){
			this.modal_surface = !this.modal_surface;
		},
		save_surface: function(surface_id){
			let self = this
			let data = {
				id: surface_id,
				nom: this.surface_name,
				surface: this.surface_path,
				area: this.tools_surface_result
			}

			axios.post('/plugin_projet/plugin_projet_iannuaire/save_surfaces', data)
				.then(function (response) {
					if (_.isNumber(response.data)) {
						self.surface_id = parseInt(response.data);
						self.modal_surface = false;
						self.$store.dispatch('updateUser');
					}else{
						self.surface_erreur = response.data
					}
				}).catch(function (error) {
					console.log(error);
				})
		},
		closeRecherche: function(){
			this.$store.commit('UI_RECHERCHE_EDITION', false)
			this.$store.dispatch('resetAllFilters')
		},
		rechercheShowSaving: function(){
			this.recherche_show_saving   = true
			this.recherche_show_modified = false
		},
		rechercheShowModified: function(){
			this.recherche_show_saving   = false
			this.recherche_show_modified = true
		},
		getDate: function(_date){
			return moment(_date).format('DD/MM ['+this.$t('bulle_recherche_active_enregistre_a')+'] HH[h]mm')
		},
		clusterMarkersClick(cluster){
			userflow.track('cluster_click')
			console.log('==> UserFlow - Track(cluster_click)')
		}
	},
	computed:{
		google: gmapApi,
		getMapOptions(){
			let options = this.mapOptions

			return options;
		},
		get_distance_path(){
			let path = [];

			let cursor = this.map_cursor

			_.forEach(this.distance_path, function(v){
				path.push(new google.maps.LatLng(v['lat'], v['lng']))
			})

			if (this.tools_distance_result == 0 && cursor.lat != undefined) {
				path.push(new google.maps.LatLng(cursor.lat, cursor.lng))
			}

			return path
		},
		getPathOptionsDistance(){
			let options = this.path_options_distance
			if (this.tools_distance_result != 0) {
				options.strokeOpacity = 1
				options.icons = []
			}else{
				options.strokeOpacity = 0
				options.icons = this.path_options_distance_icons
			}

			return options
		},
		getDistanceInfoOptions(){
			let options = this.infoOptionsDistance
			
			return options
		},
		get_distance_end_position(){
			let last = this.distance_path[this.distance_path.length-1]
			
			return new google.maps.LatLng(last['lat'], last['lng'])
		},
		getDistance(){
			let distance = 0;
			let last = null
			_.forEach(this.distance_path, function(v){
				if (last != null) {
					let _distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(v['lat'], v['lng']), new google.maps.LatLng(last['lat'], last['lng']));
					distance += _distance
				}
				last = v
			})

			return distance >= 1000 ? _.round(distance / 1000,2)+' km' : _.round(distance,2)+' m'
		},
		get_surface_path(){
			let path = [];

			let cursor = this.map_cursor

			_.forEach(this.surface_path, function(v){
				path.push(new google.maps.LatLng(v['lat'], v['lng']))
			})

			if (this.tools_surface_result == 0 && cursor.lat != undefined) {
				path.push(new google.maps.LatLng(cursor.lat, cursor.lng))
			}

			return path
		},
		getPathOptionsSurface(){
			let options = this.path_options_surface
			if (this.tools_surface_result != 0) {
				options.strokeOpacity = 1
				options.icons = []
			}else{
				options.strokeOpacity = 0
				options.icons = this.path_options_distance_icons
			}

			return options
		},
		getSurfaceInfoOptions(){
			let options = this.infoOptionsDistance
			
			return options
		},
		get_surface_start_position(){
			let last = this.surface_path[0]
			
			return new google.maps.LatLng(last['lat'], last['lng'])
		},
		get_surface_button_position(){
			let last = this.surface_path[0]
			let max_lat = null;
			let max_lng = null;

			_.forEach(this.surface_path, function(c){
				if (c.lat > max_lat) max_lat = c.lat
				if (c.lng > max_lng) max_lng = c.lng
			})
			
			return new google.maps.LatLng(max_lat, max_lng)
		},
		getSurface(){
			let distance = 0;
			let last = null

			let p = []
			_.forEach(this.surface_path, function(m){
				p.push(new google.maps.LatLng(m.lat, m.lng))
			})
			let area = self.google.maps.geometry.spherical.computeArea(p)

			let result = this.$t('map_tools_surface_label')+' : ';
			result += area >= (1000 * 1000) ? _.round(area / (1000*1000),2)+' km²' : _.round(area,2)+' m²'

			if (this.$store.getters.hasCustomSurface) {
				result += '<br/>'+this.$store.state.ui.sourcing.map_tools_surface_custom_label+' : ';
				let custom_area = area * this.$store.state.ui.sourcing.map_tools_surface_custom_ratio
				result += custom_area >= (1000 * 1000) ? _.round(custom_area / (1000*1000),2)+' km²' : _.round(custom_area,2)+' m²'
			}

			return result
		},
		getPathOptions(){
			let options  = this.path_options

			options.strokeColor = this.getAirSupp ? '#ff8100' : '#00CEA8'
			options.fillColor = this.getAirSupp ? '#ff8100' : '#00CEA8'

			return options
		},
		layerHeatMap(){
			let layer_heatmap = this.$store.state.ui.layer_heatmap.enable && this.$store.state.ui.layer_heatmap.open;
			if (this.loaded && this.heatmap.data != undefined) {
				this.heatmap.setMap(layer_heatmap ? this.$refs.map.$mapObject : null);
			}
		},
		layerHeatMapPvrMet(){
			let layer_heatmap_pvrmeta = this.$store.state.ui.layer_heatmap.enable && this.$store.state.ui.layer_heatmap_pvrmeta.open;
			if (this.loaded && this.heatmapPvrmeta.data != undefined) {
				this.heatmapPvrmeta.setMap(layer_heatmap_pvrmeta ? this.$refs.map.$mapObject : null);
			}
		},
		zoomAuto(){

			if(this.$store.state.ui.zoomAuto != this.$store.state.ui.zoomAuto_last && this.$store.state.ui.zoomAuto != 'report'){
				this.$store.commit('UI_SAVELAST_ZOOMAUTO')

				if(!this.map_zoom_center_saved){

					let filters       = this.$store.state.filters.filters;
					let position      = this.position;
					let mode          = this.map_filter_mode
					let range         = this.range
					let paths         = this.paths
					let zones_filtres = this.zones_filter

					if (this.loaded) {

						let bounds             = new google.maps.LatLngBounds()
						let setWithDefaultPath = false;

						switch(filters.map_filter_mode){

							case 'rayon':
								if(this.position){
									_.each(this.getRangePath, function(c){
										bounds.extend(new google.maps.LatLng(c.lat(), c.lng()))
									})
								}else{
									setWithDefaultPath = true;
								}
								break

							case 'trace':
								if (filters.path != null && filters.path.length > 0 && this.$store.state.ui.content == 'result') {
									let path = JSON.parse(filters.path);
									_.each(path.coordinates, function(p){
										_.each(p, function(cc){
											_.each(cc, function(c){
												bounds.extend(new google.maps.LatLng(c[1], c[0]))
											})
										})
									})
								}else{
									setWithDefaultPath = true;
								}
								break

							case 'zones':
								if (filters.zones_filter && filters.zones_filter.zones && filters.zones_filter.zones.length > 0 && this.$store.state.ui.content == 'result') {

									if(this.$store.state.ui.layers_data[zones_filtres.layer]){
										//AS--> Cherche les paths actifs
										_.forEach(this.$store.state.ui.layers_data[zones_filtres.layer].features, function(geoJson){
											if(zones_filtres.zones.includes(geoJson.id)){

												//AS--> Récupère les bounds de chaque GeoJson grâce à un outil de calcul dédié
												let _bounds = GeojsonExtent(geoJson.geometry);
												bounds.extend(new google.maps.LatLng(_bounds[1], _bounds[0]))
												bounds.extend(new google.maps.LatLng(_bounds[3], _bounds[2]))
											}
										})
										
									}else{
										setWithDefaultPath = true;
									}
								}else{
									setWithDefaultPath = true;
								}

								break

							default:
								setWithDefaultPath = true;
								
								break
						}

						//AS--> Zone définie sur le Path du BO
						if(setWithDefaultPath){
							let zone_path = this.getZonePaths
							_.each(zone_path, function(p){
								_.each(p, function(c){
									bounds.extend(c)
								})
							})
						}

						if (this.zoom_surface) {
							if (this.surface_path != null && this.surface_path.length > 2 && this.$store.state.ui.content == 'result') {
								bounds = new google.maps.LatLngBounds()
								_.each(this.surface_path, function(p){
									bounds.extend(new google.maps.LatLng(p.lat, p.lng))
								})
							}
						}

						if (!_.isEqual(this.$refs.map.$mapObject.getBounds(), bounds)) {
							this.bounds = bounds
							if(bounds.toJSON().east == -180){
								this.$store.commit('UI_UPDATE_ZOOMAUTO', 'report')
							}else{
								this.$refs.map.$mapObject.fitBounds(bounds);
							}
						}
							
						this.map_zoom_center_init = true;
					}
				}
			}

			return true
		},
		setClusters(){
			let markers = this.$store.state.entreprises.markers

			if (this.loaded) {

				let heatMapData = []
				let heatMapPvrMeta = []

				if (this.heatmap.data != undefined) {
					this.heatmap.setMap(null);
				}

				if (this.heatmapPvrmeta.data != undefined) {
					this.heatmapPvrmeta.setMap(null);
				}

				_.forEach(markers, function(mark){
					heatMapData.push({location: new google.maps.LatLng(mark.geometry.coordinates[1], mark.geometry.coordinates[0]), weight: mark.q})
					heatMapPvrMeta.push({location: new google.maps.LatLng(mark.geometry.coordinates[1], mark.geometry.coordinates[0]), weight: mark.m})
				})

				this.heatmap = new google.maps.visualization.HeatmapLayer({
				  data: heatMapData,
				  radius: 40
				});

				this.heatmapPvrmeta = new google.maps.visualization.HeatmapLayer({
				  data: heatMapPvrMeta,
				  radius: 40
				});
			}

			this.super_cluster.load(markers)
			this.markers_version = _.uniqueId()

			return true;
		},
		getClusters(){
			let self = this;
			let markers = [];
			let markers_usine = [];
			let markers_sante = [];

			let markers_version = this.markers_version

			if (self.loaded && self.map_bounds) {
				
				let ne = self.map_bounds.getNorthEast();
				let sw = self.map_bounds.getSouthWest();

				// let clusters = this.super_cluster.getClusters([-180, -85, 180, 85], 2)
				let _clusters = self.super_cluster.getClusters([sw.lng(), sw.lat(), ne.lng(), ne.lat()], self.current_zoom)

				let familles_markers = {};
				let clusters_markers = [];
				let clusters = [];
				_.forEach(_clusters, function(cluster){
					if (cluster.type != undefined && cluster.type == 'Feature') {
						
						//AS--> Si on est en mode de calcul direct et points groupés, on remplace le compteur de marker par défaut par la sommes des points représentés par chaque marker
						if(self.$store.state.entreprises.map_grouped){
							let markers = self.super_cluster.getLeaves(cluster.id, 10000000);
							let nbr = 0;
							markers.forEach(function(v, i){
								nbr += v.n
							})
							cluster.properties.point_count = nbr;
							cluster.markers = markers;
						}
						
						clusters.push(cluster)
					} else {
						markers.push(cluster)

						if(self.$store.state.entreprises.map_grouped){
							clusters_markers.push(cluster);

						}else{
							let famille_id = _.head(cluster.f)
							if (famille_id != undefined) {
								if (typeof familles_markers[famille_id] == 'undefined'){
									let _icon = {}
									let _shape = {}
									_.forEach(self.$store.state.ui.sourcing.familles, function(famille){
										if (famille.id == famille_id && famille.marker_imgu) {
											_icon.url = famille.chemin_image+famille.marker_imgu

											if (famille.marker_anchor) {
												let anchor = famille.marker_anchor.split(',')
												_icon.anchor = {
													x: parseInt(anchor[0], 10),
													y: parseInt(anchor[1], 10)
												}
											}

											if (famille.marker_size) {
												let scale = famille.marker_size.split(',')
												_icon.scaledSize = {
													height: parseInt(scale[0], 10),
													width: parseInt(scale[1], 10)
												}

												let width = scale[0] / 2

												_shape = {type: 'circle','coords': [width,width,width]}
											}
										}
									})

									familles_markers[famille_id] = {
										icon: _icon,
										shape: _shape,
										markers: []
									}
								} 

								familles_markers[famille_id]['markers'].push(cluster);
							}
						}
					}
				})

				self.clusters = clusters
				self.clusters_markers = clusters_markers

				//RZ--> groupe les markers par lat et lng
				let group_markers = self.$store.getters.getGroupMarkersFromMarkers(markers)
				let remove_ids = []
				_.forEach(group_markers, function (group_marker, index){
					//RZ--> Récupère la famille du premier marker pour récupérer l'icone
					var famille = null
					_.forEach(familles_markers, function (_famille, _famille_key){
						_.forEach(_famille.markers, function (_m, _m_key){
							if (_m._id == group_marker.ids[0]) {
								famille = _famille
								return false
							}								
						})
					})

					group_markers[index]['icon'] = famille?.icon ?? self.getIconGroupMarker
					remove_ids = _.union(remove_ids, group_marker.ids)
				})

				//RZ--> Supprime des familles les markers présents dans group_markers
				_.forEach(familles_markers, function(m, k){
					let new_m = []
					_.forEach(m.markers, function(_m, _k){
						if (!remove_ids.includes(_m._id)) {
							new_m.push(_m)
						}
					})

					familles_markers[k]['markers'] = new_m
				})

				self.markers          = markers
				self.familles_markers = familles_markers
				self.group_markers = group_markers
			}
		},
		getIcon(){
			return {url: '/theme/annuaire/img/PingMap.png', scaledSize: {height:20, width:20}, anchor:{x:10,y:10}}
		},
		getIconCluster(){
			return {url: '/theme/annuaire/img/cluster_green_1.png', scaledSize: {height:36, width:36}, anchor:{x:18,y:18}}
		},
		getIconSelectedMarker(){
			return {url: '/theme/annuaire/img/marker-orange.png', anchor:{x:17,y:50}}
		},
		getIconDistance(){
			return {url: '/theme/annuaire/img/PingMapOrange.png', scaledSize: {height:16, width:16}, anchor:{x:8,y:8}}
		},
		getIconGroupMarker(){
			return {url: '/theme/annuaire/img/PingMapGroup.png', scaledSize: {height:30, width:30}, anchor:{x:15,y:15}}
		},
		getIconSurfaceButton(){
			return {url: '/theme/annuaire/img/btn_surface.png', scaledSize: {height:34, width:34}, anchor:{x:-10,y:0}}
		},
		getRangePath(){
			let circle = []
			let range = this.range
			if (this.loaded && this.position?.lat && this.map_bounds != null) {
				let google = this.google;
				let center = new google.maps.LatLng(this.position.lat, this.position.lng)
				for (let i = 0; i <= 360; i++) {
					let d = google.maps.geometry.spherical.computeOffset(center, range*1000, i)
					circle.push(d)
				}
			}
			return circle
		},
		getZonePaths(){
			//AS--> Récupère les paths bruts
			let trace = this.$store.state.ui.sourcing.zone.type == 'Polygon' ? [this.$store.state.ui.sourcing.zone.coordinates] : this.$store.state.ui.sourcing.zone.coordinates;
			return this.geojsonCoordinates2googlePolygon(trace)
		},
		getMapPaths(){
			let outerCoords = []
			if (this.loaded && this.map_bounds != null) {
				let ne = this.map_bounds.getNorthEast();
				let sw = this.map_bounds.getSouthWest();
				outerCoords = [
					{lat: ne.lat()+1, lng: sw.lng()-1},
					{lat: sw.lat()-1, lng: sw.lng()-1},
					{lat: sw.lat()-1, lng: ne.lng()+1},
					{lat: ne.lat()+1, lng: ne.lng()+1}
				];
			}

			return outerCoords
		},
		getRangeFullPaths(){
			let self = this

			let range           = this.$store.state.filters.filters.range;
			let position        = this.position;
			let map_filter_mode = this.map_filter_mode;

			if(this.shape_range){
				if(map_filter_mode != 'rayon'){
					this.shape_range.setMap(null);
					return;
				}

				if (this.loaded && this.map_bounds != null) {

					let outerCoords = this.getMapPaths
					let circle = this.getRangePath

					let _circle = []
					_.forEach(circle, function(c){
						_circle.push({lat: c.lat(), lng: c.lng()})
					})

					if(_circle[0]?.lat){
						let positionRange = position.lat + '_' + position.lng + '_' + range
						this.shape_range.setOptions({
							paths: [outerCoords, circle],
							clickable: false,
							strokeColor: this.getAirSupp ? '#ff8100' : '#00CEA8'
						})
						this.shape_range.setMap(this.$refs.map.$mapObject);

						//AS--> S'il y a eu un changement de position ou de rayon, on relance le calcul du zoom
						if(positionRange != this.lastPositionRange){
							setTimeout(function(){
								self.$store.commit('UI_UPDATE_ZOOMAUTO')
							}, 200)
							this.lastPositionRange = positionRange
						}
					}

				}
			}

			return true
		},
		getFullZonePaths(){
			// if (this.loaded && this.map_bounds != null) {

			// 	let outerCoords = this.getMapPaths
			// 	let zone_coord = this.getZonePaths

			// 	this.shape_zone.setOptions({
			// 		paths: [outerCoords, zone_coord],
			// 		clickable: false,
			// 	})

			// 	if(this.shape_zone.map == null){
			// 		this.shape_zone.setMap(this.$refs.map.$mapObject);
			// 	}
			// }

			// return true
		},
		getFiltersChange(){
			// let path = this.$store.state.filters.filters.path
			// path = JSON.parse(path);

			// if (zone == 'path' && path != null) {
			// 	console.log(path);
			// 	this.paths = path.coordinates.map(linearRing =>
			// 		linearRing.slice(0, linearRing.length - 1)
			// 		.map(([lng, lat]) => ({lat, lng}))
			// 	)
			// 	// if(typeof path.center != 'undefined') this.center = path.center;
			// 	// if(typeof path.zoom != 'undefined') this.zoom = path.zoom;
			// }

			// if (zone == 'range') {
			// 	this.paths = []
			// }
			// console.log(this.paths);
		},
		getRange:{
			// accesseur
			get: function () {
				let filters = this.$store.state.filters.filters
				this.range = filters.range
				return filters.range
			},
			// mutateur
			set: function (value) {
				this.range = value
				// this.$store.dispatch('setRange',value)
			}
		},
		rayon_maxi(){
			return this.$store.state.ui.sourcing.zone_rayon_max > 0 ? this.$store.state.ui.sourcing.zone_rayon_max : 200;
		},
		getAireLimite(){
			return this.$store.state.ui.sourcing.limit_aire ? this.$store.state.ui.sourcing.limit_aire : 100000000000000000000;
		},
		getAireActuelle(){
			let mode          = this.map_filter_mode
			let range         = this.range || 0
			let paths         = this.paths
			let zones_filtres = this.zones_filter

			let self  = this

			let aire = 0

			switch(mode){
				case 'rayon':
					let r     = range * 1000
					aire = Math.PI * r * r
					break;

				case 'trace':
					_.forEach(paths, function(path){
						let p = []
						_.forEach(path, function(m){
							p.push(new google.maps.LatLng(m.lat, m.lng))
						});
						aire += self.google.maps.geometry.spherical.computeArea(p)
					})
					break;

				case 'zones':

					if(this.$store.state.ui.layers_data[zones_filtres.layer]){
						//AS--> Cherche les bons paths
						_.forEach(this.$store.state.ui.layers_data[zones_filtres.layer].features, function(geoJson){
							if(zones_filtres.zones.includes(geoJson.id)){
								aire += GeojsonArea.geometry(geoJson.geometry);
							}
						})
					}

					break;
			}

			return Math.round(aire / 1000000)
		},
		getAirSupp(){
			return this.getAireLimite < this.getAireActuelle;
		},
		getMaxRange(){
			return Math.sqrt(this.getAireLimite/Math.PI)
		},
		showAireInfos(){
			return this.$store.getters.canShowAireLimit && this.$store.state.ui.content == 'map' && this.getAireLimite < this.$store.state.ui.sourcing.zone_area
		},
		getZonesOptions(){
			let layers = []
			_.forEach(this.$store.state.ui.layers, function(layer, i){
				if(layer.type == "interne" && layer.filtre == 1) layers.push({label: layer.nom, _id: layer.layer, i})
			})
			return layers;
		},
		getZonesLayer(){
			return this.zones_layer != null ? this.zones_layer.id : null;
		},
		isLoading(){
			let self = this
			let calques_non_charges_et_ouverts = []
			_.forEach(self.$store.state.ui.sourcing.layers, function(layer,i){
				if(!self.$store.state.ui.layers_loaded.includes(i) && self.$store.state.ui.layers_opened.includes(i)) calques_non_charges_et_ouverts.push(i)
			});
			return this.$store.state.ui.loading_map || calques_non_charges_et_ouverts.length
		},
		infobulle(){
			if(this.$store.state.parcelle.feature_over){
				let feature = this.$store.state.parcelle.feature_over;
				return feature.getProperty('section') + ' ' + feature.getProperty('numero')
			}
			return null
		}
	},
	components: {
		CompanyDetail,
		VueSlider,
		vSelect,
		GeojsonLayer,
		GeojsonLayerEntreprise,
		GeojsonLayerStandard,
		GeojsonArea,
		GeojsonExtent,
		SaveRecherche,
		Loading
		// 'gmap-cluster': VueGoogleMapsCluster

	}
}
