import _ from 'lodash'

export default {
	data() {
		return {
			affiche: true
		}
	},
	props: [],
	created(){
		
	},
	methods: {
		
	},
	computed:{
		
	},
	components:{
		
	}
}