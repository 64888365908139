import GraphIp from '@/common/ui/graphIp'

export default {
	data() {
    return {
    	
    }
  },
  props: ['entreprise'],
  computed:{
    checkConditions(){
      return this.$store.getters.fonctionnement == 'dechets' 
        && this.$store.getters.canUseIp 
        && this.$store.getters.canShowColonnesInfo 
        && this.$store.state.ui.dechets_columns.length > 0 
        && this.$store.state.ui.content != 'favoris' 
        && this.$store.getters.canShowColonneDechets
        && this.entreprise.dechets.length                 //AS--> Si l'entreprise a des déchets
    },
    getIp() {
      return Math.ceil(this.entreprise.ip)
    },
    getIpLabel() {
      return this.entreprise.ip ? this.$t('ip_label_' + this.getIp).replace(/\n/g, '<br>') : '';
    }
  },
  components:{
    GraphIp
  }
}