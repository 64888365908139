import vueCustomScrollbar from 'vue-custom-scrollbar'
import Arbo from './blocs/arbo.vue'
import Debounce from 'lodash/debounce'
import CustomInput from '../common/forms/Input.vue'
import FiltreHeader from './filtres/_filtre_header.vue';

export default {
	data() {
    return {
    	q: '',
      qIds: [],
      selectedIds: [],
      settings: {},
      initNaces: [],
      naces: [],
      filtered_naces: [],
      regexp: null,
      closeAll: true
    }
  },
  created(){
  	let self = this;
  	self.naces = self.$store.getters.getSecteurs
  	self.$store.watch(function(){
        return self.$store.state.ui.secteurs
      }, function(){
  			self.naces = self.$store.state.ui.secteurs
      }
    )

    this.debounceQ = Debounce(this.updateSearch, 500)
  },
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
		},
		updateSearch(){
      let self = this
      if (self.q == '') {
      	self.closeAll = true;
      	this.naces = self.$store.state.ui.secteurs
      }else{
      	self.qIds = [];
      	self.filtered_naces = [];
      	self.selectedIds = self.$store.state.filters.filters.secteurs_ids;
      	self.closeAll = false;

      	let preg_quote = require('locutus/php/pcre/preg_quote');
	      let terms = self.q.split(' ');
	      terms.map(term => preg_quote(term));
	      self.regexp = new RegExp('('+terms.join('|')+')', 'im');
      	self.filteredNace(self.getInitNaces);

      	let label = self.filtered_naces.length > 0 ? self.$t('filter_sector_resultats')+' : ' : self.$t('filter_sector_resultats_vide');
      	self.naces = []

      	self.naces.push({
      		selectable: false,
      		childs_open: true,
      		data: {title: label, id: '_'+Date.now()},
      		childs: self.filtered_naces,
      		last_childs_ids: []
      	})
      }
    },
    filteredNace(naces){
    	let self = this

    	for (var i in naces) {
	      if(self.regexp.test(naces[i]['data']['code']+' '+naces[i]['data']['titre']+' '+naces[i]['data']['code'])){
	      	for (var j = naces[i].last_childs_ids.length - 1; j >= 0; j--) {
	      		self.qIds.push(naces[i].last_childs_ids[j])
	      	}
	      }

    		if(typeof naces[i].childs != 'undefined'){
    			self.filteredNace(naces[i].childs)
    		}else{
    			if (self.qIds.includes(naces[i]['data']['id']) || self.selectedIds.includes(naces[i]['data']['id'])) {
    				self.filtered_naces.push(naces[i])
    			}
    		}
    	}
    },
    resetFilter(){
    	this.q = '';
      this.naces = this.$store.state.ui.secteurs;
      // this.$store.commit('FILTERS_UPDATE_SECTEURS_IDS', [])
      // this.$store.dispatch('filterCompanies');
      this.$store.dispatch('resetSecteurs');
    },
    checkNaceUtil(baseNaces){
      var self = this;

      _.forEach(baseNaces, function(secteur, secteur_id){
        let check = _.intersection(secteur.last_childs_ids, self.$store.state.entreprises.naces_ids);
        secteur._last_childs_ids = check
        if(check.length || secteur.data.id[0] == '_'){
          secteur.visible = true;

          if(secteur.childs){
            self.checkNaceUtil(secteur.childs); 
          }
        }else{
          secteur.visible = false;
        }

      })
    },
    switchAll(){
      let etat = this.getSecteursCheckAll

      if (etat == 'mid') {
        this.$store.dispatch('resetSecteurs');
      }else if (etat == 'on') {
        this.$store.dispatch('disabledSecteurs');
      }else{
        this.$store.dispatch('resetSecteurs');
      }
    }
	},
	watch:{
    q: function(){
      this.debounceQ()
    }
  },
	computed:{
		getInitNaces(){
			return this.$store.getters.getSecteurs
		},
		getNaces(){
      //AS--> A conserver pour la réactivité
      this.$store.state.entreprises.naces_ids;
			this.checkNaceUtil(this.naces);
      return this.naces;
		},
		getCloseAll(){
			return this.closeAll
		},
    getSecteursCheckAll(){
      let self = this;
      let ids_in = []
      let last_childs_ids = _.compact(self.$store.state.entreprises.naces_ids)

      if(typeof last_childs_ids == 'undefined') last_childs_ids = []

      ids_in = _.intersection(_.compact(self.$store.state.filters.filters.secteurs_ids), last_childs_ids)

      let stat = '';
      if (ids_in.length > 0) {
        stat = ids_in.length == last_childs_ids.length ? 'on' : 'mid';
      }

      return stat;
    }
	},
	components: {
    vueCustomScrollbar,
    Arbo,
    CustomInput,
    FiltreHeader
  }
}