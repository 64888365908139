import axios from '../../common/axios.js'
import vSelect from 'vue-select'
import carousel from 'vue-owl-carousel'
import Contacts from '../blocs/contacts.vue'

export default {
	data(){
		return {
			note: 0,
			note_hover: null,
			stars:[false, false, false, false, false]
		}
	},
	props: ['params'],
	methods:{
		setNote(note, note_hover){
			if(note != null) this.note = note;
			if(note_hover !== false) this.note_hover = note_hover

			for (var i = this.stars.length - 1; i >= 0; i--) {
				if (this.note_hover != null) {
					this.stars[i] = i < this.note_hover;
				}else{
					this.stars[i] = i < this.note;
				}
			}
		},
		send(){
			let self = this
			if(self.note <= 2){
				self.$store.dispatch('open_modal', {code: 'advice', params:{note: self.note}})
			}else{
				let data = new FormData();
				data.append('note', self.note);
				data.append('contact_id', self.params.contact_id);
				data.append('company_id', self.params.company_id);

				axios.post('/plugin_projet/plugin_projet_iannuaire/set_note', data)
					.then(function (response) {
						if(self.params.modal_contact){
							self.$store.dispatch('open_modal', {code: 'contact', params:self.params})
						}else{
							self.$store.dispatch('close_modal')
						}
					}).catch(function (error) {

					})
			}
		}
	},
	computed:{
		getStars(){
			this.note_hover
			this.note
			return this.stars
		}
	},
	components: {}
}