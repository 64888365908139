import { render, staticRenderFns } from "./_variables.vue?vue&type=template&id=6e8208c0"
import script from "./_variables.js?vue&type=script&lang=js&external"
export * from "./_variables.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports