import axios from '../../common/axios.js'
import vSelect from 'vue-select'
import carousel from 'vue-owl-carousel'
import Contacts from '../blocs/contacts.vue'

export default {
	data(){
		return {
			loading: true,
			choix: false,
			contacts: false,
			selected_contact: null,
			message: '',
			error_contact: false,
			error_message: false,
			form_send: false
		}
	},
	props: ['params'],
	created(){
		let self = this
		axios.get('/plugin_projet/plugin_projet_iannuaire/get_entreprise_contacts/'+self.params.company_id)
			.then(function (response) {
				self.contacts = response.data
				self.set_selected()
				self.loading = false
			}).catch(function (error) {

			})
	},
	mounted(){

	},
	watch:{
		params: function(val){
			this.set_selected()
		}
	},
	methods:{
		set_selected(){
			if(this.params.contact_id){
				let options = this.getOptions
				let contact_id = this.params.contact_id
				for(let i in options){
					if(options[i].value == contact_id){
						this.selected_contact = options[i]
						break
					}
				}
			}
		},
		send_form(){
			let self = this

			self.error_contact = false;
			self.error_message = false;
			
			try{
				let contact_id = _.isNull(self.selected_contact) ? null : self.selected_contact.value
				if(!_.isInteger(contact_id)){
					self.error_contact = true;
					throw "contact_id";
				}

				let message = self.message
				if(message == ''){
					self.error_message = true;
					throw "message";
				}

				let company_id = self.params.company_id

				let data = new FormData();
				data.append('contact_id', contact_id);
				data.append('message', message);
				data.append('company_id', company_id);
				axios.post('/plugin_projet/plugin_projet_iannuaire/send_mail', data)
					.then(function (response) {
						self.$store.dispatch('update_companies_calls', company_id)
						self.form_send = true;
						self.selected_contact = null;
						self.message = '';
						setTimeout(function(){
							self.form_send = false;
						}, 2000)
						
					}).catch(function (error) {

					})
			}catch(error){
			}
		}
	},
	computed:{
		getContacts(){
			return this.contacts
		},
		getOptions(){
			let options = []
			for(let i in this.contacts){
				options.push({value:this.contacts[i].id, label: this.contacts[i].nom+' '+this.contacts[i].prenom})
			}
			return options
		},
		getCompanyId(){
			return this.params.company_id
		},
		getEmail(){
			let email = false

			for (let i in this.contacts) {
				if (this.contacts[i].email) {
					email = this.contacts[i].email
					break;
				}
			}
			return email
		}
	},
	components: {
		vSelect,
		carousel,
		Contacts
	}
}
