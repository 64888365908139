import _ from 'lodash'
import Teleport from 'vue2-teleport';

/*

settings : {
	menu: {
		picto: '',
		label: '',
		arrow: false,
		bg_color: null
		color: null
	},
	sousmenu: {
		class: '',
 		list: [
 			{
 				picto: '',
				label: '',
				action: ''
				viewBox: '0 0 48 48',
				width: 18,
				height: 18
 			},
 			{
 				picto: '',
				label: '',
				action: ''
 			}
 		]
	}
}

*/

export default {
	data() {
		return {
			open             : false,
			timestamp_opened : null,
			left             : 'auto',
			right            : 'auto',
			top              : 'auto',
			bottom           : 'auto',
			visible          : false
		}
	},
	props: ['settings', 'context', 'autoclose', 'droite'],
	props: {
		settings: {
			type: Object,
			default: function(){
				return {
					menu: {
						picto: '',
						label: ''
					},
					sousmenu: {
						list: []
					}
				}
			}
		},
		context: {
			type: Object,
			default: function(){
				return {}
			}
		},
		autoclose: {
			type: Boolean,
			default: true
		},
		droite: {
			type: Boolean,
			default: false
		},
		active: {
			type: Boolean,
			default: true
		}
	},
	mounted(){
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector("body").addEventListener('click', this.close_modal);
	},
	beforeDestroy: function() {
		document.querySelector("body").removeEventListener('click', this.close_modal);
	},
	created(){
		
	},
	watch:{
		"$store.state.ui.menu_sous_menu_timestamp_opened": function(after, before){
			if(after == this.timestamp_opened){
				this.open = true
			}else{
				this.open = false
			}
		}
	},
	methods: {
		switch_modal(event) {
			if(this.active){
				if(this.open){
					this.close_modal()
				}else{
					this.open_modal(event.target)
				}
			}
		},
		open_modal(target){
			let time = new Date().getTime();
			this.timestamp_opened = time;
			this.$store.commit('UI_SET_MENU_SOUS_MENU_TIMESTAMP_OPENED', time)
			this.placeModal(target)
		},
		close_modal() {
			this.open = false
		},
		action(sousmenu){
			if(this.autoclose) this.close_modal()
			sousmenu.action(this.context)
		},
		placeModal(target){

			this.top     = '0px'
			this.left    = '0px'
			this.right   = 'auto'
			this.bottom  = 'auto'
			this.visible = 'hidden'

			//AS--> On cherche le bouton le plus proche
			if(target.tagName != 'BUTTON'){
				target = target.closest('button')
			}

			setTimeout(() => {
				//AS--> Récupère la taille du menu
				let menu_width  = this.$refs.menu_sous_menu_liste.clientWidth
				let menu_height = this.$refs.menu_sous_menu_liste.clientHeight

				//AS--> On récupère la position du bouton
				let rect = target.getBoundingClientRect();
				let top = rect.top;
				let left = rect.left;

				//AS--> On récupère la taille du bouton
				let width = rect.width;
				let height = rect.height;

				let bottom = top + height;
				let right = left + width;

				this.top = bottom + 10 + 'px';

				if(this.droite){
					this.left = left + width - menu_width + 'px';
				}else{
					this.left = left + 'px';
				}

				let menu_top = this.top.replace('px', '') * 1;

				//AS--> Si le menu déborde de la fenêtre actuelle, on le place au-dessus
				if((menu_top + menu_height) > window.innerHeight){
					this.top = top - menu_height - 10 + 'px';
				}

				this.visible = 'visible'

			}, 10)

		}
	},
	computed:{
		buttonStyle(){
			let style = {}

			if(this.settings.menu?.bg_color) style['background-color'] = this.settings.menu.bg_color
			if(this.settings.menu?.color) style['color'] = this.settings.menu.color

			return style
		},
		style(){
			return {
				top: this.top,
				left: this.left,
				right: this.right,
				bottom: this.bottom,
				visibility: this.visible
			}
		},
		listClass(){
			let _class = this.settings.sousmenu?.class ? this.settings.sousmenu.class.split(' ') : []
			_class.push('liste')
			return _class
		}
	},
	components:{
		Teleport
	}
}