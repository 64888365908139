export default {
	data(){
		return {
			state: 'initial'
		}
	},
	props: {
		promise: {
			type: Promise|null
		},		
		reset_time: {
			type: Number,
			default: 0
		},
		initial: '',
		pending: '',
		success: '',
		error: '',
	},
	created(){},
	watch: {
		promise: {
			immediate: true,
			handler(new_promise, old_promise){
				let self = this
				if (new_promise != old_promise){
					if (typeof new_promise != 'object'){
						self.state = 'initial';
					} else{
						self.state = 'pending'
						self.promise.then(() => {
							self.state = 'success';
						}).catch(() => {
							self.state = 'error';
						}).finally(() => {
							//Si on a un reset_time, on remet le state à initial après un certain temps
							if(self.reset_time > 0){
								setTimeout(() => {
									self.state = 'initial';
								}, self.reset_time)
							}
						})
					}
				}
			}
		}
	},
	methods: {
		getState(){
			return 'state-'+this.state;
		}
	},
	computed:{
		
	},
	components:{
		
	}
}