import axios from '../axios'
import Defaut from '@/assets/logo.png'

export default {
	data() {
		return {
			src: null,
			reader: new FileReader()
		}
	},
	props: ['image','default'],
	mounted(){
		this.loadImage()
	},
	watch:{
		image:function(){
			this.loadImage()
		}
	},
	methods:{
		loadImage(){
			let self = this

			let image = this.image
			if(!image && this.default) image = this.default

			if(image){

				//AS--> Prépare le reader
				this.reader.addEventListener("load", function () {
					this.src = this.reader.result;
				}.bind(this), false);

				//AS--> Cas de l'image sous forme d'objet
				if(typeof image == 'object' && image.type && image.type.substring(0,6) == 'image/'){
					this.reader.readAsDataURL( image );
				}

				//AS--> Cas de l'image sous forme d'url d'API
				if(typeof image == 'string' && image.substring(0,8) == 'api=inex'){
					axios.get(image, {responseType: 'blob'}).then(function(response){
						self.reader.readAsDataURL( response.data );
					})
				}

				//AS--> Cas de l'image sous forme de data
				if(typeof image == 'string' && image.substring(0,11) == 'data:image/'){
					this.src = image
				}

				//AS--> Cas de l'image sous forme d'url
				if(typeof image == 'string' && image.substring(0,4) == 'http'){
					this.src = image
				}

			}

		}
	},
	components:{
		Defaut
	}
}