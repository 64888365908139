import { render, staticRenderFns } from "./results_page.vue?vue&type=template&id=563a6500"
import script from "./results_page.js?vue&type=script&lang=js&external"
export * from "./results_page.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports