import SaveSearch from './modals/save_search.vue';
import Contact from './modals/contact.vue';
import AlertSubscription from './modals/alert_subscription.vue';
import AlertCategorieDechet from './modals/alert_categorie_dechet.vue';
import Advice from './modals/advice.vue';
import AdviceStars from './modals/advice_stars.vue';
import ExportList from './modals/export_list.vue';
import ExportMatieresOk from './modals/export_matieres.vue';
import Intro from './modals/intro.vue';
import UploadImage from './modals/upload_image.vue';
import Matiere from './modals/matiere.vue';
import EditionStatuses from './modals/statuses.vue';
import ParcelleProprietaireContact from './modals/parcelle_proprietaire_contact.vue';
import PendingApi from './modals/pending_api.vue';

export default {
	methods: {
		close_modal(event) {
			if (Object.values(event.target.classList).indexOf('c-modal') != -1) {

				//AS--> Si on est dans un sourcing de fonctionnement 'variables' et qu'on n'est pas connecté et que la modale ouverte est celle de connexion, on empêche sa fermeture
				if (this.$store.state.ui.modal == 'connection' && !this.$store.state.user.user.nom) return;

				this.$store.commit('UI_SET_MODAL', { modal: null });
			}
		}
	},
	components: {
		SaveSearch,
		Contact,
		AlertSubscription,
		Advice,
		AdviceStars,
		ExportList,
		AlertCategorieDechet,
		Intro,
		UploadImage,
		Matiere,
		ParcelleProprietaireContact,
		ExportMatieresOk,
		PendingApi,
		EditionStatuses
	}
}