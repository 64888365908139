/*
Ouvre une modale qui permet de choisir un fichier image
Affiche l'aperçu du fichier sélectionné
A la validation du formulaire, si l'image a la bonne extension, on enregistre ses données dans ui.upload_image qu'il sera possible ensuite de récupérer dans le composant appelant. 

Récupération de l'image pour upload :
let formData = new FormData();
formData.append("image", this.$store.state.ui.upload_image);

*/

import ImageAuto from '../../common/ui/image_auto.vue'

export default {
	data(){
		return {
			image: null,
			imagePreview: null,
			showPreview: false
		}
	},
	created(){
		//AS--> On vide l'image potentiellement stockée dans le store
		if(this.checkImage(this.image)) this.$store.commit('UI_UPLOAD_IMAGE', null);
	},
	methods:{
		submitForm(){
			//AS--> On met à jour l'image stockée dans le store si le check est bon
			if(this.checkImage(this.image)) this.$store.commit('UI_UPLOAD_IMAGE', this.image);

			//AS--> et on ferme la modale
			this.$store.commit('UI_SET_MODAL', {modal: null});

		},
		onFileChange(event){
			
			this.image = event.target.files[0];

			let reader  = new FileReader();
			this.showPreview = false;
			this.imagePreview = null;

			/*
			Add an event listener to the reader that when the file
			has been loaded, we flag the show preview as true and set the
			image to be what was read from the reader.
			*/
			reader.addEventListener("load", function () {
				this.showPreview = true;
				this.imagePreview = reader.result;
			}.bind(this), false);

			
			if(this.checkImage(this.image)) reader.readAsDataURL( this.image );
		},
		checkImage(image){
			return image && /\.(jpe?g|png|gif)$/i.test( image.name );
		},
		editionOff(){
			this.$store.commit('UI_SET_MODAL', {modal: null});
		}
	},
	computed:{
		isErreur(){
			return this.image && !this.checkImage(this.image);
		},
		getSrc(){
			return this.imagePreview ? this.imagePreview : this.valeur
		}
	},
	components:{
		ImageAuto
	}
}
