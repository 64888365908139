import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import CustomSlider from '../blocs/custom_slider.vue'
import _ from 'lodash'

export default {
	data() {
		return {
			settings: {
				// maxScrollbarLength: 60
			},
			carac_opened: []
		}
	},
	props: ['params','menu_index'],
	watch: {
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
		}
	},
	methods: {
		reset_filters() {
			// this.$store.dispatch('init_custom_filters');
			this.$store.dispatch('resetFilterCaracteristiques');
		},
		set_custom_filters(filter_id, value) {
			let filter = { filter_id, value }
			this.$store.dispatch('setCaracteristiques', filter)
		},
		is_check(filter_id, value) {
			let caracteristiques = this.$store.state.filters.caracteristiques
			return caracteristiques[filter_id].includes(value)
		},
		switch_carac(carac_id) {
			if(this.carac_opened.includes(carac_id)){
				this.carac_opened = this.carac_opened.filter(a => a !== carac_id)
			}else{
				this.carac_opened.push(carac_id);
			}
			// this.$store.dispatch('switchFilterCarac', carac_id);
		},
		allCarac(carac_id){
			this.$store.dispatch('setCaracteristiques', {filter_id: carac_id, check_all: true})
		},
		noCarac(carac_id){
			this.$store.dispatch('setCaracteristiques', {filter_id: carac_id, check_all: false})
		},
		getCaracCheck(carac_id) {
			return this.$store.state.filters.caracteristiques_ids.includes(carac_id)
		},
		getI18n(custom_filter, value){
			let self = this;
			return self.$store.getters.getNomenclatureValue('caracteristics', custom_filter.id, value);
		}
	},
	computed:{
		getCustomFilters() {
			let self           = this;
			let custom_filters = this.$store.state.ui.custom_filters;
			let dechets        = this.$store.state.ui.dechets
			let main_dechet_id = this.$store.state.filters.filters.main_dechet_id
			let filtres_ids    = false

			_.forEach(dechets, function(dechet) {
				if (dechet.data.id == main_dechet_id) {
					if (Object.values(dechet.filtres_ids).length != 0) {
						filtres_ids = dechet.filtres_ids;
					}
				}
			})

			let _filtres = []
			if(!self.params.caracteristics || !self.params.caracteristics.length){
				_.each(filtres_ids, function(filtre_id, k){
					if (typeof custom_filters[filtre_id] != 'undefined') {
						_filtres.push(custom_filters[filtre_id])
					}
				})
			}else{
				self.params.caracteristics.forEach(function(carac_id){
					_.each(filtres_ids, function(filtre_id, k){
						if (typeof custom_filters[filtre_id] != 'undefined' && custom_filters[filtre_id]['id'] == carac_id) {
							_filtres.push(custom_filters[filtre_id])
						}
					})
					
				})
			}
			return _filtres
		},
		hasCustomFilters() {
			return _.keys(this.getCustomFilters).length
		},
	},
	components: {
		VueSlider,
		CustomSlider
	}
}