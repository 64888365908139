<template>
  <div class="slider_filter" v-if="!minMaxEqual">
    <div class="label" :class="{active: active}">
      <div class="check_bloc" @click="switch_active()">
        <icon-base viewBox="0 0 18 18" :class="['custom_check', {on: active}]" ><icon-selected-dark /></icon-base>
        <span class="title">{{getLabel}}</span>
      </div>
      <span class="unite">
        <template v-if="!active">
          <template v-if="showRangeOnInactive">
            <big v-if="fromToEqual">{{from}}</big>
            <big v-else-if="!active">{{$t('slider_unite_from')}} {{from}} {{ $t('slider_unite_to') }} {{to}}</big>
          </template>
        </template>
        <template v-else>
          {{ $t('slider_unite_from') }} <input v-model="from" class="input-slider no-arrow" type="number"><br>
          {{ $t('slider_unite_to') }} <input v-model="to" class="input-slider no-arrow" type="number">
        </template>
      </span>
    </div>
    <vue-slider ref="slider" :style="getSliderStyle" v-if="active" v-model="getValue" :lazy="true" :enableCross="false" dotSize="27" :marks="marks" :min="_min" :max="_max" :contained="true" :tooltipFormatter="formatData"></vue-slider>
  </div>
</template>

<script>
import _ from 'lodash'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Debounce from 'lodash/debounce'
import kebabCase from "lodash/kebabCase";
// import get from "lodash/get";
export default {
  name: "Input",
  data() {
    return {
      active: false,
      _min: 0,
      _max: 1,
      _isFloat: false,
      from: null,
      to: null,
    };
  },
  props: {
    value: null,
    options: Object,
    errors: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    is_float: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showRangeOnInactive: {
      type: Boolean,
      default: false,
    },
    field: String,
    label: String,
    params: {
      type: Object,
      default: {}
    },
  },
  created() {
    this.init()
  },
  mounted() {},
  watch: {
    value: function(after, before){
      //AS--> Réinitialisation des filtres par l'extérieur. Si la value passe à undefined, on ferme le filtre
      if(typeof after == 'undefined'){
        this.init()
        this.active = false
      }
    },
    from: function(after, before){
      if(this.active) this.updateValue([this.from, this.to])
    },
    to: function(after, before){
      // if(this.active) this.updateValue([this.from, this.to])
    },
    params: function(after, before){

      if(this.active){
        //AS--> Traite les min
        if(after.min < this._min){
          this._min = after.min
          //AS--> Si l'ancienne valeur était le min de l'échelle, on garde le min
          if(this.from == before.min) this.from = after.min
          //AS--> On set la valeur recadrée
          if(this.$refs.slider) this.$refs.slider.setValue([this.from, this.to])
          this.$emit('input', [_.clone(this.from), _.clone(this.to)])
        }else if(after.min > this._min){
          //AS--> Si le nouveau min est suppérieur au from actuel, on recadre le from à la valeur actuelle
          if(this.from < after.min) this.from = after.min
          //AS--> On set la valeur recadrée
          this.log(this.$refs.slider, [this.from, this.to]);
          if(this.$refs.slider) this.$refs.slider.setValue([this.from, this.to])
          this.$emit('input', [_.clone(this.from), _.clone(this.to)])
          //AS--> On recadre le min du slider
          this._min = after.min
        }else{
          this._min = after.min
        }

        //AS--> Traite les max
        if(after.max > this._max){
          this._max = after.max
          //AS--> Si l'ancienne valeur était le max de l'échelle, on garde le max
          if(this.to == before.max) this.to = after.max
          //AS--> On set la valeur recadrée
          if(this.$refs.slider) this.$refs.slider.setValue([this.from, this.to])
          this.$emit('input', [_.clone(this.from), _.clone(this.to)])
        }else if(after.max < this._max){
          //AS--> Si le nouveau max est suppérieur au to actuel, on recadre le to à la valeur actuelle
          if(this.to > after.max) this.to = after.max
          //AS--> On set la valeur recadrée
          if(this.$refs.slider) this.$refs.slider.setValue([this.from, this.to])
          this.$emit('input', [_.clone(this.from), _.clone(this.to)])
          //AS--> On recadre le max du slider
          this._max = after.max
        }else{
          this._max = after.max
        }
      }

    }
  },
  methods: {

    init(){
      this._min     = this.getMin
      this._max     = this.getMax
      this._isFloat = this.isFloat
      this.from     = this.getMin
      this.to       = this.getMax
    },

    //AS--> Transforme le nom du champ en kababcase pour en faire une class
    _kebabCase(field) {
      return kebabCase(field);
    },

    //AS--> Active l'affichage des choix, ou masque les choix et gère l'initialisation de la value en fonction
    switch_active(){
      this.active = !this.active
      if(!this.active){
        this.init()
        this.updateValue(null)
      }else{
        this.init()
        this.updateValue([this._min, this._max])
      }
    },

    //AS--> Mais à jour la value de référence en la passant au parent. La value interne sera mise à jour automatiquement
    updateValue(vals){
      this.$emit('input', vals)
    },

    //AS--> Vérifie qu'une valeur est choisie ou non
    is_check_val(val){
      if(this.isMultiSelect){
        return this.value.includes(val)
      }else{
        return this.value === val
      }
    },

    //AS--> Formate les valeurs retournées
    formatData(value){
      if (this._isFloat) {
        return value/100;
      } else {
        return value;
      }
    },

    log(val1, val2){
      console.log(this.getLabel, val1, val2);
    }

  },
  computed: {
    getErrors() {
      return get(this.errors, this.field);
    },

    //AS--> Renvoie le field en fonction des différentes options de paramétrage
    getField(){
      if(this.params.code) return this.params.code
      return this.field
    },

    //AS--> Renvoie le label en fonction des différentes options de paramétrage
    getLabel(){
      if(this.params.label) return this.params.label
      if(this.params.name) return _.cloneDeep(this.params.name)
      return this.label
    },

    //AS--> Renvoie le min en fonction des différentes options de paramétrage
    getMin(){
      this.params
      if(this.params.min) return this.params.min
      return this.min
    },

    //AS--> Renvoie le min en fonction des différentes options de paramétrage
    getMax(){
      this.params
      if(this.params.max) return this.params.max
      return this.max
    },

    //AS--> Renvoie le min en fonction des différentes options de paramétrage
    isFloat(){
      if(this.params.is_float) return this.params.is_float
      return this.is_float
    },

    //AS--> Vérifie si les valeurs choisie sont égales
    fromToEqual(){
      return this.from === this.to
    },


    getSliderStyle(){
      return {display: this.minMaxEqual ? 'none' : 'inherit'}
    },

    minMaxEqual(){
      return this._min == this._max
    },

    getValue:{
      get: function () {
        return [Number.parseFloat(this.from), Number.parseFloat(this.to)]
      },
      set: function (value) {
        this.from = value[0]
        this.to   = value[1]
        this.updateValue(value)
      }
    },

    marks(){
      this.params   //AS--> Nécessaire pour garder la réactivité
      let marks = [this._min, this._max];
      this._isFloat = false;
      if(this._min == 0 && this._max == 1){
        this._isFloat = true;
        marks = {0: {label:0},100: {label: '1'}}
        this._max = 100;
      }

      return marks
    },
  },
  components: {
    VueSlider
  },
};
</script>