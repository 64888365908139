import Loading from './loading_inex.vue'

export default {
	data() {
		return {
			
		}
	},
	props: {
		loading: {
			type: Boolean | Number,
			default: false
		},
		couleur: {
			type: String,
			default: 'vert'
		}
	},
	created(){
		console.log('modale.js created --')
	},
	methods: {
		
	},
	computed:{
	},
	components:{
		Loading
	}
}