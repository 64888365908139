import TableLine from './table_line.vue'
import Throttle from 'lodash/throttle'
import _ from 'lodash'
import Debounce from 'lodash/debounce'
import infiniteScroll from 'vue-infinite-scroll'
import axios from '../common/axios.js'

import Colonne_familles_header from './colonnes/colonne_familles_header.vue'
import Colonne_site_header from './colonnes/colonne_site_header.vue'
import Colonne_nom_header from './colonnes/colonne_nom_header.vue'
import Colonne_secteur_header from './colonnes/colonne_secteur_header.vue'
import Colonne_commune_header from './colonnes/colonne_commune_header.vue'
import Colonne_nouveau_header from './colonnes/colonne_nouveau_header.vue'
import Colonne_ip_header from './colonnes/colonne_ip_header.vue'
import Colonne_distance_header from './colonnes/colonne_distance_header.vue'
import Colonne_dechets_header from './colonnes/colonne_dechets_header.vue'
import Colonne_dechets_total_header from './colonnes/colonne_dechets_total_header.vue'
import Colonne_variables_header from './colonnes/colonne_variables_header.vue'
import Colonne_capacites_header from './colonnes/colonne_capacites_header.vue'
import Colonne_identifiant_header from './colonnes/colonne_identifiant_header.vue'
import Colonne_telephone_header from './colonnes/colonne_telephone_header.vue'
import Colonne_statut_header from './colonnes/colonne_statut_header.vue'
import Colonne_comment_header from './colonnes/colonne_comment_header.vue'

export default {
	data() {
    return {
      company_line_height : 54,
      // busy: true
    }
  },
  props: ['index', 'entreprise'],
  created(){
    // this.$store.commit('UI_SET_PLAGE', {min:0, max:50});
  },
  mounted(){
    let table_elem = document.getElementById("table_entreprises")
    if (table_elem) {
      table_elem.addEventListener('scroll', Throttle(this.scrollY, 250, {leading: false}));

      this.fixeLeftCols()

    }


    //AS--> Active l'infinite-scroll en décalé
    // var self = this;
    // setTimeout(function(){
    //   self.busy = false;
    // },4000)
  },
  beforeDestroy: function () {
    // let table_elem = document.getElementById("table_entreprises")
    // table_elem.removeEventListener('scroll', Throttle(this.scrollY, 500, {leading: false}));
  },
  methods: {
    fixeLeftCols(){
      let table_elem = document.getElementById("table_entreprises")
      if (table_elem) {

        //AS--> ===========================================
        //AS--> Traite la  ligne des en-têtes
        let colonnes = table_elem.getElementsByClassName('head')[0].children

        if(colonnes.length){
          
          let col_name_found = false
          let largeurs       = []

          _.forEach(colonnes, function(colonne, index){
            //AS--> Si la colonne de nom est déjà passée, on ne fait rien
            if(col_name_found) return

            //AS--> Si c'est la colonne de nom, on s'arrête là
            if(colonne.getAttribute('class') == 'col-nom' || colonne.getAttribute('class') == 'col-company') col_name_found = true;

            //AS--> Largeur des colonnes déjà ajoutées
            let initialValue = 0
            let largeurTotal = largeurs.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              initialValue
            );

            //AS--> Largeur de la colonne en cours
            let largeur = colonne.clientWidth
            largeurs.push(largeur)

            //AS--> Set le style de la colonne
            let style = 'position:sticky; left:'+largeurTotal+'px'
            colonne.setAttribute('style', style+'; z-index:2')


            //AS--> ===========================================
            //AS--> Traite les lignes de valeurs
            let lignes = document.getElementsByClassName('item-company')

            if(lignes.length){
              _.forEach(lignes, function(ligne){
                let _colonnes = ligne.children
                _colonnes[index].setAttribute('style', style+'; z-index:1')
              })
            }
            

          })
        }

      }
    },
    switchCompaniesIds(){
      this.$store.dispatch('switch_all_companies_ids')
    },
    scrollY(e){
      if(e) e.stopPropagation()
      if(document.querySelector("#table_entreprises .item-company")){
        let scrollPosition       = document.getElementById("table_entreprises").scrollTop;
        let scrollHeight         = document.getElementById("table_entreprises").offsetHeight;
        this.company_line_height = document.querySelector("#table_entreprises .item-company").offsetHeight;
        if(scrollPosition == 0) scrollPosition = 1;
        let min = Math.ceil(scrollPosition / this.company_line_height) - 5;
        if(min%2 > 0) min = min-1;
        if(min < 0) min = 0;
        let max = Math.ceil((scrollPosition+scrollHeight) / this.company_line_height) + 5;

        this.$store.commit('UI_SET_PLAGE', {min, max});
      }
    },
    startBeforeFilterCompanies: Debounce(function(e) {
      // this.$store.dispatch('beforeFilterCompanies')
    },800),
    loadMore() {
      if(!this.$store.getters.hasFiltersQ){
        this.$store.dispatch('getEntreprisesAjax', this.$store.state.entreprises.raw.length);
      }
    },
    rechercheIgnoreSites(){
      // console.log(this.$store.state.ui.recherche_supprIds);
      let self = this;
      this.$dialog.confirm(this.$t('filter_fav_confirm'), {loader: true})
      .then(dialog => {
        let entreprises_ids = this.$store.state.ui.recherche_supprIds

        let recherche = _.cloneDeep(self.$store.state.ui.recherche)
        let ignore = typeof recherche['ignore'] != 'undefined' && recherche['ignore'] != null ? recherche['ignore'] : [];
        entreprises_ids.forEach(function(entreprise_id){
          //AS--> Si ignore ne contient pas encore cette entreprise, on l'ajoute
          if(ignore.indexOf(entreprise_id) < 0){

            //AS--> Modifie les filtres dans la recherche
            ignore.push(entreprise_id);

          }
        })
        recherche.recherche.ignore = ignore
        recherche.update = true

        //AS--> Demande d'ignorer ce site de la recherche
        axios.post('api=inex/users/{user_email}/views/{view_code}/searches/', recherche)
        .then(function (response) {
          console.log(response);

          //AS--> Ferme la boîte de dialogue
          dialog.close();

          //AS--> Récupère toutes les recherches
          let recherches = _.cloneDeep(self.$store.state.user.recherches);
          recherches.forEach(function(_recherche){

            //AS--> Si c'est la recherche en cours d'affichage
            if(_recherche.nom == self.$store.state.ui.recherche.nom) _recherche = recherche

          })

          //AS--> Met à jour le référentiel des recherches
          self.$store.commit('USER_SET_RECHERCHES', recherches);

          //AS--> Met à jour la recherche en cours et relance donc une requête avec le nouveau filtre
          self.$store.dispatch('setSaveSearch', recherche);


        //   // $store.dispatch('rechercheSupprSite', entreprise.id)
        }).catch(function (error) {
          //AS--> Ferme la boîte de dialogue
          dialog.close();
        })
      });
    }
  },
  computed:{
    updateFilters(){
      let filters = this.$store.state.filters.filters
      // let secteurs_ids = this.$store.state.filters.secteurs_ids
      // let pertinance = this.$store.state.filters.pertinance
      // let contact = this.$store.state.filters.contact
      // let custom_filters = this.$store.state.filters.custom_filters
      this.startBeforeFilterCompanies()
      return '';
    },
    isChecked(){
      let ids_in = _.intersection(this.$store.state.entreprises.companies_ids, this.$store.state.ui.selected_companies)
      let _class = ''
      if(this.$store.state.ui.selected_companies.length > 0){
        _class = this.$store.state.ui.selected_companies.length == this.$store.state.entreprises.total ? 'on' : 'mid'
      }
      return _class
    },
    isVisibleInfo(){
      return this.$store.state.filters.filters.q == ''
    },
    // isVisible(){
    //   return this.$store.state.entreprises.companies_ids.includes()
    // },
    getSortDirection(){
      return this.$store.state.filters.filters.order[1] == 1
    },
    getEntreprisesAffichees(){
      let self = this
      if(this.$store.state.ui.content == 'favoris'){
        return _.values(this.$store.state.user.favoris);
      }else{
        let plage = this.$store.state.ui.plage;
        setTimeout(function(){
          self.fixeLeftCols()
        }, 100)
        //AS--> Filtres les entreprises dans la zone d'affichage
        return this.$store.state.entreprises.raw.slice(plage.min, plage.max+1);
      }
    },
    getPaddings(){
      if(this.$store.state.ui.content == 'favoris') return {};
      
      //AS--> Calcul du nombre total d'entreprises de l'échantillon
      let total  = this.$store.state.entreprises.raw.length;

      //AS--> Récupère l'index de la première à afficher
      let min    = this.$store.state.ui.plage.min;
      //AS--> Calcul de la hauteur du bloc de calage
      let height_top = 0;
      if(total > 0 && min > 0) height_top = min * this.company_line_height;

      //AS--> Récupère l'index de la dernière à afficher
      let max = this.$store.state.ui.plage.max;
      //AS--> Calcul de la hauteur du bloc de calage
      let height_bottom = 0;
      if(max < total) height_bottom = (total - max -1) * this.company_line_height;

      //AS--> Calcul de la hauteur du bloc de calage
      return {'padding-top': height_top+'px', 'padding-bottom': height_bottom+'px'};
    },
    isBusy(){
      // return this.busy || this.$store.state.entreprises.raw.length >= this.$store.state.entreprises.total_liste || this.$store.state.ui.content != 'result' || this.$store.state.ui.loading;
      return this.$store.state.entreprises.raw.length >= this.$store.state.entreprises.total_liste || this.$store.state.ui.content != 'result' || this.$store.state.ui.loading;
    },
    getColumns(){
      let variables = [];
      _.forEach(this.$store.state.ui.sourcing.variables, function(var_infos, var_field){
        var_infos['field'] = var_field;
        variables.push(var_infos);
      });
      variables = _.orderBy(variables, ['ordre'], ['asc']);
      return variables;
    }
  },
	components: {
    TableLine,
    Colonne_familles_header,
    Colonne_site_header,
    Colonne_nom_header,
    Colonne_secteur_header,
    Colonne_commune_header,
    Colonne_nouveau_header,
    Colonne_ip_header,
    Colonne_distance_header,
    Colonne_dechets_header,
    Colonne_variables_header,
    Colonne_capacites_header,
    Colonne_identifiant_header,
    Colonne_telephone_header,
    Colonne_dechets_total_header,
    Colonne_statut_header,
    Colonne_comment_header
  },
  directives: {
    infiniteScroll
  }
}