import _ from 'lodash'
import Debounce from 'lodash/debounce'
import CalculsDechets from '@/common/mixins/CalculsDechets.js'

export default {
  mixins: [CalculsDechets],
	data() {
    return {
      // marks: null,
      all_dechets_ids: [],
      all_dechets_ids_entreprises: [],
      nbr_dechets: 0,
      bg: [
        '#009AFF',
        'linear-gradient(180deg, #00CEA8 0%, #07CE6F 100%)',
        '#ff8100',
        '#6B61D2',
        'linear-gradient(270deg, #07CE6F 0%, #00CEA8 100%)',
        '#F55046',
        '#bd66e0',
        'linear-gradient(90deg, #FE6370 0%, #FE8C6F 100%)',
        '#007ED1',
        '#D86D00',
        '#9F97F4',
        '#CD3329',
        '#E39EFF',
        '#0065A7',
        '#B35A00',
        '#392FA7',
        '#A4221A',
        '#8836A9',
        '#29AAFF',
        '#FF9327',
        '#FF9B95',
        '#731997',
        '#53BBFF',
        '#CFD8EC'
      ]
    }
  },
  props: ['entreprise', 'column'],
  created(){
    let all_dechets_ids = []
    _.forEach(this.column.levels, function(col){
      all_dechets_ids = _.concat(all_dechets_ids, col.dechets_ids)
    })

    let all_dechets_ids_entreprises = []
    _.forEach(this.entreprise.dechets, function(dechet){
      all_dechets_ids_entreprises = _.concat(all_dechets_ids_entreprises, dechet.id)
    })

    let intersect_ids = _.intersection(all_dechets_ids, all_dechets_ids_entreprises)

    this.all_dechets_ids = all_dechets_ids
    this.all_dechets_ids_entreprises = all_dechets_ids_entreprises
    this.nbr_dechets = intersect_ids.length
  },
  mounted(){
  },
  methods:{
  	getTooltip(item){
      let total = 0
      _.forEach(this.entreprise.dechets, function(dechet){
        if(item.dechets_ids.includes(dechet.id)){
          total += dechet.qty
        }
      })
      return '<span>'+item.nom+'</span><br><strong>'+total+' t</strong>'
    },
    getSubTotal(bar){
      let total = 0
      _.forEach(this.entreprise.dechets, function(dechet){
        if(bar.dechets_ids.includes(dechet.id)){
          total += dechet.qty
        }
      })
      return total;
    },
    getStyle(bar, key){
      let style = {}

      if(this.getTotal == 0){
        let nbr = this.column.levels.length
        style.width = Math.round(100/nbr, 2)+'%'
      }else{

        //RZ--> Calcul du total de la barre
        style.width = (this.getSubTotal(bar)*100/this.getTotal)+'%'
      }

      let bg = this.bg[key]
      if(bg){
        if(bg.substring(0, 15) == 'linear-gradient'){
          style['background-image'] = bg
        }else{
          style['background-color'] = bg
        }
      }

      return style
    },
  },
  watch:{},
  computed:{
    getNbr(){
      return this.nbr_dechets
    },
    getTotal(){

      let qtys = []
      this.entreprise.dechets.map(dechet => {
        if(this.all_dechets_ids.includes(dechet.id)) qtys.push(dechet.qty)
      })

      return this.calculSommeQtyDechetsAvecNull(qtys)
    },
    getBarre(){
      let check = this.entreprise.dechets //AS--> Pour la réactivité sur la modification d'une entreprise
      return this.column.levels
    }
  },
  components:{}
}