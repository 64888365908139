import vueCustomScrollbar from 'vue-custom-scrollbar'
import FiltreLayersGroup from './filtre_layers_group.vue';
import Utils from '../../common/utils.js';
import VueSlider from 'vue-slider-component'

export default {
	mixins: [Utils],
	data() {
		return {
			opened: [],
			legendOpened: [],
			formatter1: '{value}%',
			sliders:{}
		}
	},
	name: 'FiltreLayersGroup',
	props: ['layers'],
	mounted(){
		let self = this

		//AS--> Ouverture par défaut des groupes de calque possédant un calque affiché. 
		if(this.layers.length){
			this.layers.forEach(function(layer, i){
				if(layer && !_.isArray(layer) && layer.list){
					let addOpened = false;
					layer.list.forEach(function(lay){
						if(lay && lay.on) addOpened = true;
					})
					if(addOpened) self.opened.push(i)
				}
			})
		}
	},
	methods: {
		getLegendeStyle(style){
			let _style = {};
			if(style?.fillColor) _style['background-color'] = style.fillColor;
			if(style?.strokeColor) _style['border-color'] = style.strokeColor;
			return !_style['background-color'] && !_style['border-color'] ? false : _style
		},
		getLegendeIcon(style){
			let icon = false
			if(style?.icon){
				icon = style.icon.substring(0, 4) != 'http' ? '/sourcing_icons/'+style.icon : style.icon
			}
			return icon
		},
		getLayerCouleurs(layer){
			if(layer?.type == 'multi'){

				//AS--> Détermine le calque à afficher
				let zone_size = this.$store.state.ui.map_zone_google.areaActif;
				if(typeof zone_size != 'undefined'){
				  let layer_selected = null;
				  layer.layers.forEach(function(layer){
				    if(layer.max == '') layer.max = 99999999999999999999999999999999999;
				    if(layer.max > zone_size && (layer_selected == null || layer.max < layer_selected.max)) layer_selected = layer;
				  })

				  if(layer_selected && layer_selected.couleurs){
				  	//AS--> S'il n'y a qu'une définition de couleur et qu'elle n'a pas de légende
				  	if(layer_selected.couleurs.length == 1 && !layer_selected.couleurs[0].legende) return null;
				  	return layer_selected.couleurs;
				  }else{
				  	return null;
				  }
				}

				return null
			}else{
				return layer.couleurs;
			}
		},
		getSubLayerId(layer){
			if(layer?.type == 'multi'){

				//AS--> Détermine le calque à afficher
				let zone_size = this.$store.state.ui.map_zone_google.areaActif;
				if(typeof zone_size != 'undefined'){
				  let layer_selected = null;
				  layer.layers.forEach(function(layer){
				    if(layer.max == '') layer.max = 99999999999999999999999999999999999;
				    if(layer.max > zone_size && (layer_selected == null || layer.max < layer_selected.max)) layer_selected = layer;
				  })
				  return layer_selected._id
				}

				return null
			}else{
				return layer.layer;
			}
		},
		switchLayerOpened(i){
			if(this.opened.includes(i)){
				this.opened = _.without(this.opened, i);
			}else{
				this.opened.push(i)
			}
		},
		isOpened(i){
			return this.opened.includes(i)
		},
		switchLegendOpened(i){
			if(this.legendOpened.includes(i)){
				this.legendOpened = _.without(this.legendOpened, i);
			}else{
				this.legendOpened.push(i)
			}
		},
		isLegendOpened(i){
			return this.legendOpened.includes(i)
		},
		toggleLayer(layer){
			delete this.sliders[layer]
			this.sliderChange(layer)
			this.$store.dispatch('toggle_layer', layer)
		},
		sliderChange(layer){
			let opacity = typeof this.sliders[layer] == 'undefined' ? null : this.sliders[layer]
			this.$store.dispatch('change_layer_opacity', {'layer_id':layer, 'opacity': opacity})
		},
		layerHasLineOrPolygon(_layer){
			let hasLineOrPolygon = false
			if(this.$store.state.ui.layers_data[_layer] && this.$store.state.ui.layers_data[_layer].features){
				this.$store.state.ui.layers_data[_layer].features.forEach(function(feature){
					if(feature.geometry && feature.geometry.type && ["MultiPolygon", "Polygon", "Line"].includes(feature.geometry.type)) hasLineOrPolygon = true
				})

			}else{
				hasLineOrPolygon = true

			}
			return hasLineOrPolygon;
		},
		hasActiveLayer(list){
			let self   = this
			let active = false
			list.forEach(function(layer){
				if(layer && layer.layer && self.$store.state.ui.layers_opened.includes(layer.layer)) active = true
			})
			return active
		}
	},
	computed:{},
	watch: {},
	components:{
		vueCustomScrollbar,
		VueSlider
	}
}