import axios from '../../common/axios.js'
import Input from '../../common/forms/Input.vue'

export default {
	data(){
		return {
			email: '',
			error: false,
			success: false
		}
	},
	methods:{
		recuppass(){
			var self = this;
			self.error = false;
			axios.post('/plugin_projet/plugin_projet_iannuaire/recuppass', {
				data:{
					Profil: {
						email: self.email
					}
				}
			})
			.then(function (response) {
				if(response.data == 'KO'){
					self.error = true;
					self.success = false;
				}else{
					self.error = false;
					self.success = true;
				}
			}).catch(function (error) {})
		}
	},
	components: {
		Input
	}
}
