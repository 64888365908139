import CompanyDetail from '../blocs/company_detail.vue'

export default {
	data() {
    return {
    	moins6semaines: (Date.now()-6*7*24*60*60*1000)/1000
    }
  },
  props: ['entreprise'],
  methods:{
  	isNew(added){
  		return added > this.$store.state.ui.sourcing.based && added > this.moins6semaines
  	},
	getDateAffich(_date){
		let __date = new Date(_date*1000);
		return __date.getDate() + '/' + (__date.getMonth()+1) + '/' + __date.getFullYear();
	}
  },
  components: {
    CompanyDetail
  }
}