import {MapElementFactory} from 'vue2-google-maps'
import _ from 'lodash'
 
export default MapElementFactory({
  name: 'geojsonLayerEntreprise',
  data() {
    return {
      data: null,
      style: {
        strokeColor: '#123456',
        fillColor: '#987654',
        fillOpacity: 0.1,
        strokeWeight: 2,
        strokeOpacity: 0.5,
        zIndex: 200,
        display: true
      },
      sourcing_styles: {}
    }
  },
  ctr: () => google.maps.Data,
  //// The following is optional, but necessary if the constructor takes multiple arguments
  //// e.g. for GroundOverlay
  // ctrArgs: (options, otherProps) => [options],
  events: [],
 
  // Mapped Props will automatically set up
  //   this.$watch('propertyName', (v) => instance.setPropertyName(v))
  //
  // If you specify `twoWay`, then it also sets up:
  //   google.maps.event.addListener(instance, 'propertyName_changed', () => {
  //     this.$emit('propertyName_changed', instance.getPropertyName())
  //   })
  //
  // If you specify `noBind`, then neither will be set up. You should manually
  // create your watchers in `afterCreate()`.
  mappedProps: {
    // routeIndex: { type: Number },
    // options: { type: Object },
    // panel: { },
    // directions: { type: Object },
    //// If you have a property that comes with a `_changed` event,
    //// you can specify `twoWay` to automatically bind the event, e.g. Map's `zoom`:
    // zoom: {type: Number, twoWay: true}
  },
  // Any other properties you want to bind. Note: Must be in Object notation
  props: {
    zIndex: { type: Number, default: 200 },
  },
  // Actions you want to perform before creating the object instance using the
  // provided constructor (for example, you can modify the `options` object).
  // If you return a promise, execution will suspend until the promise resolves
  beforeCreate (options) {},

  // Actions to perform after creating the object instance.
  afterCreate (dataInstance) {

    //AS--> Stock l'instance de maps.data
    this.dataInstance = dataInstance;

  },

  watch:{
      "$store.state.company.company": function(){
        this.draw()
      },
      "$store.state.company.companyComplementsLoad": function(){
        this.draw()
      }
  },

  computed:{

  },

  methods: {

    draw(){
      let self = this;

      //AS--> Suppression du GeoJson déjà en place si une nouvelle entreprise est définie pour la modale (sinon ne supprime pas le GeoJson)
      if(this.geoJsonData && self.$store.state.company.company.id){
        this.geoJsonData.forEach(function(feature){
          self.dataInstance.remove(feature);
        });
      }
      
      if(this.$store.state.company.company.complement && this.$store.state.company.company.complement.geojson){

        //AS--> Récupère les styles défini dans le sourcing
        if(this.$store.state.ui.sourcing.styles_geojson_entreprise) this.sourcing_styles = JSON.parse(this.$store.state.ui.sourcing.styles_geojson_entreprise);

        //AS--> Redéfini le style par défaut s'il est précisé dans le sourcing
        if(this.sourcing_styles.default) this.style = _.assign(this.style, this.sourcing_styles.default);

        //AS--> Assigne un zIndex
        if(self.zIndex) self.style.zIndex = self.zIndex;

        //AS--> Défini les styles par défaut
        this.dataInstance.setStyle(this.style);

        //AS--> Envoie de données GeoJson à la carte
        this.data = JSON.parse(this.$store.state.company.company.complement.geojson);

        //AS--> Récupère les features interprétée par GoogleMap après les avoir injectées sur la carte
        this.geoJsonData = this.dataInstance.addGeoJson(this.data);

        this.geoJsonData.forEach(function(feature){

          //AS--> Si la feature possède bien un type et qu'un style spécifique est décrit pour ce type
          if(feature.getProperty('type') != undefined && self.sourcing_styles[feature.getProperty('type')] != undefined){

            //AS--> S'il est demandé de ne pas afficher cette feature
            let style = _.clone(self.style);
            style     = _.assign(style, self.sourcing_styles[feature.getProperty('type')]);

            if(style.display === false){
              self.dataInstance.remove(feature);

            }else{
              self.dataInstance.overrideStyle(feature, style);
            }

          }else{
            if(self.style.display === false){
              self.dataInstance.remove(feature);
            }

          }


        });

      }

    }

  }

})