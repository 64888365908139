export default {
	data(){
		return {
		}
	},
	props: ['params'],
	methods:{
		
	},
	computed:{
		hasContenu(){
			return this.$store.state.ui.sourcing.modale && this.$store.state.ui.sourcing.modale.length > 10
		}
	},
	components: {}
}