export default {
	data() {
		return {}
	},
	props: ['params', 'menu_index'],
	watch: {
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
		}
	},
	methods: {
		reset_filters(){
			this.$store.dispatch('resetContacts');
		}
	},
	computed:{},
	components: {}
}