import _ from 'lodash'

import MenuColonneVerte_allfavoris_header from '../menus/menucolonneverte_allfavoris_header.vue'

export default {
	data() {
		return {
			
		}
	},
	methods:{
		hasFilters(menu){
			let self = this;
			let hasFilters = false;
			menu.filters.forEach(function(filter){
				if(
					(typeof self.$store.getters['hasFilters_'+filter.type] == 'function' && self.$store.getters['hasFilters_'+filter.type](filter)) || 
					(typeof self.$store.getters['hasFilters_'+filter.type] == 'boolean' && self.$store.getters['hasFilters_'+filter.type])
				){
					hasFilters = true;
				}
			})
			return hasFilters;
		},
		open_filter(filter_index){
			let show_filters = filter_index == this.$store.state.ui.show_filter_index ? !this.$store.state.ui.show_filters : true
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: show_filters});
			this.$store.commit('UI_SET_FILTER_INDEX', {filter_index: filter_index});
			if (this.$store.state.ui.content != 'result' && this.$store.state.ui.content != 'graph') {
				this.$store.commit('UI_SET_CONTENT', {content: 'result'});
			}
		},
		getClassMenu(menu){
			let _class = 'menu-filtre';
			menu.filters.forEach(function(filter){
				if(filter.type && !['title','separator','spacer'].includes(filter.type)){
					_class += '-'+filter.type;
				}
			})
			return _class;
		}
	},
	components:{
		MenuColonneVerte_allfavoris_header
	}
}