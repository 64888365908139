import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Debounce from 'lodash/debounce'
import CustomSlider from '../blocs/custom_slider.vue'
import _ from 'lodash'

export default {
	data() {
		return {}
	},
	props: ['params'],
	methods: {
		switch_dechets_qty() {
			this.$store.dispatch('switchFilterDechetsQty');
		},
		switch_dechet_qty() {
			this.$store.dispatch('switchFilterDechetQty');
		},
		formatData(value) {
			if (this.is_float) {
				return value / 100;
			} else {
				return value;
			}
		},
	},
	computed:{

		//AS--> DECHETS -----------------------------------------

		filterDechetsMin(){
			if(this.$store.state.filters.dechets_qty_active && Array.isArray(this.$store.state.filters.filters.dechets_qty) && typeof this.$store.state.filters.filters.dechets_qty[0] == 'number') return this.$store.state.filters.filters.dechets_qty[0]
			return false
		},

		filterDechetsMax(){
			if(this.$store.state.filters.dechets_qty_active && Array.isArray(this.$store.state.filters.filters.dechets_qty) && typeof this.$store.state.filters.filters.dechets_qty[1] == 'number') return this.$store.state.filters.filters.dechets_qty[1]
			return false
		},

		getSliderDechetsMin() {
			if(Array.isArray(this.$store.state.filters.dechets_qty_slider) && typeof this.$store.state.filters.dechets_qty_slider[0] == 'number') return Math.floor(this.$store.state.filters.dechets_qty_slider[0])
			return 0
		},

		getSliderDechetsMax() {
			if(Array.isArray(this.$store.state.filters.dechets_qty_slider) && typeof this.$store.state.filters.dechets_qty_slider[1] == 'number') return Math.floor(this.$store.state.filters.dechets_qty_slider[1])
			return 0
		},

		//AS--> Pour le slider
		filtreDechets: {
			get: function(){
				return [this.filterDechetsMin,this.filterDechetsMax]
			},
			set: function(value){
				this.$store.dispatch('setDechetsQty', value)
			}
		},

		//AS--> Pour le champ min
		filtreDechetsMin: {
			get: function(){
				return this.$store.state.filters.dechets_qty_active && this.filterDechetsMin !== false ? this.$store.state.filters.filters.dechets_qty[0] : this.getSliderDechetsMin
			},
			set: function(value){
				this.$store.dispatch('setDechetsQty', [value, this.filterDechetsMax ?? this.getSliderDechetsMax])
			}
		},

		//AS--> Pour le champ max
		filtreDechetsMax: {
			get: function(){
				return this.$store.state.filters.dechets_qty_active && this.filterDechetsMax !== false ? this.$store.state.filters.filters.dechets_qty[1] : this.getSliderDechetsMax
			},
			set: function(value){
				this.$store.dispatch('setDechetsQty', [this.filterDechetsMin ?? this.getSliderDechetsMin, value])
			}
		},





		//AS--> DECHET -----------------------------------------

		filterDechetMin(){
			if(this.$store.state.filters.dechet_qty_active && Array.isArray(this.$store.state.filters.filters.dechet_qty) && typeof this.$store.state.filters.filters.dechet_qty[0] == 'number') return this.$store.state.filters.filters.dechet_qty[0]
			return false
		},

		filterDechetMax(){
			if(this.$store.state.filters.dechet_qty_active && Array.isArray(this.$store.state.filters.filters.dechet_qty) && typeof this.$store.state.filters.filters.dechet_qty[1] == 'number') return this.$store.state.filters.filters.dechet_qty[1]
			return false
		},

		getSliderDechetMin() {
			if(Array.isArray(this.$store.state.filters.dechet_qty_slider) && typeof this.$store.state.filters.dechet_qty_slider[0] == 'number') return Math.floor(this.$store.state.filters.dechet_qty_slider[0])
			return 0
		},

		getSliderDechetMax() {
			if(Array.isArray(this.$store.state.filters.dechet_qty_slider) && typeof this.$store.state.filters.dechet_qty_slider[1] == 'number') return Math.floor(this.$store.state.filters.dechet_qty_slider[1])
			return 0
		},

		//AS--> Pour le slider
		filtreDechet: {
			get: function(){
				return [this.filterDechetMin,this.filterDechetMax]
			},
			set: function(value){
				this.$store.dispatch('setDechetQty', value)
			}
		},

		//AS--> Pour le champ min
		filtreDechetMin: {
			get: function(){
				return this.$store.state.filters.dechet_qty_active && this.filterDechetMin !== false ? this.$store.state.filters.filters.dechet_qty[0] : this.getSliderDechetMin
			},
			set: function(value){
				this.$store.dispatch('setDechetQty', [value, this.filterDechetMax ?? this.getSliderDechetMax])
			}
		},

		//AS--> Pour le champ max
		filtreDechetMax: {
			get: function(){
				return this.$store.state.filters.dechet_qty_active && this.filterDechetMax !== false ? this.$store.state.filters.filters.dechet_qty[1] : this.getSliderDechetMax
			},
			set: function(value){
				this.$store.dispatch('setDechetQty', [this.filterDechetMin ?? this.getSliderDechetMin, value])
			}
		},
	},
	components: {
		VueSlider,
		CustomSlider
	}
}