export default {
	data() {
    return {}
  },
  props: ['entreprise'],
  computed:{
    getCommentSub(){
        let comment = this.entreprise.comment ?? ''

        //AS--> Supprime les tags HTML de comment
        comment = comment.replace(/(<\/[^>]*>)/gi, " ")
        comment = comment.replace(/(\s+)/gi, " ")
        comment = comment.replace(/(<([^>]+)>)/gi, "")

        //AS--> Réduit la taille de la note à 100 caractères et ajoute ... si plus long
        if (comment.length > 100) comment = comment.substring(0, 100) + ' ...'

        return comment
    },
    getComment(){
        return this.entreprise.comment ?? ''
    }
  }
}