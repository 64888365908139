import MenuSousMenu from './menu_sous-menu.vue'
import axios from 'axios'

export default {
	data() {
		return {
			
		}
	},
	props: ['entreprise'],
	created(){
		
	},
	methods: {
        changeStatus(status){
            this.$emit('change', status.slug)
            this.$store.dispatch('updateFieldCompany', {identifiant: this.entreprise.identifiant, field: 'current_status', value: status.slug})
        }
	},
    computed:{
        menu_statuses(){
            let menu = {
                menu: this.status,
                sousmenu: {
                    class: 'no-bold menu_statuses_list',
                    list: []
                }
            }

            if(this.$store.getters.getStatuses?.length){
                this.$store.getters.getStatuses.forEach(status => {
                    menu.sousmenu.list.push({
                        label    : status.name,
                        slug     : status.slug,
                        bg_color : status.color,
                        action   : () => this.changeStatus(status)
                    })
                })
            }

            menu.sousmenu.list.push({
                label    : this.$t('statuses_no_status'),
                slug     : null,
                bg_color : "#E9F0FD",
                action   : () => this.changeStatus({slug: null})
            })

            return menu
        },
        status(){
            let status = this.$store.getters.getStatuses.filter(status => status.slug === this.entreprise.current_status)[0]
            if(status){
                return {
                    picto: false,
                    label: status.name,
                    arrow: true,
                    bg_color: status.color
                }
            }else{
                return {
                    picto: false,
                    label: this.$t('statuses_no_status'),
                    arrow: true,
                    bg_color: "#E9F0FD"
                }
            }
        }
    },
	components:{
		MenuSousMenu
	}
}