import _ from 'lodash'

export default {
	data() {
		return {}
	},
	props: ['params'],
	methods: {},
	computed:{
		canHeatmapPvr(){
			let can = false
			let familles = this.$store.getters.getFamillesParams;
			_.forEach(familles, function(famille){
				if(famille.pvr_metha === '1') can = true
			})
			return can
		}
	},
	watch: {},
	components: {}
}