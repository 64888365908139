import _ from 'lodash'
import * as turf from '@turf/turf'

export default {
	methods: {

		//AS--> Part d'un array de paths et renvoie un vrai GeoJson, qui peut être Stringifié
		getValidePaths(map_object, paths, stringify){
			let self = this;

			if (paths.length == 0) return null


			let center = map_object.getBounds().getCenter();
			let zoom   = map_object.getZoom();
			let coordinates = [];

			//AS--> Trasnforme les coordonnées des paths en coordonnées pour GeoJson
			paths.forEach(function(path){
				let _path = [];
				path.forEach(function(c){
					_path.push([c.lng, c.lat]);
				})
				_path = self.closeLoop(_path);
				coordinates.push([_path]);
			})

			//AS--> Monte le GeoJson et le transforme en string
			let polygonGeojson = {
				type: 'MultiPolygon',
				coordinates,
				center,
				zoom
			}
			if(undefined == stringify || true == stringify) polygonGeojson = JSON.stringify(polygonGeojson, null, 2);

			return polygonGeojson;
		},

		geojsonCoordinates2googlePolygon(coordinates){
			//AS--> Quand il n'y a qu'un Polygon, il faut le wrapper dans un array
			if(typeof coordinates[0][0][0][0] == 'undefined') coordinates = [coordinates];

			let paths = [];

			coordinates.forEach(function(zone, k_zone){
				let _zone = [];
				
				//AS--> Change le sens de description du polygon s'il n'est pas cockwise, right-hand rule
				if(!turf.booleanClockwise(zone[0])) zone[0] = zone[0].reverse()

				//AS--> Transforme chaque point en objet lat/lng
				zone[0].forEach(function(point){
					_zone.push({lat:point[1], lng:point[0]});
				})

				paths.push(_zone);
			})

			return paths;
		},

		isClockwise(poly) {
		    var sum = 0
		    for (var i=0; i<poly.length-1; i++) {
		        var cur = poly[i],
		            next = poly[i+1]
		        sum += (next[0] - cur[0]) * (next[1] + cur[1])
		    }
		    if (sum > 0) {
		      return "clockwise";
		    } else if (sum < 0) {
		        return " counter-clockwise";
		    }
		},

		//AS--> Renvoiie un LatLngBoundsLiteral pour permettre à Google de zoomer la carte
		geojsonBounds(geojson){
			let bounds = turf.bbox(geojson)
			bounds = {
				east: bounds[2],
				north: bounds[3],
				south: bounds[1],
				west: bounds[0]
			}
			return bounds
		}
	},



	computed:{

		//AS--> Récupère les coordonnées des 4 coins de la map affichée. this.map_state doit être défini sur l'enfant
		getMapPaths(){
			let outerCoords = []
			if (this.map_state.bounds != null) {
				let ne = this.map_state.bounds.getNorthEast();
				let sw = this.map_state.bounds.getSouthWest();
				outerCoords = [
					{lat: ne.lat()+1, lng: sw.lng()-1},
					{lat: sw.lat()-1, lng: sw.lng()-1},
					{lat: sw.lat()-1, lng: ne.lng()+1},
					{lat: ne.lat()+1, lng: ne.lng()+1}
				];
			}

			return outerCoords
		},

		//AS--> Dessine la zone couverte par la zone active
		//      this.map                  = $refs.map
		//      this.geoFiltreActif       = la forme de la zone de sélection
		//      this.shape_filter_to_show = new google.maps.Polygon qui affiche le polygon sur la carte
		drawActiveZone(){

			if(this.map && this.shape_filter_to_show){
				if(this.getZonePaths && this.getZonePaths.length){

					//AS--> Crée un array avec pour première valeur le contour de la carte visible
					let paths = [this.getMapPaths]

					//AS--> Ajoute à cet array tous les polygon à soustraire un par un
					this.getZonePaths.forEach(function(zone){
						paths.push(zone);
					})

					//AS--> Défini le polygon à afficher sur shape_filter_to_show
					this.shape_filter_to_show.setOptions({
						paths: paths,
						clickable: false,
						strokeColor: '#00CEA8'
					})
					this.shape_filter_to_show.setMap(this.map.$mapObject);

				}else{
					this.shape_filter_to_show.setMap(null);

				}
			}

			return true
		}
	}
}